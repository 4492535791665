import React, { Component } from "react";
import styles from "./Thank-You.module.scss";
import DoneIcon from '@material-ui/icons/Done';
import MoodIcon from '@material-ui/icons/Mood';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ThankYou extends Component {

    componentDidMount() {
        document.title = "Thank You"
    }

    render() {
        return(
            <div className={`content ${styles.bodyBG}`}>
                <Container fluid className={`${styles.thankYouDiv} sectionSpace`}>
                    <Container>
                        <Row>
                            <Col md={6} xs={12} className={`offset-md-3 offset-0 ${styles.thankYouDivCenter}`}>
                                <Col xs={12}>
                                    <h1>
                                        Thank
                                        <span><b>Y<MoodIcon />u</b></span>
                                    </h1>
                                </Col>
                                <Col xs={12}>
                                    <h4>
                                        <span>Your Test is booked! <DoneIcon /></span>
                                    </h4>
                                </Col>
                                <Col xs={12}>
                                    <p>
                                        Just a second, we're sending the test details to your email.
                                    </p>
                                </Col>
                                <Col xs={12} className={`${styles.thankYouBtn}`}>
                                    <Link to="/" className={`my-btn`}>
                                        Back to home
                                    </Link>
                                    <Link to="/" className={`my-btn my-btn-second`}>
                                        Continue Shopping
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default ThankYou;