import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';


function ScrollToTop({ history, children }) {
  useEffect(() => {
    function fatchData() {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      }
    }
    fatchData();
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);