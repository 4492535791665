import React, { Component } from "react";
import styles from "./Refund.module.scss"
import { Container, Image, Row, Col, Breadcrumb } from 'react-bootstrap';

class Refund extends Component{

    componentDidMount() {
        document.title = "Refund"
    }

    render() {
        return(
            <div className="content">
                
                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>Refund</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Refund
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={styles.staticPageContent}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h5>
                                    In case of breach of contract, will the client’s subscription amount be refunded?
                                </h5>
                                <p>
                                    If there will be a case of breach of contract due to some unethical behavior of the client, then subscription amount of current quarter and all previous quarters will be forfeited and balance amount of remaining quarters will be refunded within 7 business days post the date of breach of contract.
                                </p>

                                <h5>
                                    Is there any refund policy in case I want to cancel my tab/desktop subscription prior to the completion of its subscription period?
                                </h5>
                                <p>
                                    Yes there is… if a person wants to cancel his/her tab/desktop subscription can contact our customer support team and can get the refund back according to the refund policy as stated below:
                                </p>

                                <h5>
                                    Tab/Desktop subscription refund policy:
                                </h5>
                                <p>
                                    If a person cancels his order at the time of delivery, the amount will be refunded after deducting the cancellation charges within 7 business days post the date of order cancellation.
                                </p>
                                <p>
                                    If a person cancels his order due to any reason before the shipment has been dispatched, full amount will be refunded within 7 business days from the date of order cancellation.
                                </p>
                                <p>
                                    If a person wants to discontinue his/her tab/desktop subscription before completion of its subscription period, the cancellation charges will be deducted and the balance amount will be refunded within 7 business days from the date of discontinuation of the tab/desktop subscription.
                                </p>
                                <p>
                                    If a person’s tab/desktop gets damaged while usage but he wants to continue his subscription, he can contact our customer support team to get his tab/desktop replaced as per the policy.
                                </p>
                                <p>
                                    If a person’s tab/desktop gets damaged while usage and he did not want to continue his subscription further, the cancellation charges will be deducted and the balance amount will be refunded within 7 business days from the date of discontinuation of the tab/desktop subscription.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default Refund;