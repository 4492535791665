import React, {Component} from 'react';
import styles from './Download-App.module.scss';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { Image, Container, Row, Col, Form, InputGroup, } from 'react-bootstrap';

class DownloadAppComponent extends Component{
    render() {
        return(
            <Container fluid className={`sectionSpace ${styles.downloadApp}`}>
                <Container>
                    <Row className={styles.row}>
                        <Col md={5} sm={6} xs={12}>
                            <Image src={window.location.origin + "/assets/images/home/phoneApp.png"} alt="Download App" className={styles.appMobileImg} fluid />
                        </Col>

                        <Col md={7} sm={6}  xs={12} className={styles.aboutContent_text}>
                            <h3>
                                Download the <span>DrRay24 app</span>
                            </h3>
                            <p>
                                We are just an app away! Yes, to ensure your safety 24/7 and to reach you instantly for support and assistance we have launched an app where you can get every detail of our service and treatment-related queries, from the comfort of your homes. Click the link and register your mobile number to log in once and enjoy hassle-free services for the rest of your life.
                            </p>

                            <h4>
                                Get the link to download the app
                            </h4>

                            <Form className={`${styles.getAppLink} customForm customGetAppLink`}>
                                <Form.Row>
                                    <Form.Group as={Col} md={8} sm={6} xs={12}>
                                        <InputGroup className={styles.inputGroup}>
                                            <InputGroup.Prepend className={styles.inputGroupPrepend}>
                                                <InputGroup.Text className={styles.inputGroupText} id="inputGroupPrepend">+91</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <TextField aria-describedby="inputGroupPrepend" required label="Enter phone number" className={`form-control mobileAppInput ${styles.formControl}`} />
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>

                                <Button className={`my-btn my-btn-second mt-4 ${styles.formBtn}`} variant="contained" type="submit">Send App Link</Button>
                            </Form>

                            <p className={styles.getAppIcon}>
                                <Button href="#" className="my-btn" variant="contained">
                                    <AppleIcon className={styles.getAppIconRoot} />
                                </Button>
                                <Button href="#" className="my-btn" variant="contained">
                                    <AndroidIcon className={styles.getAppIconRoot} />
                                </Button>
                            </p>

                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default DownloadAppComponent;