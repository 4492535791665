var initState = {};
if (JSON.parse(localStorage.getItem('diagnosticCart'))) {
    initState = JSON.parse(localStorage.getItem('diagnosticCart'));
} else {
    initState = {
        items: [],
        total: 0,
        totalDisc: 0
    }
}
const diagnosticCartReducer = (state = initState, action) => {
    if(action.type === 'ADD_TO_CART') {
        var cartItem = state.items.find(item => action.item.test_code === item.test_code);
        let newTotal = state.total + parseInt(action.item.final_mrp);
        let newDisc = state.totalDisc + (parseInt(action.item.mrp) - parseInt(action.item.final_mrp));
        if(cartItem) {
            localStorage.setItem('diagnosticCart', JSON.stringify(state));
            return state;
        } else {
            let cartItems = {
                ...state,
                items: [...state.items, action.item],
                total : newTotal,
                totalDisc: newDisc
            };
            localStorage.setItem('diagnosticCart', JSON.stringify(cartItems));
            return cartItems;
        }
    }

    if(action.type === 'REMOVE_ITEM') {
        let new_items = state.items.filter(item=> action.item.test_code !== item.test_code)
        let newTotal = state.total - parseInt(action.item.final_mrp);
        let newDisc = state.totalDisc - (parseInt(action.item.mrp) - parseInt(action.item.final_mrp));
        let cartItems = {
            ...state,
            items: new_items,
            total: newTotal,
            totalDisc: newDisc
        };
        localStorage.setItem('diagnosticCart', JSON.stringify(cartItems));
        return cartItems;
    }

    if(action.type === 'CHECKOUT') {
        initState = {
            items: [],
            total: 0,
            totalDisc: 0
        };
        return initState;
    }

    return state;
}

export default diagnosticCartReducer;