import React, {Component} from 'react';

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// return component
import ReactFusioncharts from "react-fusioncharts";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const dataSource = {
    chart: {
        caption: "Blood Pressure",
        subcaption: "",
        xaxisname: "Date",
        yaxisname: "Blood Pressure",
        numbersuffix: "",
        theme: "fusion",
        showhovereffect: "1",
        drawcrossline: "1",
        plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
    },
    categories: [
      {
        category: [
            {
                label: "09-07-2020"
            },
            {
                label: "10-07-2020"
            },
            {
                label: "11-07-2020"
            },
            {
                label: "12-07-2020"
            },
            {
                label: "13-07-2020"
            }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "Systolic",
        data: [
          {
            value: "62"
          },
          {
            value: "64"
          },
          {
            value: "64"
          },
          {
            value: "66"
          },
          {
            value: "78"
          }
        ]
      },
      {
        seriesname: "Diastolic",
        data: [
          {
            value: "16"
          },
          {
            value: "28"
          },
          {
            value: "34"
          },
          {
            value: "42"
          },
          {
            value: "54"
          }
        ]
      }
    ]
};


class BloodPressureComponent extends Component{
    render() {
        return(
            <ReactFusioncharts className={`graphChart`}
                type="msline"
                width="100%"
                height="400"
                dataFormat="JSON"
                dataSource={dataSource}
            />
        );
    }
}

export default BloodPressureComponent;