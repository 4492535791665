import React, {Component} from 'react';
import '../../App.scss';
import styles from './Header.module.scss';
import { withRouter } from 'react-router-dom'
import SendIcon from '@material-ui/icons/Send';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import {Navbar, Nav, Container } from 'react-bootstrap';
import DoctorSearch from './Doctor-Search/Doctor-Search';
import BackToTopButton from './Back-To-Top/Back-To-Top';
import { connect } from 'react-redux';
import HeaderFixedComponent from './Header-Fixed/Header-Fixed';
import ReactTooltip from "react-tooltip";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
        }
    }

    render() {
        if(this.props.location.pathname != "/checkout") {
            return(
                <div className={`${styles.headerMain}`} id="myHeaderHelp">
                    <BackToTopButton />

                    <Navbar expand="md" className={`${styles.navSocialMedia}`}>
                        <Container className={`${styles.containerSpace}`}>
                            <Nav className={`mr-auto ${styles.navAccount} d-none d-md-flex`}>
                                <Nav.Link href="mailto:info@drray24.in">
                                    <SendIcon className={`${styles.rotateIcon}`} /> info@drray24.in
                                </Nav.Link>
                                <Nav.Link href="tel:8810428831">
                                    <PhoneInTalkIcon /> +91 8448677438
                                </Nav.Link>
                            </Nav>

                            <Nav className={`${styles.navAccount}`}>
                                <span className={`d-block d-md-none`}>
                                    <DoctorSearch />
                                </span>
                                <Nav.Link data-tip data-for="facebookTooltip" href="https://www.facebook.com/Drray24lab/" className={`${styles.socialLink} d-none d-md-block`}><FacebookIcon /></Nav.Link>
                                <Nav.Link data-tip data-for="twitterTooltip" href="https://twitter.com/GETTOOWELL" className={`${styles.socialLink} d-none d-md-block`}><TwitterIcon /></Nav.Link>
                                <Nav.Link data-tip data-for="youtubeTooltip" href="https://www.youtube.com/channel/UCqMz3W8ffANVD1yybiv8ArA" className={`${styles.socialLink} d-none d-md-block`}><YouTubeIcon /></Nav.Link>
                                <Nav.Link data-tip data-for="linkedinTooltip" href="https://www.linkedin.com/company/gettoowell-technology-pvt-ltd" className={`${styles.socialLink} d-none d-md-block`}><LinkedInIcon /></Nav.Link>
                                <Nav.Link data-tip data-for="instagramTooltip" href="https://www.instagram.com/gettoowell/" className={`${styles.socialLink} d-none d-md-block`}><InstagramIcon /></Nav.Link>

                                <ReactTooltip id="facebookTooltip">Facebook</ReactTooltip>
                                <ReactTooltip id="twitterTooltip">Twitter</ReactTooltip>
                                <ReactTooltip id="youtubeTooltip">Youtube</ReactTooltip>
                                <ReactTooltip id="linkedinTooltip">Linkedin</ReactTooltip>
                                <ReactTooltip id="instagramTooltip">Instagram</ReactTooltip>
                            </Nav>

                        </Container>
                    </Navbar>

                    <HeaderFixedComponent userData={this.props.userData} />
                </div>
            );                        
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state)=>{
    return{
        userData: state.userAuth
    }
}

export default connect(mapStateToProps)(withRouter(Header));