import React, {Component} from 'react';
import styles from './After-Activity-Component.module.scss';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Image, Col, Figure, Card } from 'react-bootstrap';
import AfterActivityListComponent from './After-Activity-Component-List/After-Activity-Component-List';

class AfterActivityComponent extends Component{
    render() {
        return(
            <div className={`${styles.profileDetails}`}>
                <Card className={`${styles.profileDetailDivCard}`}>
                    <Card.Header className={`${styles.profileDetailHeader}`}>

                        <Figure className={`${styles.profileDetailProfileImg}`}>
                            <Image className="mx-auto" src={window.location.origin + "/assets/images/home/doctors/image_1.jpg"} fluid alt="Profile Photo" />
                        </Figure>

                        <div className={`${styles.profileDetailContent}`}>
                            <Col md={7} xs={12} className={`${styles.profileDetailContentLeft}`}>
                                <h1>
                                    Name
                                </h1>
                                <h5>
                                    Date, time
                                </h5>
                            </Col>

                            <Col md={5} xs={12} className={`${styles.profileDetailContentRight}`}>
                                <span>
                                    <DeleteForeverIcon />
                                </span>
                            </Col>
                        </div>
                    </Card.Header>

                    <Card.Body className={`${styles.profileDetailBody}`}>
                        <AfterActivityListComponent />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default AfterActivityComponent;