import React, {Component, useState} from 'react';
import styles from './Careers.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import { Container, Image, Row, Col, Breadcrumb, Accordion, Card, Badge, ListGroup, Form } from 'react-bootstrap';
import { Button, TextField } from '@material-ui/core';
import Modal from 'react-bootstrap/Modal';

class Careers extends Component {

    componentDidMount() {
        document.title = "Careers"
    }

    render() {

        /***** modal-popup *******/
        function ModalPopup() {
            const [show, setShow] = useState(false);
          
            const handleClose = () => setShow(false);
            const handleShow = () => setShow(true);
          
            return (
              <>
                <Button className="my-btn" variant="contained" onClick={handleShow}>Apply Now</Button>
          
                <Modal show={show} onHide={handleClose} centered className="customModal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Business Analytics
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        
                        <Form className="customForm row">
                            <Form.Row as={Col} xs={12}>
                                <TextField label="Enter your name" name="userName" variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} sm={6} xs={12}>
                                <TextField label="Enter your email" name="userEmail" variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} sm={6} xs={12}>
                                <TextField label="Enter your phone" name="userPhone" variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} xs={12}>
                                <TextField label="Enter your email" name="userEmail" variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} xs={12}>
                                <Button className="my-btn mx-auto px-5" variant="contained" type="submit">
                                    Submit
                                </Button>
                            </Form.Row>
                        </Form>

                    </Modal.Body>
                </Modal>
              </>
            );
        }

        return(
            <div className="content">
                
                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>Careers</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Careers
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={`${styles.staticPageContent} ${styles.sectionSpace} bg-white`}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h3 className="customHeading">Open Positions</h3>
                                <p className="sectionText">
                                    Drray team leads web and mobile development community through conferences, blogs, open source, videos, books and meetups. <br />
                                    We value open source, learning, remote work and open communication.
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Accordion defaultActiveKey="0" className={[styles.accordionDiv, styles.accordionDivCareer]}>
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="0">
                                            Analytics <Badge className={styles.badge}>2 Opening</Badge>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className={styles.body}>
                                                <div className={styles.openingPosition}>
                                                    <h4>
                                                        Business Analytics
                                                    </h4>
                                                    
                                                    <p>
                                                        <span>
                                                            Our Team DRRAY24 was founded in 2017 with the sole purpose to make healthcare easily available, accessible a......
                                                        </span>
                                                        <ModalPopup />
                                                    </p>
                                                </div>
                                                
                                                <div className={styles.openingPosition}>
                                                    <h4>
                                                        Data Analytics
                                                    </h4>
                                                    
                                                    <p>
                                                        <span>
                                                            Our Team DRRAY24 was founded in 2017 with the sole purpose to make healthcare easily available, accessible a......
                                                        </span>
                                                        <ModalPopup />
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="1">
                                            Sales <Badge className={styles.badge}>2 Opening</Badge>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body className={styles.body}>
                                                <div className={styles.openingPosition}>
                                                    <h4>
                                                        Sales Manager
                                                    </h4>
                                                    
                                                    <p>
                                                        <span>
                                                            Our Team DRRAY24 was founded in 2017 with the sole purpose to make healthcare easily available, accessible a......
                                                        </span>
                                                        <ModalPopup />
                                                    </p>
                                                </div>
                                                
                                                <div className={styles.openingPosition}>
                                                    <h4>
                                                        Sales Executive
                                                    </h4>
                                                    
                                                    <p>
                                                        <span>
                                                            Our Team DRRAY24 was founded in 2017 with the sole purpose to make healthcare easily available, accessible a......
                                                        </span>
                                                        <ModalPopup />
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="2">
                                            BPO <Badge className={styles.badge}>1 Opening</Badge>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body className={styles.body}>
                                                <div className={styles.openingPosition}>
                                                    <h4>
                                                        Telecaller
                                                    </h4>
                                                    
                                                    <p>
                                                        <span>
                                                            Our Team DRRAY24 was founded in 2017 with the sole purpose to make healthcare easily available, accessible a......
                                                        </span>
                                                        <ModalPopup />
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`${styles.staticPageContent} ${styles.sectionSpace} bg-white`}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h3 className="customHeading">Why Join Drray24?</h3>
                                <p className="sectionText">
                                    We at DRRAY24, strongly believes in delivering timely, unmatched and effective healthcare solutions with greater comfort to our customers. Our enthusiasm, loyalty and devotion for providing good quality service rewarded us in the form of long term relationships with our clients.
                                </p>

                                <ListGroup as="ul" className={styles.whyChooseOption}>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    24 /7 Emergency Services
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get round the clock access to our healthcare services. Get doctor’s appointment, consultation, or medicines - anytime, anywhere.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Modern Technologies
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get smarter healthcare services with modern technologies to stay healthy and fit in today’s fast moving lifestyle.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Passionate Doctors & Nurses
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get access to highly qualified and experienced doctors along with passionate and caring nursing staff everywhere and every time.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Protection Against Diseases
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get instant guidance from our experts on protection against diseases with no waiting long queues even at the time of crises.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default Careers;