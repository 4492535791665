import React, { Component } from "react";
import styles from "./Tests.module.scss";
import { TextField, MenuItem } from '@material-ui/core';
import FilterCard from '../Cards/Filter-card/Filter-card';
import {Link} from 'react-router-dom';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import TestCard from '../Cards/Test-card/Test-card';
import Service from '../../../services/Service';
import ListLoader from "../List-Loader/List-Loader";
import StickyBox from "react-sticky-box";

const currencies = [
    {
      value: 'LowHigh',
      label: 'Price - Low to High',
    },
    {
      value: 'HighLow',
      label: 'Price - High to Low',
    }
];

class Tests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tests: []
        }
    }

    componentDidMount() {
        const API = Service.get('diagnostic/tests');
        API.then(json => {
            this.setState({
                isLoading: true,
                tests: json
            });
        });
        document.title = "Tests"
    }

    render() {
        var {isLoading, tests} = this.state;
        if(isLoading) {
            return(
                <div className={`content bgGray`}>
                    <Container fluid className={`mt-n3`}>
                        <Container>
                            <Row>
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Home</Link>
                                        <Link to="/diagnostic">Diagnostic</Link>
                                        <Breadcrumb.Item active>All Tests</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className={`${styles.doctorListDiv}`}>
                        <Container>

                            <Row>
                                <Col lg={3} md={4} xs={12}>
                                    <StickyBox offsetTop={80} offsetBottom={10}>
                                        <FilterCard filter={tests.result.health_category}/>
                                    </StickyBox>
                                </Col>

                                <Col lg={9} md={8} xs={12}>

                                    <Col className={`customSecondHeading ${styles.filterTop}`}>
                                        <h5>
                                            {tests.result.total} Diagnostic Tests
                                            <span>
                                                Our Search Result
                                            </span>
                                        </h5>

                                        <TextField id="SortBy" className={`${styles.sortByFilter} customDropDown`} select required label="Sort By" name="SortBy" variant="outlined">
                                            {currencies.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>

                                    <Row className={`${styles.listingRow}`}>
                                        {
                                            tests.result.tests.map((item, index) => (
                                                <Col md={6} xs={12} key={index}>
                                                    <Col className={styles.bookedTestMain}>
                                                        <TestCard test={item} />
                                                    </Col>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            )
        } else {
            return (
                <ListLoader />
            );
        }
    }
}

export default Tests;