import React, { Component } from "react";
import styles from "./Page-Not-Found.module.scss";
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

class PageNotFound extends Component {

    componentDidMount() {
        document.title = "Page Not Found"
    }
    render() {
        return(
            <div className={`content ${styles.bodyBG}`}>
                <Container fluid className={`${styles.thankYouDiv} sectionSpace`}>
                    <Container>
                        <Row>
                            <Col md={6} xs={12} className={`offset-md-3 offset-0 ${styles.thankYouDivCenter}`}>
                                <Col xs={12}>
                                    <h1>
                                        <span><b>4<MoodBadOutlinedIcon />4</b></span>
                                    </h1>
                                </Col>
                                <Col xs={12}>
                                    <h4>
                                        <span>Oops!</span>
                                        Page not found
                                    </h4>
                                </Col>
                                <Col xs={12}>
                                    <p>
                                        Sorry, an error has occured, Requested page not found!
                                    </p>
                                </Col>
                                <Col xs={12} className={`${styles.thankYouBtn}`}>
                                    <Link to="/" className={`my-btn`}>
                                        Back to home
                                    </Link>
                                    <Link to="/contact" className={`my-btn my-btn-second`}>
                                        Contact
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default PageNotFound;