import React, { Component } from "react";
import styles from './Doctor.module.scss';
import { Button } from '@material-ui/core';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Image, CardGroup, Card, Container, Row, Col, Figure } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DoctorSpecialistComponent from "../Carousel-Card/Doctor-Specialist/Doctor-Specialist";

const responsiveItemList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1.8,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

const responsiveBlogList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

class Doctor extends Component {
    componentDidMount() {
        document.title = "Doctor"
    }

    render() {
        return(
            <div className="content mt-n3">
                
                <Figure className={`w-100 ${styles.doctorMainBanner}`}>
                    <Link to="/search/specialization/Cardiologist/3" variant="contained">
                        <Image className="w-100 d-none d-md-block" src="assets/images/doctor/doctor_banner.svg" alt="First slide" />
                        <Image className="w-100 d-block d-md-none" src="assets/images/doctor/doctor_banner_m.svg" alt="First slide" />
                    </Link>
                </Figure>

                <CardGroup className={styles.weOffers}>
                    <Container>
                        <Row className={styles.row}>
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Figure>
                                        <Image width="50" className="mx-auto" src="assets/images/home/doctor_card.jpg" alt="Book Appointment" />
                                    </Figure>
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>Qualified Doctors</Card.Title>
                                        <Card.Text className={styles.text}>
                                            Our Qualified MBBS and MD level doctors have 12- 20 years of service experience. They are trained professionals in various intensive care units. Their priority is to diagnose the disease at early stages and provide instant solutions, support, and treatments and post medical care.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Figure>
                                        <Image width="50" className="mx-auto" src="assets/images/home/pharmacy_card.jpg" alt="Book Pharmacy" />
                                    </Figure>
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>Emergency Care</Card.Title>
                                        <Card.Text className={styles.text}>
                                            Emergency services remain our top priority any day. We have a special team of experts to deal with emergency conditions and dedicate our best counseling and support to the family for instant enlightenment.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Figure>
                                        <Image width="50" className="mx-auto" src="assets/images/home/diagnostics_card.jpg" alt="Book Diagnostics" />
                                    </Figure>
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>24-Hour Service</Card.Title>
                                        <Card.Text className={styles.text}>
                                            Health emergencies and causalities may arrive unnoticed. So we provide 24/7 healthcare solutions not only on physical appointments but also through our app service. Avail the expertise of our unmatched healthcare professionals no matter when and how. 
Your health is a primary concern.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </CardGroup>

                <Container fluid className={styles.aboutContent}>
                    <Container>
                        <Row>
                            <Col md={5} xs={12} className={`order-md-2 order-1`}>
                                <Image src="assets/images/home/about_us.png" alt="About Us" fluid />
                            </Col>

                            <Col md={7} xs={12} className={`order-md-1 order-2 ${styles.aboutContent_text}`}>
                                <h1>
                                    Your Health is <span className="d-block">Our <b>Primary Concern</b></span>
                                </h1>
                                <p>
                                    At Dr Ray 24 we are committed to maintaining the highest standards of healthcare affordably. With our instant homely services in all sectors of medical emergencies, treatments, and procedures, we take our pride privilege to call ourselves the best anybody deserves!
                                </p>
                                <p>
                                    Since its inception, we believe in focusing on ethical medical values with super specialty and state-of-the-art facilities. Our polite professionals and patient-friendly ambiance make sure you feel like your second home. We are happy to prove your long term solutions inexpensively. 
Medical Departments.
                                </p>
                                <Button className={`${styles.aboutBtn} my-btn d-none`} variant="contained">Read More</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.medicalDepartment}`}>
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="customHeading">Doctor Specialization</h3>
                                <p className="sectionText">
                                    DrRay 24 is equipped with some of the best medical departments for everyone of every age. It has Mother & Child Care, Digital X-Ray, Oncology, Gastroenterology, Pathology, and Emergency Department to name some. You name it, we have it!
                                </p>
                            </Col>
                        </Row>

                        <Row className={`${styles.medicalDepartmentRow}`}>
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/cardiology.svg" alt="Cardiology" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Cardiology</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We treat all cardiac ailments with our best in-service professionals and intensive quality care. We offer cardiothoracic surgery and vascular surgery packages through our state of the art facility within an outstanding expenditure.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/neurology.svg" alt="Neurology" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Neurology</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We have a team full of experts and qualified neurologists and neurosurgeons who have years of medical knowledge and worked on several complex disorders.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/orthopedic.svg" alt="Orthopedic" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Orthopaedics</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We are offering the forefront service for unmatched orthopedic treatments with our latest equipment and surgical advancements aiming to maintain the highest standards of medical and homely care.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/pulmonary.svg" alt="Pulmonary" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Pulmonary</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            Any respiratory disorder can’t go untreated with us. We offer exemplary services to treat tuberculosis and chest disease maintaining the highest order of medical hygiene.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/dental.svg" alt="Dental" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Dental Clinic</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            Our Multi spatiality dental care provides you the strength and confidence to smile more each day. From dental surgeries to cleaning and filling, we are happy to serve you better and whiter teeth each day.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className={`px-0 ${styles.allPackages}`}>
                                <Link to="/all-specialization" className={`my-btn ${styles.allPackagesBtn}`}>
                                    View All Departments <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.customItemSlider} bg-white`}>
                    <Container>
                        <Col className="px-0">
                            <span className={`text-center d-block secondary-color text-uppercase mb-0 h5`}>Our</span>
                            <h3 className="customHeading">Experience Doctors</h3>
                            <p className="sectionText">
                                We have a long list of MBBS, MD, and MS degree holder doctors who are experienced in every way. Other than that we have surgeons, gynecologists and lab attendants who come from a very strong medical background and holds the ability to perform tough diagnostic procedures in critical conditions. Experience and intelligence speak from their work.
                            </p>
                        </Col>

                        <DoctorSpecialistComponent />

                        <Col className={`px-0 ${styles.allPackages}`}>
                            <Link to="/doctor-list" className={`my-btn ${styles.allPackagesBtn}`}>
                                View All <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} />
                            </Link>
                        </Col>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.appointmentBanner}`}>
                    <Container>
                        <Row>
                            <Col xs className={styles.appointmentBanner_text}>
                                <p>Need a doctor for check-up?</p>
                                <h3>
                                    Just make an appointment & you’re done
                                </h3>
                                <Button className="my-btn" variant="contained">Make an appointment</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.customItemSlider} ${styles.blogListMainDiv} d-none`}>
                    <Container>
                        <Col className="px-0">
                            <span className={`text-center d-block secondary-color text-uppercase mb-0 h5`}>Latest News</span>
                            <h3 className="customHeading">News And Healthcare Tips</h3>
                        </Col>

                        <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay
                        autoPlaySpeed={8000}
                        centerMode={false}
                        className={`mt-5 pt-3`}
                        containerClass="container-with-dots"
                        dotListClass="custom-dot-list-style"
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsiveBlogList}
                        showDots={true}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                        >
                            <Col>
                                <CardGroup className={`${styles.blogListGroup}`}>
                                    <Card className={`${styles.blogListCard}`}>
                                        <Link to="/" className={`${styles.blogListCardLink}`}>
                                            <Image className="mx-auto" src="assets/images/blogs/blog_1.jpg" fluid alt="Blog" />
                                        </Link>
                                        <Card.Body className={`${styles.blogListBody}`}>
                                            <Card.Text className={`${styles.blogListText}`}>
                                                <Link to="/">
                                                    April 3, 2020
                                                </Link>
                                                <Link to="/">
                                                    Health Care
                                                </Link>
                                            </Card.Text>
                                            <Card.Title className={`${styles.blogListTitle}`}>
                                                <Link to="/">
                                                    Lorem ipsum dolor sit amet, consectetuer elit,
                                                </Link>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </CardGroup>
                            </Col>

                            <Col>
                                <CardGroup className={`${styles.blogListGroup}`}>
                                    <Card className={`${styles.blogListCard}`}>
                                        <Link to="/" className={`${styles.blogListCardLink}`}>
                                            <Image className="mx-auto" src="assets/images/blogs/blog_1.jpg" fluid alt="Blog" />
                                        </Link>
                                        <Card.Body className={`${styles.blogListBody}`}>
                                            <Card.Text className={`${styles.blogListText}`}>
                                                <Link to="/">
                                                    April 3, 2020
                                                </Link>
                                                <Link to="/">
                                                    Health Care
                                                </Link>
                                            </Card.Text>
                                            <Card.Title className={`${styles.blogListTitle}`}>
                                                <Link to="/">
                                                    Lorem ipsum dolor sit amet, consectetuer elit,
                                                </Link>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </CardGroup>
                            </Col>

                            <Col>
                                <CardGroup className={`${styles.blogListGroup}`}>
                                    <Card className={`${styles.blogListCard}`}>
                                        <Link to="/" className={`${styles.blogListCardLink}`}>
                                            <Image className="mx-auto" src="assets/images/blogs/blog_1.jpg" fluid alt="Blog" />
                                        </Link>
                                        <Card.Body className={`${styles.blogListBody}`}>
                                            <Card.Text className={`${styles.blogListText}`}>
                                                <Link to="/">
                                                    April 3, 2020
                                                </Link>
                                                <Link to="/">
                                                    Health Care
                                                </Link>
                                            </Card.Text>
                                            <Card.Title className={`${styles.blogListTitle}`}>
                                                <Link to="/">
                                                    Lorem ipsum dolor sit amet, consectetuer elit,
                                                </Link>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </CardGroup>
                            </Col>

                            <Col>
                                <CardGroup className={`${styles.blogListGroup}`}>
                                    <Card className={`${styles.blogListCard}`}>
                                        <Link to="/" className={`${styles.blogListCardLink}`}>
                                            <Image className="mx-auto" src="assets/images/blogs/blog_1.jpg" fluid alt="Blog" />
                                        </Link>
                                        <Card.Body className={`${styles.blogListBody}`}>
                                            <Card.Text className={`${styles.blogListText}`}>
                                                <Link to="/">
                                                    April 3, 2020
                                                </Link>
                                                <Link to="/">
                                                    Health Care
                                                </Link>
                                            </Card.Text>
                                            <Card.Title className={`${styles.blogListTitle}`}>
                                                <Link to="/">
                                                    Lorem ipsum dolor sit amet, consectetuer elit,
                                                </Link>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </CardGroup>
                            </Col>

                            <Col>
                                <CardGroup className={`${styles.blogListGroup}`}>
                                    <Card className={`${styles.blogListCard}`}>
                                        <Link to="/" className={`${styles.blogListCardLink}`}>
                                            <Image className="mx-auto" src="assets/images/blogs/blog_1.jpg" fluid alt="Blog" />
                                        </Link>
                                        <Card.Body className={`${styles.blogListBody}`}>
                                            <Card.Text className={`${styles.blogListText}`}>
                                                <Link to="/">
                                                    April 3, 2020
                                                </Link>
                                                <Link to="/">
                                                    Health Care
                                                </Link>
                                            </Card.Text>
                                            <Card.Title className={`${styles.blogListTitle}`}>
                                                <Link to="/">
                                                    Lorem ipsum dolor sit amet, consectetuer elit,
                                                </Link>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </CardGroup>
                            </Col>

                        </Carousel>

                        <Col className={`px-0 ${styles.allPackages}`}>
                            <Link to="/test-list" className={`my-btn ${styles.allPackagesBtn}`}>
                                View All <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} />
                            </Link>
                        </Col>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default Doctor;