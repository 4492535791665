import React, {Component} from 'react';
import styles from './My-Appointment.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import MyAppointmentListComponent from './My-Appointment-List/My-Appointment-List';
import { DotLoader } from 'react-spinners';
import AuthService from '../../../services/Auth-Service';

class MyAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            app: []
        }
    }

    componentDidMount() {
        const API = AuthService.get('my_appointment');
        API.then(json => {
            this.setState({
                isLoading: true,
                app: json.result
            });
        });
        document.title = "My Appointment"
    }

    render() {
        var {isLoading, app} = this.state;
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    My Appointment
                                    <span>
                                      Appointment List
                                    </span>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`tableMainDiv`}>
                                    {
                                        isLoading ? (<MyAppointmentListComponent app={app}/>) : 
                                        (<div className={`sweet-loading customLoader`}>
                                            <DotLoader
                                                color={'#ef3c67'}
                                                loading={this.state.loading}  
                                            />
                                        </div>)
                                    }
                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default MyAppointment;