import React, {Component} from 'react';
import styles from './User-Profile-Sidebar.module.scss';
import { NavLink } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class UserProfileSidebar extends Component{
    constructor(props) {
        super(props);
        this.state = {addClass: false}
    }

    toggle() {
        this.setState ({
            addClass: !this.state.addClass
        });
    }

    render() {
        return(
            <div>
                <span onClick={this.toggle.bind(this)} className={this.state.addClass ? `${styles.myProfileBtn} ${styles.myProfileHelpBtn} ${styles.myProfileBtnActive}` : `${styles.myProfileBtn} ${styles.myProfileHelpBtn}`}>
                    User Profile <ChevronRightIcon />
                </span>
                
                <div className={this.state.addClass ? `${styles.sidebarMenuMain} ${styles.sidebarMenuMainShow} w-100` : `${styles.sidebarMenuMain} w-100`}>
                    <div className={`${styles.sidebarMenu}`}>
                        <NavLink to="/my-account" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Account</NavLink>
                    </div>

                    <div className={`${styles.sidebarMenu}`}>
                        <div className={`${styles.sidebarHeading}`}>Doctor</div>
                        <NavLink to="/my-doctor" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Doctor</NavLink>
                        <NavLink to="/my-appointment" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Appointment</NavLink>
                        <NavLink to="/prescription" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>Prescription</NavLink>
                    </div>

                    <div className={`${styles.sidebarMenu} d-none`}>
                        <div className={`${styles.sidebarHeading}`}>Medicine</div>
                        <NavLink to="/order-medicine" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>Order Medicine</NavLink>
                        <NavLink to="/my-order" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Orders</NavLink>
                    </div>

                    <div className={`${styles.sidebarMenu}`}>
                        <div className={`${styles.sidebarHeading}`}>Diagnostics</div>
                        <NavLink to="/my-test" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Test</NavLink>
                        <NavLink to="/my-report" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>My Report</NavLink>
                    </div>

                    <div className={`${styles.sidebarMenu}`}>
                        <div className={`${styles.sidebarHeading}`}>Account</div>
                        <NavLink to="/profile" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>Profile</NavLink>
                        <NavLink to="/add-member" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>Add Members</NavLink>
                        <NavLink to="/manage-address" className={`${styles.sidebarLink}`} activeClassName={`${styles.sidebarLinkActive}`}>Manage Address</NavLink>
                    </div>
                
                </div>
            </div>
        );
    }
}

export default UserProfileSidebar;