import React, { Component } from 'react';
import styles from './Coming-Soon.module.scss';
import { Image } from 'react-bootstrap';

class ComingSoon extends Component {
    render() {
        return(
            <div className={`${styles.comingSoonSection}`}>
                <Image className={`d-block img-fluid mx-auto`} src="assets/images/comingSoonVector.png" alt="Coming Soon" />
            </div>
        );
    }
}

export default ComingSoon;