import React, { Component } from 'react';
import styles from './OtpGenerate-filed.module.scss';
import OtpInput from 'react-otp-input';
import Modal from 'react-bootstrap/Modal';
import AuthService from '../../../services/Auth-Service';
 
export default class OtpFiled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      numInputs: 6,
      separator: '-',
      isDisabled: false,
      hasErrored: false,
      isInputNum: true,
      otpPopup: true,
      focusStyle: true
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.confilrmMobile({confirm: false, popup: false});
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('mobile', this.props.mobile);
    formData.append('otp', this.state.otp);
    const API = AuthService.post('diagnostic_checkout/confirmOtp', formData);
    API.then(res => {
        if(res == 1) {
          this.props.confilrmMobile({confirm: true, popup: false});
        } else {
          alert("Wrong OTP !");
          this.props.confilrmMobile({confirm: false, popup: true});
        }
    });
  }

  render() {
    const { otp, numInputs, separator, isDisabled, hasErrored, isInputNum, focusStyle } = this.state;

    return (
      <Modal show={this.state.otpPopup} onHide={this.handleClose} centered className="customModal">
        <Modal.Header closeButton>
            <Modal.Title>
                Enter Verification Code
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className={`${styles.otpPopupBody}`}>
          <div className={`${styles.viewOtpDiv}`}>
            <div className={`${styles.viewOtpCard}`}>
              <form>
                <div>
                  <OtpInput
                    inputStyle={{
                      width: '3rem',
                      height: '3rem',
                      margin: '0 1rem',
                      fontSize: '2rem',
                      borderRadius: 4,
                      border: '1px solid rgba(0,0,0,0.3)',
                    }}
                    numInputs={numInputs}
                    name="otp"
                    value={this.state.otp}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    focusStyle= {focusStyle}
                  />
                </div>

                <button type="button" className="my-btn mt-5" onClick={this.handleSubmit} disabled={otp.length < numInputs}>Submit</button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}