import React, {Component} from 'react';

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// return component
import ReactFusioncharts from "react-fusioncharts";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const dataSource = {
    chart: {
      caption: "Dietary Intake",
      subcaption: "0 Calories Daily intake on date",
      xaxisname: "Date",
      yaxisname: "Calories",
      numbersuffix: "",
      theme: "fusion"
    },
    data: [
        {
            label: "09-07-2020",
            value: ".5"
        },
        {
            label: "10-07-2020",
            value: ".2"
        },
        {
            label: "11-07-2020",
            value: ".5"
        },
        {
            label: "12-07-2020",
            value: ".4"
        },
        {
            label: "13-07-2020",
            value: ".7"
        },
        {
            label: "14-07-2020",
            value: ".8"
        },
        {
            label: "15-07-2020",
            value: ".9"
        },
        {
            label: "16-07-2020",
            value: "1"
        }
    ]
};

class ExerciseComponent extends Component{
    render() {
        return (
            <ReactFusioncharts
                type="column2d"
                width="100%"
                height="400"
                dataFormat="JSON"
                dataSource={dataSource}
            />
          );
    }
}

export default ExerciseComponent;