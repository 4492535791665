import React, {Component} from 'react';
import ComingSoon from '../Coming-Soon/Coming-Soon';

class Pharmacy extends Component {

    componentDidMount () {
        document.title = "Pharmacy"
    }

    render() {
        return(
            <div className="content">
                <ComingSoon />
            </div>
        );
    }
}

export default Pharmacy;