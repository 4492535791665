import React, {Component} from 'react';
import styles from './Manage-Address.module.scss';
import { Row, Container, Col, Form } from 'react-bootstrap';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import { Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AuthService from '../../../services/Auth-Service';
import { DotLoader } from 'react-spinners';

class ManageAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            states: [],
            city: [],
            state_id: null,
            city_id: null,
            locality: null,
            pincode: null,
            address: null,
            addressType: "1",
            landmark: null,
            placeOrder: false,
            showHideClass: false,
            addresses: [],
            defaultAddress: null,
            update: false,
            updateAddressId: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCity = this.getCity.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        const API = AuthService.get('diagnostic_checkout');
        API.then(res => {
            this.setState({
                isLoading: true,
                states: res['states'],
                addresses: res['address'],
                defaultAddress: res['default_address']
            });
        });
        document.title = "Manage Address"
    }

    handleEdit() {
        this.setState ({
            showHideClass: !this.state.showHideClass
        })
    }

    editAddress(add) {
        this.setState ({
            showHideClass: !this.state.showHideClass,
            update: true,
            updateAddressId: add.id,
            state_id: add.state_id,
            city_id: add.city_id,
            locality: add.locality,
            pincode: add.pincode,
            address: add.address,
            landmark: add.landmark,
            addressType: add.lable
        });
        this.getCity('', add.state_id);
    }

    cancelAddress() {
        this.setState ({
            state_id: '',
            city_id: '',
            locality: '',
            pincode: '',
            address: '',
            landmark: '',
            showHideClass: !this.state.showHideClass,
            update: false
        });
    }

    setAsDeafault(id) {
        this.setState({isLoading: true});
        const formData = new FormData();
        formData.append('add_id', id);
        const API = AuthService.post('diagnostic_checkout/setAsDeafault', formData);
        API.then(json => {
            this.setState({isLoading: false});
            if(json) {
                const API = AuthService.get('diagnostic_checkout');
                API.then(res => {
                    this.setState({
                        isLoading: true,
                        states: res['states'],
                        addresses: res['address'],
                        defaultAddress: res['default_address']
                    });
                });
            } else {
                alert("Something went wrong!");
            }
        });
    }

    deleteAddress(id) {
        var action = window.confirm('Are you sure want to delete it ?');
        if (action) {
            this.setState({isLoading: true});
            const formData = new FormData();
            formData.append('add_id', id);
            const API = AuthService.post('diagnostic_checkout/deleteAddress', formData);
            API.then(json => {
                this.setState({isLoading: false});
                if(json) {
                    const API = AuthService.get('diagnostic_checkout');
                    API.then(res => {
                        this.setState({
                            isLoading: true,
                            states: res['states'],
                            addresses: res['address'],
                            defaultAddress: res['default_address']
                        });
                    });
                } else {
                    alert("Something went wrong!");
                }
            });
        }
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
        if(name === 'gender') {
            if(value == 1)
                this.setState({genderName: 'Male'});
            else if(value == 2)
                this.setState({genderName: 'Female'});
            else
                this.setState({genderName: 'Other'});
        }
    }

    getCity(event, id='') {
        let stateId = id ? id : event.target.value;
        const formData = new FormData();
        formData.append('state_id', stateId);
        const API = AuthService.post('diagnostic_checkout/city', formData);
        API.then(json => {
            this.setState({
                state_id: stateId,
                city: json
            });
        });
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({saveAddress: true});
        var apiUrl = this.state.update ? 'diagnostic_checkout/update_address' : 'diagnostic_checkout/address';
        const formData = new FormData();
        formData.append('id', this.state.updateAddressId);
        formData.append('state', this.state.state_id);
        formData.append('city', this.state.city_id);
        formData.append('locality', this.state.locality);
        formData.append('pincode', this.state.pincode);
        formData.append('address', this.state.address);
        formData.append('landmark', this.state.landmark);     
        formData.append('lable', this.state.addressType);
        const API = AuthService.post(apiUrl, formData);
        API.then(json => {
            this.setState({saveAddress: false});
            if(json.status === 200) {
                const API = AuthService.get('diagnostic_checkout');
                API.then(res => {
                    this.setState({
                        addresses: res['address'],
                        state_id: '',
                        city_id: '',
                        locality: '',
                        pincode: '',
                        address: '',
                        landmark: '',
                        addressType: "1",
                        deliveryAddress: json.delevery_address,
                        showHideClass: false,
                        update: false
                    });
                });
                this.setState({accor: "2"});
            } else {
                alert("Something went wrong!");
            }
        });
    }

    render() {
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Manage Address
                                    <span>
                                        All Address
                                    </span>
                                </h5>
                                {
                                    this.state.isLoading ? (
                                    <>
                                    <Row className={this.state.showHideClass ? `${styles.formAddressRow} ${styles.hide} mt-5` : `${styles.formAddressRow} mt-5`}>

                                        <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                            <div className={`${styles.userDetailBox} ${styles.addNewAddress}`} onClick={this.handleEdit}>
                                                <h6>
                                                    <LocalHospitalIcon />
                                                </h6>
                                                <p>Add Address</p>
                                            </div>
                                        </Col>
                                        {this.state.addresses.map(add => (
                                            <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                                <div className={this.state.defaultAddress.id == add.id ? `${styles.userDetailBox} ${styles.userDetailBoxActive}` : `${styles.userDetailBox}`}>
                                                    <address className={`${styles.userDetailBoxAddress}`}>
                                                        <span>{add.address}, {add.landmark}, {add.locality}, {add.city_name}, {add.state_name}, {add.pincode}</span>
                                                    </address>

                                                    <div className={`${styles.userDetailBoxBtn}`}>
                                                        <Button onClick={ () => this.editAddress(add) } variant="contained">Edit</Button>
                                                        {this.state.defaultAddress.id == add.id ? (
                                                            <Button variant="contained">Default</Button>
                                                        ): (
                                                            <>
                                                            <Button onClick={ () => this.deleteAddress(add.id) } variant="contained">Delete</Button>
                                                            <Button variant="contained" onClick={ () => this.setAsDeafault(add.id) }>Set As Default</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                        
                                    </Row>
                                    {this.state.showHideClass ? (
                                        <ValidatorForm ref="form" className={`${styles.formFiledRow} customForm row`} onSubmit={this.handleSubmit}>

                                            <Form.Row as={Col} md={6} xs={12}>
                                                <TextValidator select required label="Select state" type="number" name="state_id" value={this.state.state_id} onChange={(event) => this.getCity(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                    {this.state.states.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.state_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextValidator>
                                            </Form.Row>

                                            <Form.Row as={Col} md={6} xs={12}>
                                                <TextValidator select required label="Select city" type="number" name="city_id" value={this.state.city_id} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                    {this.state.city.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.city_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextValidator>
                                            </Form.Row>
                                            
                                            <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                <TextValidator required label="Locality" name="locality" value={this.state.locality} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                            </Form.Row>
                                            
                                            <Form.Row as={Col} lg={6} md={12} xs={12}>
                                            <TextValidator required label="PinCode" name="pincode" value={this.state.pincode} onChange={(event) => this.handleChange(event)} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{6,6}$']} errorMessages={['This field is required', 'Enter only number.', 'Pincode is not valid']} autoComplete="nope" variant="outlined" />
                                            </Form.Row>
                                            
                                            <Form.Row as={Col} xs={12}>
                                                <TextValidator required label="Enter Address" multiline rows="3" name="address" value={this.state.address} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                            </Form.Row>
                                            
                                            <Form.Row as={Col} md={6} xs={12}>
                                                <TextValidator id="Landmark" required label="Enter Landmark" name="landmark" value={this.state.landmark} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                            </Form.Row>

                                            <Form.Row as={Col} md={6} xs={12}>
                                                <FormControl component="fieldset" className={`customRadio`}>
                                                    <FormLabel component="legend">Lable <sup>*</sup></FormLabel>
                                                    <RadioGroup aria-label="addressType" name="addressType" value={this.state.addressType} onChange={(event) => this.handleChange(event)}>
                                                        <FormControlLabel value="1" control={<Radio />} label="Home" />
                                                        <FormControlLabel value="2" control={<Radio />} label="Work" />
                                                        <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Form.Row>

                                            <Form.Row as={Col} xs={12}>
                                                <Button onClick={ () => this.cancelAddress() } as={Button} className="my-btn mx-auto px-4" variant="contained">
                                                    Cancel
                                                </Button>
                                                <Button as={Button} type="submit" className="my-btn my-btn-second mx-auto px-4" variant="contained" formNoValidate>
                                                    Save
                                                </Button>
                                            </Form.Row>

                                        </ValidatorForm>
                                    ) : null}
                                    </>) : (
                                        <div className={`sweet-loading customLoader`}>
                                            <DotLoader
                                                color={'#ef3c67'}
                                                loading={this.state.loading}  
                                            />
                                        </div>
                                    )
                                }

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default ManageAddress;