var initState = {};
if (JSON.parse(localStorage.getItem('userData'))) {
    initState = JSON.parse(localStorage.getItem('userData'));
} else {
    initState = {
        mobile: null,
        user_id: null,
        f_name: null,
        l_name: null,
        profile_pic: null,
        last_login: null,
        token: null
    }
}
const userAuthReducer = (state = initState, action) => {

    if(action.type == 'LOGIN') {
        localStorage.setItem('userData', JSON.stringify(action.userData));
        return action.userData;
    }
    
    if(action.type === 'LOGOUT') {
        initState = {
            mobile: null,
            user_id: null,
            f_name: null,
            l_name: null,
            profile_pic: null,
            last_login: null,
            token: null
        };
        localStorage.removeItem("userData");
        return initState;
    }

    return state;
}

export default userAuthReducer;