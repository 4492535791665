import React, { Component } from "react";
import styles from './Test-Packages.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Container, Breadcrumb, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TestPackageCard from '../Cards/Test-package-card/Test-package-card';
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';

const responsivePackagesList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 2,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 676,
            min: 1
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 678
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

class TestPackages extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            packages: []
        }
    }

    componentDidMount() {
        const API = Service.get('diagnostic/packages');
        API.then(json => {
            this.setState({
                isLoading: true,
                packages: json
            });
        });
        document.title = "TestPackages"
    }
    render() {
        var {isLoading, packages} = this.state;
        if(isLoading) {
            return(
                <div className="content bgGray">
                    <Container fluid className={`mt-n3`}>
                        <Container>
                            <Row>
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Home</Link>
                                        <Link to="/diagnostic">Diagnostic</Link>
                                        <Breadcrumb.Item active>All Packages</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>

                            </Row>
                        </Container>
                    </Container>
                    
                    <Container fluid className={`${styles.healthRiskTopPart}`}>
                        <Container>
                            <Row className={`${styles.healthRiskTopPartRow}`}>
                                <Col md={3} xs={12}>
                                    <h1>Most Selling Packages</h1>
                                    <p>
                                        DrRay24 offers you a wide range of budget-friendly packages that will not make your healthcare checkups easier but also less time-consuming. We have dedicated packages for each disease, age, gender, full-body examination, special routine checkups and even for doctors and pre-operative procedures. We also provide intensive care with the basic and comprehensive level checkup packages designed as per one’s own need. With the help of our highly qualified team of doctors and medical experts, we assure you get the best medical assessment for any emergency or day-to-day conditions.
                                    </p>
                                    <ArrowRightIcon className={`${styles.defineArrow}`} />
                                </Col>
                                <Col md={9} xs={12}>
                                    <Carousel
                                    additionalTransfrom={0}
                                    arrows={true}
                                    autoPlay
                                    autoPlaySpeed={5000}
                                    centerMode={false}
                                    className={`${styles.healthPackageSlider}`}
                                    containerClass=""
                                    dotListClass=""
                                    draggable
                                    focusOnSelect={false}
                                    infinite
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={10}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside={false}
                                    responsive={responsivePackagesList}
                                    showDots={false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    >
                                        {
                                            packages.result.map((item, index) => (
                                                index < 5 ? (
                                                <Col className={styles.healthPackage}>
                                                    <TestPackageCard test={item} />
                                                </Col>) : null
                                            ))
                                        }
                                    </Carousel>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className={`sectionSpace ${styles.relativePackage}`}>
                        <Container>
                            <Row>
                                {
                                    packages.result.map(item => (
                                        <Col className={`${styles.healthPackage}`} md={4} xs={12}>
                                            <TestPackageCard test={item} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default TestPackages;