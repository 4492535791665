import React, { Component, useState } from "react";
import styles from './Filter-card.module.scss';
import Collapse from 'react-bootstrap/Collapse';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Checkbox } from '@material-ui/core';
import { Card, ListGroup, Accordion } from 'react-bootstrap';
/********* start of checkbox  **************/
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

class FilterCard extends Component{
    render() {
        function Example(fltr) {
            const [open, setOpen] = useState(false);

            const [value, setValue] = React.useState('cod');

            const handleChange = (event) => {
                setValue(event.target.value);
            };
          
            return (
              <div className={`${styles.priceDetail}`}>
                <Card className={`${styles.priceDetailCard} customFilter`}>
                    <Card.Header className={`${styles.priceDetailHeader}`}>
                        <Button className={`d-block d-md-none`} onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                            <FilterListIcon className={`${styles.priceDetailHeaderIcon}`} /> Filter <ExpandMoreIcon className={`${styles.filterIcon}`} />
                        </Button>
                        <span className={`d-none d-md-block ${styles.priceDetailFilter}`}>
                            <FilterListIcon className={`${styles.priceDetailHeaderIcon}`} /> Filter
                        </span>
                    </Card.Header>
                    
                    <Collapse in={open} className={`priceDetailCardFilter position-relative`}>
                        <div id="example-collapse-text">

                            <span className={`${styles.clearAllBtn}`}>
                                Clear All Filter
                            </span>

                            <Card.Body className={`${styles.priceDetailBody}`}>
                                <Accordion defaultActiveKey="0" className={styles.accordionDiv}>
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="0">
                                            Gender <ExpandMoreIcon />
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className={styles.body}>
                                                <ListGroup as="ul" variant="flush">
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="GenderMale" />} label="Male" />
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="GenderFemale" />} label="Female" />
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="GenderBoth" />} label="Both" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="1">
                                            Consultation Fee <ExpandMoreIcon />
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body className={styles.body}>
                                                <ListGroup as="ul" variant="flush">
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="ConsultationFee1" />} label="Free" />
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="ConsultationFee2" />} label="1-20" />
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="ConsultationFee3" />} label="200-500" />
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                        <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="ConsultationFee4" />} label="More than 500" />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                            
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="2">
                                            Health Category <ExpandMoreIcon />
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body className={styles.body}>
                                                <ListGroup as="ul" variant="flush">
                                                    {
                                                        fltr.filter.map((item, index) => (
                                                            <ListGroup.Item as="li" key={index}>
                                                                <FormControlLabel className={`customCheckbox ${styles.filterItem}`} control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="HealthDiabetes" />} label={item.name} />
                                                            </ListGroup.Item>
                                                        ))
                                                    }
                                                    
                                                </ListGroup>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Card.Body>
                        </div>
                    </Collapse>
                </Card>
              </div>
            );
        }

        return(
            <div>
                <Example filter={this.props.filter}/>
            </div>
        );
    }
}

export default FilterCard;