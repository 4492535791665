import React, { Component } from "react";
import styles from "./Doctor-Details-Info.module.scss";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoctorOverview from "./Doctor-Overview/Doctor-Overview";

class DoctorDetailsInfo extends Component{
    render() {
        function TabPanel(props) {
            const { children, value, index, ...other } = props;
          
            return (
              <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
              >
                {value === index && (
                  <Box p={3}>
                    <Typography>{children}</Typography>
                  </Box>
                )}
              </div>
            );
        }
          
        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };
          
        function a11yProps(index) {
            return {
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            };
        }
          
        const useStyles = makeStyles((theme) => ({
            root: {
              flexGrow: 1,
              //backgroundColor: theme.palette.background.paper,
            },
        }));
          
        function DoctorDetails(data) {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
          
            const handleChange = (event, newValue) => {
              setValue(newValue);
            };
          
        return (
              <div className={classes.root}>
                <AppBar position="static" className={`${styles.doctorInfoLayout} ${styles.helpDiv}`}>
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Overview" className={`${styles.doctorInfoHeading}`} {...a11yProps(0)} />
                    <Tab className={`d-none`} label="Feedback" {...a11yProps(1)} />
                    <Tab className={`d-none`} label="Customer Support" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} className={`${styles.doctorInfoLayoutContent}`}>
                    <DoctorOverview clinics={data.clinics}/>
                </TabPanel>
                <TabPanel value={value} index={1} className={`${styles.doctorInfoLayoutContent} d-none`}>
                  Item Two
                </TabPanel>
                <TabPanel value={value} index={2} className={`${styles.doctorInfoLayoutContent} d-none`}>
                  Item Three
                </TabPanel>

              </div>
            );
        }

        return(
            <DoctorDetails clinics={this.props.clinics} />
        );
    }
}

export default DoctorDetailsInfo;