import React, { Component } from "react";
import styles from "./Doctor-List-Loader.module.scss";
import ContentLoader from "react-content-loader";
import { ListGroup } from 'react-bootstrap';

const ListLoaderDiv = () => (
    <ContentLoader 
      speed={.5}
      height={40}
      backgroundColor="#e3e3e3"
      foregroundColor="#fcfbfb"
    >
      <rect x="60" y="12" rx="0" ry="0" width="60%" height="13" />
      <rect x="250" y="13" rx="0" ry="0" width="30%" height="8" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
)

class DoctorListLoader extends Component {
    render() {
        return(
            <div className={`content`}>
                <ListGroup as="ul" className={`${styles.doctorListLoading} ${styles.heloDoctorListLoading}`}>
                    <ListGroup.Item as="li" className={`${styles.doctorListLoadingItem}`}>
                        <ListLoaderDiv />
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className={`${styles.doctorListLoadingItem}`}>
                        <ListLoaderDiv />
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className={`${styles.doctorListLoadingItem}`}>
                        <ListLoaderDiv />
                    </ListGroup.Item>
                </ListGroup>
            </div>
        );
    }
}

export default DoctorListLoader;