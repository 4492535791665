import React, { Component, useState } from "react";
import styles from './Doctor-Search.module.scss';
import { Button } from '@material-ui/core';
import Collapse from 'react-bootstrap/Collapse';
import { Form, ListGroup, InputGroup, FormControl, Image, Popover, OverlayTrigger } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {Link} from 'react-router-dom'
import Service from '../../../services/Service';
import DoctorListLoader from "./Doctor-List-Loader/Doctor-List-Loader";

const popover = (
    <Popover id="popover-basic" className={`${styles.findLocationPopup} ${styles.helpPopup}`}>
      <Popover.Title as="h3">Popular Cities</Popover.Title>
      <Popover.Content className={`${styles.findLocationBody}`}>
        <ListGroup horizontal className={`${styles.findLocationListDiv}`}>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Delhi
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Gurugram
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Mumbai
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Noida
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Pune
            </ListGroup.Item>
        </ListGroup>
      </Popover.Content>

      <Popover.Title as="h3">Other Cities</Popover.Title>
      <Popover.Content className={`${styles.findLocationBody}`}>
        <ListGroup horizontal className={`${styles.findLocationListDiv}`}>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Bhopal
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Chandigarh
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Dehradun
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Indore
            </ListGroup.Item>
            <ListGroup.Item className={`${styles.findLocationListItem}`}>
                Jaipur
            </ListGroup.Item>
        </ListGroup>
      </Popover.Content>
    </Popover>
);

class DoctorSearch extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            specializations: [],
            th: []
        }
    }

    componentDidMount() {
        const API = Service.get('doctor/specialization');
        API.then(json => {
            this.setState({
                isLoading: true,
                specializations: json['result']
            });
        });
    }

    render(){
        var {specializations} = this.state;
        function SearchCollapse(data) {
            const [result, searchResult] = useState(data.specializations);
            const [isLoading, getData] = useState(true);
            const [open, setOpen] = useState(false);
            const [showText, setShowText] = useState(false);
            const handleChange = (d) => {
                getData(false);
                if(d.length > 0) {
                    const formData = new FormData();
                    formData.append('data', d);
                    const API = Service.post('search/doctor', formData);
                    API.then(json => {
                        getData(true);
                        searchResult(json['result'])
                    });
                } else {
                    const API = Service.get('doctor/specialization');
                    API.then(json => {
                        getData(true);
                        searchResult(json['result'])
                    });
                }
            }

            const doctorName = (name) => {
                var nm;
                if(name) {
                    nm = name.replace(" ", "-");
                } else {
                    nm = name;
                }
                return nm;
            }
            return (
                <>
                <Button className={`${styles.customSearchFilterBtn} ${styles.helpBtn}`}
                    onClick={() => setOpen(!open)}
                    aria-controls="searchFilterBtn"
                    aria-expanded={open}
                >
                <SearchIcon />
                </Button>
                <Collapse in={open}>
                    <div id="searchFilterBtn" className={`${styles.searchCollapseDiv} helpSearchCollapsDiv`}>

                        <Form className={`customForm ${styles.nav_form_main}`}>
                            <InputGroup className={`${styles.searchInput}`}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Image src={window.location.origin + "/assets/images/location.jpg"} alt="Lacation" /></InputGroup.Text>
                                </InputGroup.Prepend>

                                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                                    <FormControl placeholder="Gurugram" aria-label="Detect your location" />
                                </OverlayTrigger>

                                <InputGroup.Append>
                                    <Button className="my-btn nav_detect_btn" variant="contained">
                                        <Image src={window.location.origin + "/assets/images/detect.png"} alt="Detect" /> Detect
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>

                            <InputGroup className={`${styles.searchInput}`}>
                                <TextField onClick={() => setShowText(true)} onChange={e => handleChange(e.target.value)} aria-describedby="inputGroupPrepend" required label="search doctor, clinics, pharmacy ect." className="form-control search_input" />
                                <InputGroup.Append>
                                    <Button className="nav_search_btn"
                                        onClick={() => setOpen(!open)}
                                        aria-controls="searchFilterBtn"
                                        aria-expanded={open}
                                    >
                                        <ClearIcon />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            {showText ? [(isLoading ? (
                                <ListGroup className={`${styles.locationSearch} ${styles.helpLocationSearch}`}>
                                    {
                                        result.map(item => (
                                            <ListGroup.Item className={`${styles.locationSearchList}`}>
                                                {
                                                    item.doctor_name ? (
                                                        <Link onClick={() => setOpen(!open)} className={`${styles.locationSearchLink} ${styles.doctorSearchLink}`} to={`/search/doctor/${doctorName(item.doctor_name)}/${item.id}`}><Image src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} roundedCircle className={`mr-3`} height="30" width="30" alt="Dr" /> {item.doctor_name} <span>{item.specialization}</span></Link>
                                                    ) : (
                                                        <Link onClick={() => setOpen(!open)} className={`${styles.locationSearchLink}`} to={`/search/specialization/${item.specialization}/${item.id}`}><SearchIcon className={`${styles.listIcon}`} /> {item.specialization} <span>Speciality</span></Link>
                                                    )
                                                }
                                            </ListGroup.Item>
                                        ))
                                    }
                            </ListGroup>
                            ) : (<DoctorListLoader />))] : null}
                        </Form>
                    </div>
                </Collapse>
                </>
            );
        }

        return(
            <div>
                <SearchCollapse specializations={specializations}/>
            </div>
        );
    }
}

export default DoctorSearch;