import React, {Component} from 'react';
import styles from "./Edit-Password.module.scss";
import { Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Modal from 'react-bootstrap/Modal';
import AuthService from '../../../../services/Auth-Service';

class EditPassword extends Component{

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            formSubmit: false,
            oldPassword: '',
            password: '',
            repeatPassword: ''
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }
 
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleClose(){
        this.setState({show: false})
    }

    handleShow(){
        this.setState({show: true})
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('oldPassword', this.state.oldPassword);
        formData.append('password', this.state.password);
        const API = AuthService.post('edit_profile/password', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if(json.status == 300){
                alert("Old Password is not correct!");
            } else if(json.status == 200) {
                alert("Password has been changed successfully!");
                this.handleClose();
            } else {
                alert("Something went wrong!");
            }
        })
    }

    render() {

        return(
            <div>
                <Button className={`my-btn ${styles.editFiledText} ${styles.helpPopup}`} variant="contained" onClick={this.handleShow}>Edit</Button>
          
                <Modal show={this.state.show} onHide={this.handleClose} centered className="customModal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Password
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        
                        <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit}>
                            <Form.Row as={Col} xs={12} className={`${styles.EditFormText}`}>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </Form.Row>

                            <Form.Row as={Col} xs={12}>
                                <TextValidator required type="password" label="Old password" name="oldPassword" validators={['required']} errorMessages={['This field is required!']} onChange={this.handleChange} value={this.state.oldPassword} variant="outlined" />
                            </Form.Row>

                            <Form.Row as={Col} xs={12}>
                                <TextValidator required type="password" label="New Password" name="password" validators={['required']} errorMessages={['This field is required!']} onChange={this.handleChange} value={this.state.password} variant="outlined" />
                            </Form.Row>

                            <Form.Row as={Col} xs={12}>
                                <TextValidator required type="password" label="Confirm Password" name="repeatPassword" validators={['required', 'isPasswordMatch']} errorMessages={['This field is required!', 'Password mismatch!']} onChange={this.handleChange} value={this.state.repeatPassword} variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} xs={12}>
                                <Button className="my-btn mx-auto px-5" variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                    Save Password
                                </Button>

                                <Button className="my-btn my-btn-second mx-auto px-5" variant="contained" type="button" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </Form.Row>
                        </ValidatorForm>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default EditPassword;