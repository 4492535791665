export default {
    get: (url) => {
        return fetch('https://stage.drray24.in/drray24/'+url, {
            method: 'get',
            headers: new Headers({
                'Client-Service': 'frontend-client',
                'Auth-Key': 'arvindgetw'
            })
        }).then(res => res.json());
    },

    post: (url, data) => {
        return fetch('https://stage.drray24.in/drray24/'+url, {
            method: 'post',
            headers: new Headers({
                'Client-Service': 'frontend-client',
                'Auth-Key': 'arvindgetw'
            }),
            body: data
        }).then(res => res.json());
    }
}