import React, { Component } from "react";
import styles from "./Doctor-Details.module.scss";
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import EmailIcon from '@material-ui/icons/Email';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import "react-multi-carousel/lib/styles.css";
import { Container, Figure, Image, Row, Col, Breadcrumb, Card, Nav, ListGroup } from 'react-bootstrap';
import { Link } from "@material-ui/core";
import DoctorDetailsInfo from "./Doctor-Details-Info/Doctor-Details-Info";
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';
import DoctorSpecialistComponent from "../../Carousel-Card/Doctor-Specialist/Doctor-Specialist";

const responsiveItemList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1.8,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

class DoctorDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            profile: null
        }
    }

    componentDidMount() {
        var params = this.props.match.params;
        const formData = new FormData();
        formData.append('doctor_id', params.id);
        const API = Service.post('doctor/doctorSearch', formData);
        API.then(json => {
            this.setState({
                isLoading: true,
                profile: json['result']
            });
        });
        document.title = "Doctor | " + params.name;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({isLoading: false});
            const formData = new FormData();
            formData.append('doctor_id', nextProps.match.params.id);
            const API = Service.post('doctor/doctorSearch', formData);
            API.then(json => {
                this.setState({
                    isLoading: true,
                    profile: json['result']
                });
            });
        }
    }

    render() {
        var {isLoading, profile} = this.state;
        if(isLoading) {
            return(
                <div className={`content bgGray`}>
                    
                    <Container fluid>
                        <Container>
                            <Row>
                                
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Doctors</Link>
                                        <Breadcrumb.Item active>Doctors Details</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>

                            </Row>
                        </Container>
                    </Container>

                    <Container fluid>
                        <Container>
                            <Row>
                                
                                <Col xs="12" className={`${styles.profileDetails}`}>
                                    <Card className={`${styles.profileDetailDivCard}`}>
                                        <Card.Header className={`${styles.profileDetailHeader}`}>

                                            <Figure className={`${styles.profileDetailProfileImg}`}>
                                                <Image className="mx-auto" src={window.location.origin + "/assets/images/home/doctors/image_1.jpg"} fluid alt="Profile Photo" />
                                            </Figure>

                                            <div className={`${styles.profileDetailContent}`}>
                                                <Col md={7} xs={12} className={`${styles.profileDetailContentLeft}`}>
                                                    <h1>
                                                        Dr. {profile.doctor.f_name} {profile.doctor.l_name}, <span>{profile.doctor.specialization}</span>
                                                    </h1>
                                                    <h5>
                                                        <span>{profile.doctor.experience} Years Experience</span>
                                                    </h5>
                                                </Col>
                                                
                                                <Col md={5} xs={12} className={`${styles.profileDetailContentRight}`}>
                                                    <h3>
                                                        Social Network
                                                    </h3>
                                                    <Nav className={`${styles.SocialIcons}`}>
                                                        <Nav.Item className={`${styles.navItem}`}>
                                                            <Nav.Link href="#" className={`${styles.facebook} ${styles.navLink}`}><FacebookIcon className={`${styles.socialIcon}`} /></Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className={`${styles.navItem}`}>
                                                            <Nav.Link href="#" className={`${styles.twitter} ${styles.navLink}`}><TwitterIcon className={`${styles.socialIcon}`} /></Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className={`${styles.navItem}`}>
                                                            <Nav.Link href="#" className={`${styles.linkedin} ${styles.navLink}`}><LinkedInIcon className={`${styles.socialIcon}`} /></Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className={`${styles.navItem}`}>
                                                            <Nav.Link href="#" className={`${styles.youtube} ${styles.navLink}`}><YouTubeIcon className={`${styles.socialIcon}`} /></Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                            </div>
                                        </Card.Header>

                                        <Card.Body className={`${styles.profileDetailBody}`}>
                                            <span className={`${styles.likePercentage}`}>
                                                <ThumbUpIcon /> 92%
                                            </span>

                                            <div className={`${styles.profileDetailBodyContent}`}>
                                                <Col md={12} xs={12}>
                                                    <p>{profile.doctor.about_me}</p>
                                                </Col>
                                            </div>

                                            <ListGroup horizontal className={`${styles.profileBrief}`}>
                                                <ListGroup.Item className={`${styles.profileBriefText}`}>
                                                    <SettingsPhoneIcon className={`${styles.profileBriefIcon}`} /> 888-000-000
                                                </ListGroup.Item>

                                                <ListGroup.Item className={`${styles.profileBriefText}`}>
                                                    <VerifiedUserIcon className={`${styles.profileBriefIcon} text-success`} /> Medical Registration <b>Verified</b>
                                                </ListGroup.Item>

                                                <ListGroup.Item className={`${styles.profileBriefText}`}>
                                                    <EmailIcon className={`${styles.profileBriefIcon}`} /> sample@gmail.com
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs="12 mt-5">
                                    <DoctorDetailsInfo clinics={profile.clinics} />
                                </Col>

                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className={`sectionSpace ${styles.customItemSlider}`}>
                        <Container>
                            <Col className="px-0">
                                <span className={`text-center d-block secondary-color text-uppercase mb-0 h5`}>Match</span>
                                <h3 className="customHeading">Other's Doctors</h3>
                                <p className="sectionText">
                                    We have a long list of MBBS, MD and MS degree holder doctors are experienced in every way. Other than that we have surgeons, gynecologist and lab attendants who come from a very strong medical background and holds the ability to perform tough diagnostic procedures in critical conditions. Their Experience and intelligence speaks from their work.
                                </p>
                            </Col>

                            <DoctorSpecialistComponent />
                        </Container>
                    </Container>

                </div>
            )
        }  else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default DoctorDetails;