import React, { Component } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import PhotoGallery from "./Photo-Gallery";

class ClinicPhotoGallery extends Component{
    render() {
        return(
            <div className="App">
            <SimpleReactLightbox>
                <PhotoGallery images={this.props.images}/>
            </SimpleReactLightbox>
            </div>
        );
    }
}

export default ClinicPhotoGallery;