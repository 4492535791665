export default {
    get: (url) => {
        var userData = JSON.parse(localStorage.getItem('userData'));
        return fetch('https://stage.drray24.in/drray24/'+url, {
            method: 'get',
            headers: new Headers({
                'Client-Service': 'frontend-client',
                'Auth-Key': 'drray24',
                'User-ID' : userData.user_id,
                'Token' : userData.token
            })
        }).then(res => res.json());
    },

    post: (url, data) => {
        var userData = JSON.parse(localStorage.getItem('userData'));
        return fetch('https://stage.drray24.in/drray24/'+url, {
            method: 'post',
            headers: new Headers({
                'Client-Service': 'frontend-client',
                'Auth-Key': 'drray24',
                'User-ID' : userData.user_id,
                'Token' : userData.token
            }),
            body: data
        }).then(res => res.json());
    }
}