import React, { Component } from "react";
import styles from './Health-Risk.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Container, Breadcrumb, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TestPackageCard from '../Cards/Test-package-card/Test-package-card';
import TestCard from '../Cards/Test-card/Test-card';
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';

const responsivePackagesList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 2,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 676,
            min: 1
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 678
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

class HealthRisk extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            healthRisk: []
        }
    }

    componentDidMount() {
        var params = this.props.match.params;
        const formData = new FormData();
        formData.append('id', params.id);
        const API = Service.post('diagnostic/healthRisk', formData);
        API.then(json => {
            this.setState({
                isLoading: true,
                healthRisk: json
            });
        });
        document.title = "HealthRisk | " + params.name;
    }

    render() {
        var {isLoading, healthRisk} = this.state;
        if(isLoading) {
            return(
                <div className="content bgGray">

                    <Container fluid className={`mt-n3`}>
                        <Container>
                            <Row>
                                
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Home</Link>
                                        <Link to="/diagnostic">Diagnostic</Link>
                                        <Breadcrumb.Item active>Category</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>

                            </Row>
                        </Container>
                    </Container>
                    
                    <Container fluid className={`${styles.healthRiskTopPart}`}>
                        <Container>
                            <Row className={`${styles.healthRiskTopPartRow}`}>

                                <Col md={3} xs={12}>
                                    <h1>{healthRisk.result.health_category.name}</h1>
                                    <p>
                                    {healthRisk.result.health_category.short_description}
                                    </p>
                                    <ArrowRightIcon className={`${styles.defineArrow}`} />
                                </Col>

                                <Col md={9} xs={12}>

                                    <Carousel
                                    additionalTransfrom={0}
                                    arrows={true}
                                    autoPlay
                                    autoPlaySpeed={5000}
                                    centerMode={false}
                                    className={`${styles.healthPackageSlider}`}
                                    containerClass=""
                                    dotListClass=""
                                    draggable
                                    focusOnSelect={false}
                                    infinite
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={10}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside={false}
                                    responsive={responsivePackagesList}
                                    showDots={false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    >
                                        {
                                            healthRisk.result.health_package.map(item => (
                                                <Col className={styles.healthPackage}>
                                                    <TestPackageCard test={item} />
                                                </Col>
                                            ))
                                        }
                                    </Carousel>

                                </Col>

                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className={`sectionSpace ${styles.relativePackage}`}>
                        <Container>

                            <Col className={`customSecondHeading ${styles.filterTop}`}>
                                <h5>
                                    {healthRisk.result.health_category.name} Related Tests
                                    <span>
                                        {healthRisk.result.total} Tests
                                    </span>
                                </h5>
                            </Col>

                            <Row className={`${styles.listingRow}`}>
                                {
                                    healthRisk.result.tests.map(item => (
                                        <Col md={4} xs={12}>
                                            <Col className={styles.bookedTestMain}>
                                                <TestCard test={item} />
                                            </Col>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default HealthRisk;