import React, {Component} from 'react';
import styles from './My-Doctor.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import MyDoctorListComponent from './My-Doctor-List/My-Doctor-List';
import { DotLoader } from 'react-spinners';
import AuthService from '../../../services/Auth-Service';

class MyDoctor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            doctors: []
        }
    }

    componentDidMount() {
        const API = AuthService.get('my_doctors');
        API.then(json => {
            this.setState({
                isLoading: true,
                doctors: json.result
            });
        });
        document.title = "My Doctor"
    }

    render() {
        var {isLoading, doctors} = this.state;
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    My Doctor
                                    <span>
                                        Doctors List
                                    </span>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`tableMainDiv`}>
                                        {
                                            isLoading ? (<MyDoctorListComponent doctors={doctors}/>) : 
                                            (<div className={`sweet-loading customLoader`}>
                                                <DotLoader
                                                    color={'#ef3c67'}
                                                    loading={this.state.loading}  
                                                />
                                            </div>)
                                        }
                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default MyDoctor;