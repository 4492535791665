import React, { Component } from "react";
import styles from './Privacy-Policy.module.scss';
import { Container, Image, Row, Col, Breadcrumb } from 'react-bootstrap';

class PrivacyPolocy extends Component{

    componentDidMount() {
        document.title = "Privacy Polocy"
    }
    
    render() {
        return(
            <div className="content">
                
                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>Privacy Policy</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Privacy Policy
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={styles.staticPageContent}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <p>
                                    GETTOOWELL Technology Private Limited (referred to as us, we, our, GETTOOWELL or company) is the owner and operator of the website www.drray24.in as well as the software and other applications provided through the website or mobile application ‘drray24’.
                                </p>
                                <p>
                                    This privacy policy describes our commitment about the privacy and protection of personal information of the users of our services, including doctors, patients and the visitors of the website (referred to as you, end-user or user). 
                                </p>
                                <p>
                                    BY USING OUR SERVICES AND/OR PROVIDING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND CONSENTED UPON OUR PRIVACY POLICY. AS PER THIS PRIVACY POLICY YOU HEREBY AGREED UPON COLLECTION, USAGE, SHARING AND DISCLOSURE OF YOUR INFORMATION. HOWEVER, WE RESERVE THE RIGHT TO CHANGE, ADD OR DELETE ANY PART OF THIS PRIVACY POLICY, AT ANY POINT OF TIME AND AT OUR SOLE DISCRETION. IF YOU DO NOT AGREE UPON THIS PRIVACY POLICY, THEN DO NOT USE ANY OF OUR SERVICES OR DO NOT PROVIDE US ANY OF YOUR INFORMATION. ALSO, IF YOU USE OUR SERVICES ON BEHALF OF SOMEONE ELSE, THEN IT IMPLIES THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY AND HAS ACCEPTED THIS PRIVACY POLICY ON THEIR BEHALF.
                                </p>
                                <p>
                                    <b>
                                        However, the company will be free to collect, use and disclose information which is available free on the public domain without the user’s prior consent.
                                    </b>
                                </p>

                                <h5>
                                    Privacy statement for users
                                </h5>
                                <p>
                                    The use of company’s services by the user clearly implies that the acceptance of the company’s privacy policy. However, if any user disagrees with the provisions of the company he/she has the option to discontinue our services with immediate effect.
                                </p>
                                <p>
                                    All the information provided by you to the company, including personal or any sensitive information is voluntary. Also the company reserves the right to use any information provided by you for the following purposes such as: - the company can publish such information on its website; or can contact you for offering new products and services; also the company can contact you for taking feedback on its products and services; or can analyze software usage pattern to improve our product.
                                </p>
                                <p>
                                    The company does not endorse your personal information to third party without your consent. Also the company specifically disclaims any liability w.r.t. any service and any action resulting from your participation in any service and believes that you agreed upon waiving any claims against the company relating to the same.
                                </p>
                                <p>
                                    The user is responsible for accuracy of the data which is being submitted to us like - your contact details provided during registration process. In case there is a change in your personal data then, you can update or delete inaccuracies, by contacting us through info@drray24.in. If the company finds that the information provided by the user is false, inaccurate or incomplete then GETTOOWELL may at its sole discretion discontinue the provisions of the services to the user.
                                </p>
                                <p>
                                    If the user did not want to continue our services and wants that the company will no longer use his/her data, contact us through info@drray24.in. In case of withdrawal of consent by the user, or request for cancellation of account, the company may not provide you its services or can terminate any of its existing relationship with the user.
                                </p>
                                <p>
                                    In case the user did not want to receive non-essential communications like promotional or marketing-related emails regarding our services, the user can send us an email at info@drray24.in.
                                </p>
                                <p>
                                    We may require you to pay with a debit card, credit card or cheque for our services for which subscription amount is payable. Then we will collect your information such as credit card number and/or bank account numbers for the billing and payment processes, including but not limited to the use and disclosure of such debit card or credit card number and information to 3rd party where ever necessary to complete such billing operations. However, verification of credit information is solely accomplished by you through the authentication process. Your debit card or credit card details are transacted upon secure sites of approved payment gateways which are digitally under encryption, thereby providing the highest possible degree of care as per current technology. However, we provide you an option not to save your payment details. You are advised that internet technology is not completely safe and you should exercise discretion on using the same.
                                </p>
                                <p>
                                    You may have limited access to ‘www.drray24.in’ without creating an account on it. You can make appointments without registration on the website, with the doctors by providing your name and phone number. However to have access to all our services you must first create an account on the website. In order to create an account, you are required to provide your details, allowing others, including GETTOOWELL to identify you such as - your name, email-Id, country, age, telephone number, password, valid financial account information etc. In future, the company may include other optional data requests also from you to help us serve you better.
                                </p>
                                <p>
                                    This privacy policy applies to all the services provided by GETTOOWELL. If user wants to visit the third party website which is linked to www.drray24.in, he/she can do this entirely at his/her own risk. We encourage you to read the privacy policies of such websites.
                                </p>
                                <p>
                                    The company does not collect any information about the visitors of our website from other sources, for user registration purposes.
                                </p>
                                <p>
                                    The company does not intend to share, sell or rent our users e-mail address to 3rd party without the user’s prior consent.
                                </p>
                                <p>
                                    The company has implemented best security policies to protect our users’ personal data from unauthorized access, or misuse. However, loss of data or theft due to unauthorized access to the user’s computer through which the user avails our services, the company shall not be held liable for any such loss of user.
                                </p>

                                <h5>
                                    Clients section:
                                </h5>
                                <p>
                                    The personal information is collected from the practitioners as part of the registration process of the practitioners on www.drray24.in.
                                </p>
                                <p>
                                    All practitioners are required to read and understand the privacy statements before submitting any personal information on the website, failing which they may require to leave the services of the company.
                                </p>
                                <p>
                                    Our clients’ personally information, which they choose to provide to GETTOOWELL, is exclusively owned by the company. Client will be the owner of his/her information and his/her consent to us for collecting, using and/or disclosing this information for the purposes hereinafter stated. The company may use such information for commercial purposes. The company also reserves the right to use such information for the following purposes: the company can publish such information on its website; it can contact its clients for offering new products or services; the company can also contact its clients for taking feedback on its products and services; we can analyze software usage patterns for improving product design and utility.
                                </p>
                                <p>
                                    The company automatically enables the listing of doctors’ information on its website for every new practitioner added. The doctor’s information is displayed on the website when any visitor searches for doctors on www.drray24.in, and the doctor’s information displayed on the website is used by the visitor to request for their appointment. Any personal information of the doctors listed on the website will not be generated by the company. However, the company displays such information on its website on an as-is basis making no warranty on the accuracy of the data. Meanwhile, we encourage doctors to check the accuracy of their data from time to time, and immediately inform us in case of any discrepancies.
                                </p>

                                <h5>
                                    Patients/ Visitors sections
                                </h5>
                                <p>
                                    Personal information is collected from the patients/visitors as part of the registration process which is available to them on www.drray24.in.
                                </p>
                                <p>
                                    All patients/visitors are required to read and understand the privacy policy before submitting any personal information on the website, failing which they may require to leave the services of the company immediately.
                                </p>
                                <p>
                                    In case the end user has inadvertently submitted any information on our website before reading the privacy policy, and the patient/visitor do not agree with the manner in which such information is used, collected or disclosed, then he/she can change or delete such information by using the options provided by www.drray24.in. In addition, you can do it by sending an email to us on info@drray24.in.
                                </p>
                                <p>
                                    Patients/visitors personal information, which they choose to provide to GETTOOWELL, is exclusively owned by the company. Patients’ health record for the following purposes such as: the company can analyze software usage patterns to improve its product design; we can analyze such information for the purpose of research and development (R&D) of new technologies; the company can use analysis of user data in its other commercial product offerings; the company can also share analysis of such information with 3rd parties for commercial use.
                                </p>
                                <p>
                                    The company will communicate you through email, phone and/or notices posted on the website or through any other means available through the service. You can change your e-mail and other contact details at any point of time by logging into your account at www.drray24.in and by changing your account settings.
                                </p>
                                <p>
                                    The company may keep records of electronic communications such as - phone calls or emails etc. for making appointments or other purposes for administration purposes like customer support, research and development (R&D); or for better listing of doctors.
                                </p>
                                <p>
                                    In order to provide our services to you, the company may provide your personal information to 3rd parties who work on behalf of or with us to provide you with such services, to help us communicate with you or to maintain the website. Generally, such 3rd parties do not have any independent right to share such information; however certain 3rd parties those provide services on our website may use or disclose the information collected in connection with the provision of these services as per their own privacy policy. In such circumstances, the end-user consent to the company disclosing his/her personal information to 3rd party, solely for the intended purposes only.
                                </p>
                                <p>
                                    Any person will not be treated as a casual visitor if he/she have willingly submitted any personal information on our website by any means, including registration process on the website, email or post. All such visitors will be treated as, users as per the company’s privacy policy, and this privacy policy apply to such persons.
                                </p>

                                <h5>
                                    Data security
                                </h5>
                                <p>
                                    The users’ personal information is maintained by the company in electronic form on its equipment and it may also be converted into physical form from time to time. The company takes all necessary precautions to protect the users’ personal data both online and off-line.
                                </p>
                                <p>
                                    It is advised that the user will protect their passwords against unauthorized access either from their computer or from their mobile phones. After completing the work user should log off from the website. The company does not undertake any liability for any unauthorized access of user’s account and password. If any user suspects any unauthorized use of his/her account, he/she should immediately notify the company by sending an email to info@drray24.in.
                                </p>
                                <p>
                                    The company binds its employees to strict confidentiality obligations regarding all user information.
                                </p>
                                <p>
                                    The company, as part of its functionality, is assisting the patients to access information which is related to them. The company may retain and submit such records to the relevant patients, or to their doctors.
                                </p>
                                <p>
                                    Notwithstanding the above, the company will not be responsible for the security, confidentiality or distribution of any of your personal data by our partners and 3rd parties outside the scope of our agreement with such partners and 3rd parties. Moreover, the company shall not be responsible for any breach of security or for any actions of any 3rd party or event that are beyond the reasonable control of us including but not limited to, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of internet service or telephone service of the user etc.
                                </p>

                                <h5>
                                    Revision of policy
                                </h5>
                                <p>
                                    GETTOOWELL Technology Pvt. Ltd. may update this privacy policy at any point of time, with or without the prior notice. In any situation, there will be significant changes in the way, the company treats its users’ personal data, or in the privacy policy itself, the company will display a notice on its website ‘www.drray24.in’ or send its users an email, so that the users may review the changed terms & conditions prior to continuing to use our services. In case, the user is agree to any of our changed terms, and he/she no longer wish to continue to use our services, he/she may contact at info@drray24.in to deactivate his/her account. The company believes that if the user uses its services after the notice of changes has been sent to such user and/or published on its website, such user hereby provides his/her consent to the revised terms & conditions.
                                </p>

                                <h5>
                                    Minor’s Privacy
                                </h5>
                                <p>
                                    The company’s website and its services are not intended for use by children and minors. We respect the privacy of minors who may inadvertently use the internet or the mobile application. We recommend using our website and its services under supervision of parents.
                                </p>

                                <h5>
                                    User Consent
                                </h5>
                                <p>
                                    The user acknowledge that this privacy policy is a part of the terms of use of our website and other services, and the user unconditionally agree that becoming a user of our website and its services signifies the user’s assent to this privacy policy; and consent to the company collecting, using and/or disclosing the user personal data in the manner and for the purposes set out in this privacy policy. Visit to our website by the user and use of our services is subject to privacy policy and terms of use.
                                </p>

                                <h5>
                                    Information as per nature of service
                                </h5>

                                <h5>
                                    User creating account via registering on the website
                                </h5>
                                <p>
                                    End-user can create an account by providing his/her personal data such as- name, phone number, email-ID etc., on the registration page. This enables the company to provide the end-user with the facility to use the account to book his/her appointment and can also store other health related information.
                                </p>

                                <h5>
                                    User using the website without registration 
                                </h5>
                                <p>
                                    End-user can use www.drray24.in without account registration on the website, however, in order to book an appointment, user has to provide certain information such as his/her name, phone number, email-ID etc., and any other information as requested when he/she can choose to use the services to confirm the appointment.
                                </p>

                                <h5>
                                    Doctors availing listing service on the website via a/c registration
                                </h5>
                                <p>
                                    Doctor may be required to provide us the personal information such as his/her name, phone number, email-ID etc., and any other information as requested on the doctor registration form to create an account. We may send an email or SMS confirmation or other communications to them regarding their bookings, appointments or other interactions etc.
                                </p>

                                <h5>
                                    Doctors using the ‘Tab/Desktop’ products
                                </h5>
                                <p>
                                    Doctors will be required to create an account and may be required to provide us the information such as - name, phone number, email-ID etc., and such other information as requested by the company on the Tab/Desktop doctor registration page, to complete their registration.
                                </p>
                                <p>
                                    Post doctor’s registration, the company will access non-personally identifiable information of their patients from their patient records. Doctors agree to make their patients fully aware of such access.
                                </p>
                                <p>
                                    Also, doctors will have an option under these products to switch on user feedback form, which means that doctor has given his/her patients’ contact details to our feedback system. The feedback system will then send an email or SMS to the patient(s) asking for their feedback which may then be published on our website. Doctors agree to make their patients fully aware of the possibility of their receiving such feedback queries.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default PrivacyPolocy;