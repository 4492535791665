import React, { Component } from "react";
import styles from "./Doctor-Overview.module.scss";
import { TextField, MenuItem } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import DoctorClinicItem from "./Doctor-Clinic-Item/Doctor-Clinic-Item";

const clinics = [
    {
      value: 'relevance',
      label: 'Relevance',
    },
    {
      value: 'LowHigh',
      label: 'Price - Low to High',
    },
    {
      value: 'HighLow',
      label: 'Price - High to Low',
    },
    {
      value: 'YearExperience',
      label: 'Years of Experience',
    },
];

class DoctorOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {addClass: false};
        this.state = {
            rating: 2.0,
        };
    }
    toggle() {
        this.setState ({
            addClass: !this.state.addClass
        });
    }
     
    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
    }

    render() {
        const { rating } = this.state;
        return(
            <Row>
                <Col xs={12} className={`customSecondHeading ${styles.filterTop}`}>
                    <h5>
                        Dr. Name
                        <span>{this.props.clinics.length} Clinic{this.props.clinics.length > 1 ? 's' : null}</span>
                    </h5>

                    <TextField id="SortBy" className={`${styles.sortByFilter} customDropDown`} select required label="Sort By" name="SortBy" variant="outlined">
                        {clinics.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Col>
                {
                    this.props.clinics.map(item => (
                        <Col xs={12} className={`${styles.clinicFolderContent}`}>
                            <DoctorClinicItem clinic={item}/>
                        </Col>
                    ))
                }
            </Row>
        );
    }
}

export default DoctorOverview;