import React, {Component} from 'react';
import styles from './Image-Uploader.module.scss';
import { Popover, OverlayTrigger, Figure, Image } from 'react-bootstrap';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Button } from '@material-ui/core';

class ImageUploaderComponent extends Component{
    render() {
        function ImageUploaders() {
            const uploadedImage = React.useRef(null);
            const imageUploader = React.useRef(null);
            const popover = (
                <Popover id="popover-basic" className={`${styles.uploadImage} ${styles.helpPopup}`}>
                    <Popover.Title as="h3" onClick={() => imageUploader.current.click()}>
                        Select
                    </Popover.Title>
                    {
                        uploadedImage.current ? (
                            <Popover.Title as="h3">
                                Upload
                            </Popover.Title>) : null
                    }
                    <Popover.Title as="h3">
                        Remove
                    </Popover.Title>
                </Popover>
            );

            const handleImageUpload = e => {
                const [file] = e.target.files;
                if (file) {
                const reader = new FileReader();
                const { current } = uploadedImage;
                current.file = file;
                reader.onload = e => {
                    current.src = e.target.result;
                };
                reader.readAsDataURL(file);
                }
            };
          
            return (
                <div className={`${styles.profile_image}`}>
                    <Figure>
                        <input type="file"
                        accept="image/*" onChange={handleImageUpload} ref={imageUploader}
                        style={{ display: "none" }}
                        />
                        <Image ref={uploadedImage} src="assets/images/home/customer_img/user.jpg" roundedCircle alt="User" />
                        <span className={`${styles.AddAPhotoIconSpan}`}>
                            <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                                <Button variant="contained"><AddAPhotoIcon /></Button>
                            </OverlayTrigger>
                        </span>
                    </Figure>
                </div>
            );
        }

        return(
            <ImageUploaders />
        );
    }
}

export default ImageUploaderComponent;