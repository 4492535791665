import React, { Component } from 'react';
import styles from './My-Appointment-List.module.scss';
import MaterialTable from 'material-table';
import {Link} from 'react-router-dom';

import { forwardRef } from 'react';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn } from "@material-ui/icons";

class MyAppointmentListComponent extends Component {
    render() {

        function MaterialTableDemo(app) {
            var i = 0;
            const [state, setState] = React.useState({
              columns: [
                { title: "Sr. No.", render: rowData => rowData.tableData.id + 1  },
                { title: 'Image', field: 'imageUrl', cellStyle: { fontSize: '.9rem' } , render: rowData => (<Link to={{ pathname: '/foo/'+`${rowData.id}` }}><img src='assets/images/home/doctors/image_1.jpg' style={{width: 40, borderRadius: '50%'}}/></Link>) },
                { title: 'Doctor Name', field: 'doctor_name', cellStyle: { fontSize: '.9rem' } , render: doctorLink => (<Link to={{ pathname: '/foo/'+`${doctorLink.id}` }}>{ doctorLink.name }</Link>) },
                { title: 'Phone No.', field: 'mobile', cellStyle: { fontSize: '.9rem' } },
                { title: 'Patient Name', field: 'patient_name', cellStyle: { fontSize: '.9rem' } },
                { title: 'Date / Time', field: 'appointment_time', cellStyle: { fontSize: '.9rem' } },
                { title: 'Status', field: 'status', cellStyle: { fontSize: '.9rem' }, render: patientStatus => (<span className={`${styles.statusText} ${styles.pendingText}`}>{ patientStatus.status }</span>)  },
              ],
              data: app.app,
            });

            const tableIcons = {
                Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
                ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
            }
          
            return (
              <MaterialTable
                title="My Appointments"
                columns={state.columns}
                data={state.data}
                icons={tableIcons}
              />
            );
        }

        return(
            <MaterialTableDemo app={this.props.app} />
        );
    }
}

export default MyAppointmentListComponent;