import React, {Component} from 'react';
import styles from './Edit-Profile.module.scss';
import { Row, Container, Col, Card, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import { TextField, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditEmail from './Edit-Email/Edit-Email';
import EditPassword from './Edit-Password/Edit-Password';
import EditPhoneNumber from './Edit-Phone-Number/Edit-Phone-Number';
import { DotLoader } from 'react-spinners';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AuthService from '../../../services/Auth-Service';
import Service from '../../../services/Service';

const country = [
    {
      value: 'delhi',
      label: 'Delhi',
    },
    {
      value: 'gurugram',
      label: 'Gurugram',
    },
    {
      value: 'noida',
      label: 'Noida',
    },
];

const bloodGroup = [
    {
      value: 'o-positive',
      label: 'O+',
    },
    {
      value: 'a-positive',
      label: 'A+',
    },
    {
      value: 'b-positive',
      label: 'B+',
    },
];

class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            city: [],
            isLoading: false,
            formSubmit: false,
            email: '',
            mobile: '',
            f_name: '',
            l_name: '',
            address: '',
            house_no: '',
            locality: '',
            state_id: '',
            city_id: '',
            pincode: '',
            dob: '',
            blood_group: '',
            gender: '',
            updateSuccess: false,
            vertical: 'top',
            horizontal: 'center',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const API = AuthService.get('edit_profile');
        API.then(json => {
            this.setState({
                isLoading: true,
                email: json.result['profile'].email,
                mobile: json.result['profile'].mobile,
                f_name: json.result['profile'].f_name,
                l_name: json.result['profile'].l_name,
                address: json.result['profile'].address,
                house_no: json.result['profile'].house_no,
                locality: json.result['profile'].locality,
                state_id: json.result['profile'].state_id,
                city_id: json.result['profile'].city_id,
                pincode: json.result['profile'].pincode,
                dob: json.result['profile'].dob,
                blood_group: json.result['profile'].blood_group,
                gender: json.result['profile'].gender,
                states: json.result['states']
            });
            const formData = new FormData();
            formData.append('state_id', this.state.state_id);
            const API = Service.post('diagnostic_checkout/city', formData);
            API.then(json => {
                this.setState({city: json});
            });
        });
        document.title = "Edit Profile"
    }

    getCity(event) {
        let stateId = event.target.value;
        const formData = new FormData();
        formData.append('state_id', stateId);
        const API = Service.post('diagnostic_checkout/city', formData);
        API.then(json => {
            this.setState({
                city_id: null,
                state_id: stateId,
                city: json
            });
        });
    }

    handleDateChange(d, name) {
        if(d) {
            var dob = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
            this.setState({[name]: dob});
        }
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('f_name', this.state.f_name);
        formData.append('l_name', this.state.l_name);
        formData.append('address', this.state.address);
        formData.append('house_no', this.state.house_no);
        formData.append('locality', this.state.locality);
        formData.append('state_id', this.state.state_id);
        formData.append('city_id', this.state.city_id);
        formData.append('pincode', this.state.pincode);
        formData.append('dob', this.state.dob);
        formData.append('blood_group', this.state.blood_group ? this.state.blood_group : '');
        formData.append('gender', this.state.gender);

        const API = AuthService.post('edit_profile', formData);
        API.then(json => {
            this.setState({formSubmit: false, updateSuccess: true});
        })
    }

    render() {
        const { isLoading, vertical, horizontal, updateSuccess } = this.state;

        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
    
        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({updateSuccess: false});
        };

        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                { isLoading ? (
                                    <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit}>
                                        <h5>
                                            Accounts
                                            <span>
                                                Profile Details
                                            </span>
                                            <Button id="loginFormWidOtpBtn" className={`my-btn ${styles.topSaveBtn} loginFormWidOtpBtn`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                                Save Changes
                                            </Button>
                                        </h5>

                                        <Row className={`mt-5 px-md-3`}>

                                            <Card as={Col} xs={12} className={`${styles.editProfileCard}`}>
                                                <Card.Body className={`${styles.editProfileCardBody}`}>

                                                    <Form.Row as={Col} className={`px-0 w-100`}>
                                                        <Col md={4} xs={12} className={`${styles.editRowMobile}`}>
                                                            <EditEmail />
                                                            <TextField required label="Email id" name="PatientEmailId" variant="outlined" value={this.state.email} disabled />
                                                        </Col>

                                                        <Col md={4} xs={12} className={`${styles.editRowMobile}`}>
                                                            <EditPassword />
                                                            <TextField type="password" required label="Password" name="PatientPassword" variant="outlined" value="111111" disabled />
                                                        </Col>

                                                        <Col md={4} xs={12} className={`${styles.editRowMobile}`}>
                                                            <EditPhoneNumber />
                                                            <TextField required label="Phone number" name="PatientNumber" variant="outlined" value={this.state.mobile} disabled />
                                                        </Col>
                                                    </Form.Row>

                                                </Card.Body>
                                            </Card>

                                            <Card as={Col} xs={12} className={`${styles.editProfileCard}`}>
                                                <Card.Body className={`${styles.editProfileCardBody}`}>

                                                    <Form.Row as={Col} xs={12} className={`px-0 ${styles.formHeadding}`}>
                                                        <p>General Informations</p>
                                                    </Form.Row>
                                                    <Snackbar anchorOrigin={{ vertical, horizontal }} open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
                                                        <Alert onClose={handleClose} severity="success">Profile has been updated successfully!</Alert>
                                                    </Snackbar>
                                                    
                                                    <Form.Row as={Col} md={6} xs={12}>
                                                        <TextValidator required label="First name" name="f_name" validators={['required']} errorMessages={['This field is required!']} onChange={this.handleChange} value={this.state.f_name} autoComplete="off" variant="outlined" />
                                                    </Form.Row>

                                                    <Form.Row as={Col} md={6} xs={12}>
                                                        <TextValidator label="Last name" name="l_name" onChange={this.handleChange} value={this.state.l_name} autoComplete="off" variant="outlined" />
                                                    </Form.Row>

                                                    <Form.Row as={Col} md={4} xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    name="sampleCollectionDate"
                                                                    margin="normal"
                                                                    id="date-picker-dialog"
                                                                    label="Select date of birth"
                                                                    format="dd/MM/yyyy"
                                                                    value={this.state.dob}
                                                                    emptyLabel=""
                                                                    disableFuture={true}
                                                                    clearable
                                                                    onChange={(date, name) => this.handleDateChange(date, 'dob')}
                                                                    KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                    }}
                                                                />
                                                        </MuiPickersUtilsProvider>
                                                    </Form.Row>

                                                    <Form.Row as={Col} md={4} xs={12}>
                                                        <TextValidator select label="Blood Group" name="blood_group" value={this.state.blood_group} onChange={this.handleChange} variant="outlined">
                                                            {bloodGroup.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextValidator>
                                                    </Form.Row>

                                                    <Form.Row as={Col} md={4} xs={12}>
                                                        <FormControl component="fieldset" className={`customRadio`}>
                                                            <FormLabel component="legend">Gender <sup>*</sup></FormLabel>
                                                            <RadioGroup aria-label="gender" name="gender" value={this.state.gender} onChange={(event) => this.handleChange(event)}>
                                                                <FormControlLabel value="1" control={<Radio />} label="Male" />
                                                                <FormControlLabel value="2" control={<Radio />} label="Female" />
                                                                <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Form.Row>

                                                    <Col as={Col} xs={12} className={`text-center`}>
                                                        <Button id="loginFormWidOtpBtn" className={`my-btn mt-3 px-5 loginFormWidOtpBtn`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                                            Save Changes
                                                        </Button>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    </ValidatorForm>) : (
                                <div className={`sweet-loading customLoader`}>
                                    <DotLoader
                                        color={'#ef3c67'}
                                        loading={this.state.loading}  
                                    />
                                </div>
                            ) }
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default EditProfile;