import React, { Component } from "react";
import styles from './Faq.module.scss';
import { Container, Image, Row, Col, Breadcrumb, Accordion, Card } from 'react-bootstrap';

class Faq extends Component{

    componentDidMount() {
        document.title = "FAQ"
    }

    render() {
        return(
            <div className="content">
                
                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>FAQ</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            FAQ
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={styles.staticPageContent}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Accordion defaultActiveKey="0" className={styles.accordionDiv}>
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="0">
                                            Q. Why should I use drray24.in, instead of my traditional way of booking an appointment with doctor?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> DRRAY24 connects you to verified doctors within your locality and book an appointment. You need not to go anywhere. You can select from the available date and time slots for consultation with doctor. DRRAY24 enables you to explore about the doctors, their specialization, professional experience etc. that helps you to choose the best doctor for consultation
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="1">
                                            Q. Why should I maintain my health record with drray24.in?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> DRRAY24 saved your health-related information electronically (either on cloud or on a device). You can share your electronically saved health records of all times with the doctors on the go. Also, your saved records are used by doctors to whom you are connected to, for an informed medication.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="2">
                                            Q. What can I do with drray24.in?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> You can do many things through drray24.in such as - view & print portions of your medical records, including doctor visits, discharge summaries, medications, immunizations, allergies etc. at any time and from anywhere. You can also request for prescription refills; scheduling of non-urgent appointments; or updating your contact information etc.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="3">
                                            Q. How do I know my appointment is confirmed through drray24.in?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> Each time when you requested for an appointment booking with the doctor, you will get an SMS and an email regarding confirmation of appointment with the doctor.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="4">
                                            Q. I am a physician. How can I list myself on drray24.in?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> You can get listed on drray24.in through the link: <a href="http://doctor.drray24.in" target="_blank">http://doctor.drray24.in.</a>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="5">
                                            Q. Do I need to show any proof of appointment booking while visiting the doctor’s clinic/ hospital?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> We recommend you to have your SMS and/or email handy with you while visiting the doctor’s clinic/ hospital. However, it is not mandatory as we send appointment confirmation message to the doctor as well.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card className={styles.card}>
                                        <Accordion.Toggle as={Card.Header} className={styles.header} eventKey="6">
                                            Q. Is there any charges for patient’s registration on drray24.in?
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body className={styles.body}>
                                                <b>Ans.</b> No, it is totally free for patients. We believe in providing healthcare services which can fit into everyone’s pocket. We earn revenue from the services provided to the doctors.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default Faq;