import React, {Component} from 'react';
import { Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MenuItem } from '@material-ui/core';
import AuthService from '../../../../../services/Auth-Service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

class HealthRecord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            previewUrl: null,
            healthRecord: null,
            healthType: null,
            healthRecordSubmitSuccess: false,
            healthRecordFormSubmit: false,
            vertical: 'top',
            horizontal: 'center'
        };
        this.healthRecordChange = this.healthRecordChange.bind(this);
        this.recordTypeChange = this.recordTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    healthRecordChange(files: FileList) {
        this.setState({healthRecord: files[0]});
        var reader = new FileReader();

        reader.onload = (event:any) => {
            this.setState({previewUrl: event.target.result});
        }

        reader.readAsDataURL(files[0]);
    }

    recordTypeChange(event) {
        this.setState({healthType: event.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({healthRecordFormSubmit:true});
        const formData = new FormData();
        formData.append('record_type', this.state.healthType);
        formData.append('health_record', this.state.healthRecord);
        const API = AuthService.post('profile/healthRecord', formData);
        API.then(json => {
            if(json.status == 200){
                this.setState({healthRecordSubmitSuccess: true});
            } else {
                alert("Something went wrong!");
            }
            this.setState({healthRecordFormSubmit: false, previewUrl:null, healthRecord: null, healthType: null});
            document.getElementById("healthRecordForm").reset();
        });
    }

    render() {
        var { vertical, horizontal, healthType, previewUrl, healthRecordSubmitSuccess, healthRecordFormSubmit } = this.state;
        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
    
        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({healthRecordSubmitSuccess: false});
        };
        return(
            <div>
                <Snackbar anchorOrigin={{ vertical, horizontal }} open={healthRecordSubmitSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">Your health record has been submitted successfully!</Alert>
                </Snackbar>
                <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit} id="healthRecordForm">

                    <Form.Row as={Col} xs={12}>
                        <TextValidator select required label="Select a record type" name="health_record_type" variant="outlined" onChange={this.recordTypeChange} value={healthType} validators={['required']} errorMessages={['This field is required!']}>
                            <MenuItem key='1' value='1'>Prescription</MenuItem>
                            <MenuItem key='2' value='2'>Report</MenuItem>
                            <MenuItem key='3' value='3'>Invoice</MenuItem>
                        </TextValidator>
                    </Form.Row>

                    <Form.Row as={Col} md={12} xs={12} className={`browseImage`}>
                        <span className={`browseImageSpan`}>Choose a file here!</span>
                        <TextValidator type="file" onChange={(event) => this.healthRecordChange(event.target.files)} />
                    </Form.Row>
                    <Form.Row as={Col} md={12} xs={12}>
                        <img src={previewUrl} className={`img-fluid mx-auto`} height="100" width="100" />
                    </Form.Row>
                    <Form.Row as={Col} xs={12} className={`justify-content-center`}>
                        <Button className={`my-btn px-5`} variant="contained" type="submit" disabled={healthRecordFormSubmit} formNoValidate>Submit</Button>
                    </Form.Row>

                </ValidatorForm>
            </div>
        );
    }
}

export default HealthRecord;