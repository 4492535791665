import React, {Component} from 'react';
import styles from './Profile.module.scss';
import { Row, Container, Figure, Image, Card, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import NavTabs from './Activity-Tabs/Activity-Tabs';
import AfterActivityComponent from './After-Activity-Component/After-Activity-Component';

class ProfileComponent extends Component {

    componentDidMount() {
        document.title = "Profile"
    }

    render() {
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Profile
                                    <span>
                                        Profile Details
                                    </span>
                                    <Link to="/graph-view" className={`my-btn ${styles.topSaveBtn} loginFormWidOtpBtn`} variant="contained">
                                        Graph View
                                    </Link>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`${styles.profileSectionDiv}`}>
                                        <Card className={`${styles.profileSectionCard} ${styles.helpCard}`}>
                                            <Card.Title className={`${styles.profileSectionCardTitle}`}>Your Body Details</Card.Title>

                                            <ListGroup className={`${styles.profileSectionCardList}`}>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/age.jpg" height="50" width="50" alt="Age" />
                                                    </Figure>
                                                    <h6>Age <span>24 yr</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/height.jpg" height="50" width="50" alt="Height" />
                                                    </Figure>
                                                    <h6>Height <span>0 cm</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/weight.jpg" height="50" width="50" alt="Weight" />
                                                    </Figure>
                                                    <h6>Weight <span>65 kg</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/bmi.jpg" height="50" width="50" alt="BMI" />
                                                    </Figure>
                                                    <h6>BMI <span>0 kg/m<sup>2</sup></span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/pulse.jpg" height="50" width="50" alt="Pulse" />
                                                    </Figure>
                                                    <h6>Pulse <span>55</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/respiratory_rate.jpg" height="50" width="50" alt="Respiratory Rate" />
                                                    </Figure>
                                                    <h6>Respiratory Rate <span>20</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/blood_pressure.jpg" height="50" width="50" alt="Blood Pressure" />
                                                    </Figure>
                                                    <h6>Blood Pressure <span>52/40</span></h6>
                                                </ListGroupItem>
                                                <ListGroupItem className={`${styles.profileSectionCardListItem}`}>
                                                    <Figure>
                                                        <Image className={`img-fluid mx-auto`} src="assets/images/user_profile/profile/temperature.jpg" height="50" width="50" alt="Temperature" />
                                                    </Figure>
                                                    <h6>Temprature <span>97 f</span></h6>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </Card>
                                    </Col>

                                    <Col xs={12} className={`${styles.profileSectionDiv}`}>
                                        <Card className={`${styles.profileSectionCard} ${styles.helpCard}`}>
                                            <Card.Title className={`${styles.profileSectionCardTitle}`}>Your Details</Card.Title>

                                            <Card.Body className={`${styles.profileSectionCardBody}`}>
                                                <Col md={6} xs={12}>
                                                    <p>First Name: <span>Shashank</span></p>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <p>Last Name: <span>Verma</span></p>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <p>DOB: <span>09-09-1993</span></p>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <p>Gender: <span>Male</span></p>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <p>Height: <span>165 cm</span></p>
                                                </Col>
                                                <Col md={12} xs={12} className={`text-center`}>
                                                    <Link className={`my-btn px-4 mt-3`} to="/edit-profile">
                                                        Edit Profile
                                                    </Link>
                                                </Col>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col>
                                        <Card className={`${styles.profileSectionCard} ${styles.helpCard}`}>
                                            <Card.Title className={`${styles.profileSectionCardTitle}`}>Your Activity</Card.Title>

                                            <NavTabs />
                                        </Card>
                                    </Col>
                                    
                                </Row>

                            </Col>

                            <Col lg={12} md={12} xs={12}>
                                <AfterActivityComponent />
                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default ProfileComponent;