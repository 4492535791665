import React, {Component} from 'react';
import styles from "./Edit-Email.module.scss";
import { Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AuthService from '../../../../services/Auth-Service';

class EditEmail extends Component{

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            formSubmit: false,
            email: '',
            password: '',
            updateSuccess: false,
            vertical: 'top',
            horizontal: 'center',
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose(){
        this.setState({show: false})
    }

    handleShow(){
        this.setState({show: true})
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('email', this.state.email);
        formData.append('password', this.state.password);
        const API = AuthService.post('edit_profile/email', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if(json.status == 300){
                alert("Password is not correct!");
            } else if(json.status == 200) {
                this.setState({updateSuccess: true});
                this.handleClose();
            } else {
                alert("Something went wrong!");
            }
        })
    }

    render() {
        const { vertical, horizontal, updateSuccess } = this.state;

        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
    
        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({updateSuccess: false});
        };
        return(
            <div>
                <Button className={`my-btn ${styles.editFiledText} ${styles.helpPopup}`} variant="contained" onClick={this.handleShow}>Edit</Button>
          
                <Modal show={this.state.show} onHide={this.handleClose} centered className="customModal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Email Id
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Snackbar anchorOrigin={{ vertical, horizontal }} open={this.state.updateSuccess} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">Email has been changed successfully!</Alert>
                        </Snackbar>
                        <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit}>
                            
                            <Form.Row as={Col} xs={12} className={`${styles.EditFormText}`}>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </Form.Row>

                            <Form.Row as={Col} xs={12}>
                                <TextValidator required label="Email Id" name="email" validators={['required', 'isEmail']} errorMessages={['This field is required!', 'Email is not valid!']} onChange={this.handleChange} value={this.state.email} autoComplete="off" variant="outlined" />
                            </Form.Row>

                            <Form.Row as={Col} xs={12}>
                                <TextValidator required label="Password" type="password" name="password" validators={['required']} errorMessages={['This field is required!']} onChange={this.handleChange} value={this.state.password} variant="outlined" />
                            </Form.Row>
                            
                            <Form.Row as={Col} xs={12}>
                                <Button className="my-btn mx-auto px-5" variant="contained" disabled={this.state.formSubmit} type="submit" formNoValidate>
                                    Change Email
                                </Button>

                                <Button className="my-btn my-btn-second mx-auto px-5" variant="contained" type="button" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </Form.Row>
                        </ValidatorForm>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default EditEmail;