import React, {Component} from 'react';
import styles from './Diagnostic.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CheckIcon from '@material-ui/icons/Check';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Image, Card, Container, Row, Col, ListGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Button } from '@material-ui/core';
import TestCard from './Cards/Test-card/Test-card';
import TestPackageCard from './Cards/Test-package-card/Test-package-card';
import Service from '../../services/Service';
import { DotLoader } from 'react-spinners';

const responsiveMainBanner = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
};

const responsiveItemList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3.5,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2.3,
        partialVisibilityGutter: 30
    }
};

const responsivePackagesList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3.3,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 676,
            min: 1
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 678
        },
        items: 3,
        partialVisibilityGutter: 30
    }
};

class Diagnostic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            diagnostic: [],
            th: []
        }
    }

    componentDidMount() {
        const API = Service.get('diagnostic');
        API.then(json => {
            this.setState({
                isLoading: true,
                diagnostic: json
            });
        });
        document.title = "Home"
    }

    render() {
        var {isLoading, diagnostic} = this.state;
        if(isLoading) {
        return(
            <div className="content">
                
                <Container fluid className={`pb-4`}>
                    <Container>
                        <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay
                        autoPlaySpeed={6000}
                        centerMode={false}
                        className={`customSliderDiv ${styles.customCarousel}`}
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsiveMainBanner}
                        dotListClass="custom-dot-list-style"
                        showDots
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                        >
                            <div>
                                <Link to="/" variant="contained">
                                    <Image className="w-100 d-none d-md-block" src="assets/images/diagnostic/diagnostic_banner_1.svg" alt="First slide" />
                                    <Image className="w-100 d-block d-md-none" src="assets/images/diagnostic/diagnostic_banner_1m.svg" alt="First slide" />
                                </Link>
                            </div>

                            <div>
                                <Link to="/" variant="contained">
                                    <Image className="w-100 d-none d-md-block" src="assets/images/diagnostic/diagnostic_banner_2.svg" alt="Second slide" />
                                    <Image className="w-100 d-block d-md-none" src="assets/images/diagnostic/diagnostic_banner_2m.svg" alt="Second slide" />
                                </Link>
                            </div>
                        </Carousel>
                    </Container>
                </Container>

                <Container fluid className={styles.aboutContent}>
                    <Container>
                        <Row>
                            <Col md={5} xs={12}>
                                <Image src="assets/images/home/about_us.png" alt="About Us" fluid />
                            </Col>

                            <Col md={7} xs={12} className={styles.aboutContent_text}>
                                <h1>
                                    Welcome To DrRay24 <span className="d-block">Better Life Healthcare</span>
                                </h1>
                                <p>
                                    We are here to break healthcare as a privilege and make treatments and diagnosis available for everyone. This venture started as our concern for the rising number of mortality rates, deaths, diseases, and poor treatment in healthcare sectors. Being one of India’s top diagnostic laboratories we expertise in best industrial practices. We have come up with defining solutions through the combined efforts of technology, artificial intelligence, and specialties of medicines to share the best benefits with our clients. What makes us unique is our talked about ethical practices, availability of wide testing facilities, seamless quality of work, and affordability of services.
                                </p>
                                <Link to="/about" className={`${styles.aboutBtn} my-btn`}>Read More</Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.customPosition} bg-white`}>
                    <Container>
                        <section>

                            <Col className="px-0 mb-md-5 mb-0">
                                <h3 className="customHeading">Book Tests & Checkups</h3>
                            </Col>

                            <Col className={`customSecondHeading ${styles.positionAbsolute} w-auto px-0`}>
                                <h5>
                                    Top Booked Diagnostic Tests
                                    <span>
                                        Book your session on priority and get exclusive treatment
                                    </span>
                                </h5>
                            </Col>

                            <Carousel
                            additionalTransfrom={0}
                            arrows={true}
                            autoPlay
                            autoPlaySpeed={10000}
                            centerMode={false}
                            className={styles.bookTestSlider}
                            containerClass=""
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={10}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsiveItemList}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                            >
                            {
                                diagnostic.result.test.map((item, i) => (
                                    <Col key={i} className={`${styles.bookedTestMain}`}>
                                        <TestCard test={item} />
                                    </Col>
                                ))
                            }
                            </Carousel>

                            <Col className={`px-0 ${styles.allPackages}`}>
                                <Link to="/diagnostic/tests" className={`my-btn ${styles.allPackagesBtn}`}>
                                    View All <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} />
                                </Link>
                            </Col>

                            <Col className={`customSecondHeading ${styles.positionAbsolute} w-auto px-0`}>
                                <h5>
                                    Medical Services
                                    <span>
                                        Don’t delay! Prevent yourself from all possible risks, before it’s too late.
                                    </span>
                                </h5>
                            </Col>

                            <Carousel
                            additionalTransfrom={0}
                            arrows={true}
                            autoPlay
                            autoPlaySpeed={10000}
                            centerMode={false}
                            className={`${styles.bookTestSlider} mb-md-5 mb-0`}
                            containerClass=""
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={10}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsiveItemList}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                            >
                            {
                                diagnostic.result.health_category.map((item, i) => (
                                    <Col className={styles.medicalServices} key={i}>
                                        <Card className={styles.medicalCard}>
                                            <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`}>
                                                <Card.Header className={styles.medicalCardHeader}>
                                                    <Image className={`mx-auto ${styles.servicesImg}`} src="assets/images/home/services/immunisations.jpg" alt="immunisations" />
                                                    <Image className={`mx-auto ${styles.servicesImg_c}`} src="assets/images/home/services/immunisations_c.jpg" alt="immunisations" />
                                                </Card.Header>
                                            </Link>
                                            <Card.Body className={styles.medicalCardBody}>
                                                <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`} className={styles.medicalCardLink}>{item.name}</Link>
                                                <Card.Text className={`${styles.medicalCardText} d-none`}>
                                                    Lorem ipsum dolor sit amet adipiscing.
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className={styles.medicalCardFooter}>
                                                <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`} className={`my-btn ${styles.medicalBtn}`}>See</Link>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))
                            }   
                            </Carousel>
                        </section>

                        <section className={`mt-0 mt-md-5 pt-5`}>

                            <Col className="px-0 mb-5">
                                <h3 className="customHeading">Health Checkup Packages</h3>
                            </Col>

                            <Carousel
                            additionalTransfrom={0}
                            arrows={true}
                            autoPlay
                            autoPlaySpeed={10000}
                            centerMode={false}
                            className={`${styles.bookTestSlider} ${styles.healthPackageSlider}`}
                            containerClass=""
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={10}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsivePackagesList}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                            >
                            {
                                diagnostic.result.health_package.map((item, i) => (
                                    <Col className={styles.healthPackage} key={i}>
                                        <TestPackageCard test={item} />
                                    </Col>
                                ))
                            }
                            </Carousel>

                            <Col className={`px-0 ${styles.allPackages}`}>
                                <Link to="/diagnostic/test-packages" className={`my-btn ${styles.allPackagesBtn}`}>
                                    View All <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} />
                                </Link>
                            </Col>

                        </section>

                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.appointmentBanner}`}>
                    <Container>
                        <Row>
                            <Col xs className={styles.appointmentBanner_text}>
                                <p>Need a doctor for check-up?</p>
                                <h3>
                                    Just make an appointment & you’re done
                                </h3>
                                <Button className="my-btn" variant="contained">Make an appointment</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.diagnosticChoose}`}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h3 className="customHeading">Why Choose DrRay24?</h3>
                                <p className="sectionText">
                                    We at DRRAY24, strongly believes in delivering timely, unmatched and effective healthcare solutions with greater comfort to our customers. Our enthusiasm, loyalty and devotion for providing good quality service rewarded us in the form of long term relationships with our clients.
                                </p>

                                <ListGroup as="ul" className={styles.whyChooseOption}>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    24 /7 Emergency Services
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get round the clock access to our healthcare services. Get doctor’s appointment, consultation, or medicines - anytime, anywhere.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Modern Technologies
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get smarter healthcare services with modern technologies to stay healthy and fit in today’s fast moving lifestyle.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Passionate Doctors & Nurses
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get access to highly qualified and experienced doctors along with passionate and caring nursing staff everywhere and every time.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <CheckIcon className={styles.fontIcon} />
                                        <Card className={styles.card}>
                                            <Card.Body className={styles.body}>
                                                <Card.Title className={styles.title}>
                                                    Protection Against Diseases
                                                </Card.Title>
                                                <Card.Text className={styles.text}>
                                                    Get instant guidance from our experts on protection against diseases with no waiting long queues even at the time of crises.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default Diagnostic;