import React, {Component} from 'react';
import styles from './Register.module.scss';
import { Row, Container, Col, Form } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Service from '../../services/Service';

class RegisterComponent extends Component {

    componentDidMount() {
        document.title = "Register"
        ValidatorForm.addValidationRule('isOTPMatch', (value) => {
            if(value.length === 6) {
                const formData = new FormData();
                formData.append('mobile', this.state.mobile);
                formData.append('otp', value);
                const API = Service.post('register/otp', formData);
                return API.then(json => {
                    if (json.status === 200) {
                        if(json.result === 1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if(json.status === 400) {
                        return false;
                    } else {
                        return false;
                    }
                });
            }
            return true;
        });

        ValidatorForm.addValidationRule('mobileUnique', (value) => {
            if(value.length === 10) {
                const formData = new FormData();
                formData.append('mobile', value);
                const API = Service.post('register/mobile', formData);
                return API.then(res => {
                    if (res) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            return true;
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            f_name: '',
            l_name: '',
            mobile: '',
            password: '',
            gender: '',
            dob: null,
            formSubmit: false,
            otpForm: false,
            vertical: 'top',
            horizontal: 'center',
            notFound: false,
            otp: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
    }

    handleDateChange(d, name) {
        var dob = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
        this.setState({[name]: dob});
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('f_name', this.state.f_name);
        formData.append('l_name', this.state.l_name);
        formData.append('mobile', this.state.mobile);
        formData.append('password', this.state.password);
        formData.append('gender', this.state.gender);
        formData.append('dob', this.state.dob);
        const API = Service.post('register', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if (json.status == 200) {
                this.setState({otpForm: true});
                //this.props.history.push('/thankyou-register');
            } else if(json.status == 400) {
                if(json.validation_errors.mobile) {
                    alert(json.validation_errors.mobile);
                } else {
                    alert("Something went wrong!");
                }
            } else {
                alert("Something went wrong!");
            }
        });
    }

    handleOTPSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('mobile', this.state.mobile);
        formData.append('otp', this.state.otp);
        const API = Service.post('register/otp', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if (json.status === 200) {
                if(json.result === 1) {
                    this.setState({otpForm: false});
                    this.props.history.push('/thankyou-register');
                } else {
                    this.setState({otpMatch: false});
                }
            } else if(json.status === 400) {
                this.setState({otpMatch: false});
                if(json.validation_errors.mobile) {
                    alert(json.validation_errors.mobile);
                } else {
                    alert("Something went wrong!");
                }
            } else {
                this.setState({otpMatch: false});
                alert("Something went wrong!");
            }
        });
    }

    back() {
        this.setState({otpForm: !this.state.otpForm});
    }

    render() {

        return(
            <div className={`content`}>
                
                <Container fluid className={`${styles.loginRegisterDiv}`}>
                    <Container>
                        <Row className="justify-content-center">

                            <Col lg={5} md={6} xs={12} className={`${styles.loginRegisterBox}`}>
                                <div className={`${styles.accountSection}`}>
                                    <div className={`${styles.accountIcon}`}>
                                        <PeopleIcon />
                                    </div>

                                    <p>Register to drray24 account</p>
                                </div>
                                {
                                    this.state.otpForm ? (
                                        <ValidatorForm ref="form" className="customForm" onSubmit={this.handleOTPSubmit}>
                                            <div id="loginFormWidOtp" className={`loginFormWidOtp row`}>
                                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                                    <p>
                                                        We have sent OTP on entered mobile number
                                                    </p>
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12}>
                                                    <TextValidator label="Enter Otp" name="otp" variant="outlined" onChange={this.handleChange} value={this.state.otp} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{6,6}$', 'isOTPMatch']} errorMessages={['This field is required', 'Enter only number.', 'OTP not valid', 'Wrong OTP!']} autoComplete="off" inputProps={{ maxLength: 6 }} />
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection} ${styles.OtpSection}`}>
                                                    <Button id="loginFormWidOtpBtn" className={`my-btn mt-3 px-5 btn-block loginFormWidOtpBtn`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                                        Verify
                                                    </Button>

                                                    <span className={`${styles.OtpBackBtn} mt-3`} onClick={ () => this.back() }><ChevronLeftIcon /></span>
                                                </Form.Row>
                                            </div>
                                        </ValidatorForm> ) : (
                                        <ValidatorForm ref="form" className="customForm" onSubmit={this.handleSubmit}>
                                            <div id="loginForm" className={`loginForm row`}>
                                                <Form.Row as={Col} md={6} xs={12}>
                                                    <TextValidator required label="Enter first name" name="f_name" variant="outlined" onChange={this.handleChange} value={this.state.f_name} validators={['required']} errorMessages={['This field is required']} autoComplete="off" />
                                                </Form.Row>
        
                                                <Form.Row as={Col} md={6} xs={12}>
                                                    <TextValidator label="Enter last name" name="l_name" variant="outlined" onChange={this.handleChange} value={this.state.l_name}/>
                                                </Form.Row>
        
                                                <Form.Row as={Col} xs={12}>
                                                    <TextValidator required label="Enter your mobile number" type="text" name="mobile" variant="outlined" onChange={(event) => this.handleChange(event)} value={this.state.mobile} inputProps={{ maxLength: 10 }} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{10,10}$', 'mobileUnique']} errorMessages={['This field is required', 'Enter only number.', 'Mobile number not valid', 'The Mobile Number is already register.']} autoComplete="off" />
                                                </Form.Row>
        
                                                <Form.Row as={Col} xs={12}>
                                                    <TextValidator required label="Enter password" type="password" name="password" variant="outlined" value={this.state.password} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} />
                                                </Form.Row>
    
                                                <Form.Row as={Col} md={6} xs={12}>
                                                    <FormControl component="fieldset" className={`customRadio`}>
                                                        <FormLabel component="legend">Gender <sup>*</sup></FormLabel>
                                                        <RadioGroup className={`${styles.genderBtn}`} aria-label="gender" name="gender" required onChange={(event) => this.handleChange(event)}>
                                                            <FormControlLabel value="1" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="2" control={<Radio />} label="Female" />
                                                            <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Form.Row>
        
                                                <Form.Row as={Col} md={6} xs={12}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            required
                                                            name="dob"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label="Select date of birth"
                                                            format="dd/MM/yyyy"
                                                            value={this.state.dob}
                                                            required={true}
                                                            onChange={(date, name) => this.handleDateChange(date, 'dob')}
                                                            KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Form.Row>
                                                
                                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection}`}>
                                                    <span className={`mt-3 ${styles.termsCText}`}>
                                                        By signing up, I agree to <Link to="/">terms</Link>
                                                    </span>
                                                    <Button id="loginFormBtn" className={`my-btn mt-3 px-5 loginFormBtn`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>Register</Button>
                                                </Form.Row>
                                            </div>
                                        </ValidatorForm>
                                        ) 
                                }

                                <Form.Row as={Col} xs={12} className={`${styles.haveAccount}`}>
                                    <p>
                                        You have an account? <Link to="/login">Login Now</Link>
                                    </p>
                                </Form.Row>
                            </Col>

                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default RegisterComponent;