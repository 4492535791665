import React, {Component} from 'react';
import { Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AuthService from '../../../../../services/Auth-Service';
import { DotLoader } from 'react-spinners';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

class BloodTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bloodTestId: null,
            isLoadedBlood: false,
            bloodTestInput: false,
            isLoadedBloodTestInput: false,
            bloodTestId: null,
            bloodTest: [],
            submitBloodTest: [],
            previewUrl: null,
            inputCount: null,
            bloodTestFormSubmit: false,
            bloodTestSubmitSuccess: false,
            dob: new Date(),
            vertical: 'top',
            horizontal: 'center'
        };
        this.bloodTestInput = this.bloodTestInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const API = AuthService.get('profile/blood_test');
        API.then(json => {
            this.setState({
                bloodTest: json.result,
                isLoadedBlood: true
            });
        });
    }

    bloodTestInput(event) {
        this.setState({isLoadedBloodTestInput: false});
        let bloodTestId = event.target.value;
        if(bloodTestId) {
            const formData = new FormData();
            formData.append('blood_test_id', bloodTestId);
            const API = AuthService.post('profile/bloodTestInput', formData);
            API.then(json => {
                this.setState({bloodTestInput: json, isLoadedBloodTestInput: true});
                let allName = {};
                this.state.bloodTestInput.result.map(item => {
                    let name = "field_"+item.id;
                    allName[name] = [''];
                });
                this.setState({submitBloodTest: allName, inputCount: this.state.bloodTestInput.result.length, bloodTestId: bloodTestId});
            });
        } else {
            this.setState({bloodTestInput: false})
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({bloodTestFormSubmit: true});
        const formData = new FormData();
        formData.append('blood_test_id', this.state.bloodTestId);
        formData.append('blood_test_data', JSON.stringify(this.state.submitBloodTest));
        const API = AuthService.post('profile/bloodTestData', formData);
        API.then(json => {
            if(json.status == 200){
                this.setState({bloodTestSubmitSuccess: true});
            } else {
                alert("Something went wrong!");
            }
            this.setState({bloodTestFormSubmit: false, bloodTestInput: false, bloodTestId: null});
            document.getElementById("bloodTestForm").reset();
        });
    }

    formInput(item) {
        let input;
        let required = item.is_required =='1' ? 'required': null;
        let name = "field_"+item.id;
        if(this.state.submitBloodTest[name]){
            var inputVal = this.state.submitBloodTest[name][0];
        }
        if(item.input_type == "field") {
            if(required)
                input = (<TextValidator required label={item.label} name={name} validators={['required']} errorMessages={['This field is required!']} onChange={(event) => this.handleChange(0, event)} value={inputVal} autoComplete="off" variant="outlined" />);
            else
                input = (<TextValidator label={item.label} name={name} variant="outlined" onChange={(event) => this.handleChange(0, event)} value={inputVal} />);
        } else if(item.input_type == "number") {
            var min = 'minNumber:'+item.min;
            var max = 'maxNumber:'+item.max;
            var val = ['required'];
            val.push(min);
            val.push(max);
            val.push('matchRegexp:^[0-9]*$');
            var minError = "The field must contain a number greater than or equal to "+item.min;
            var maxError = "The field must contain a mumber less than or equal to "+item.max;
            var errMsg = ['This field is required!', minError, maxError, 'Please enter number only!'];
            input = (<TextValidator required label={item.label} name={name} validators={val} errorMessages={errMsg} onChange={(event) => this.handleChange(0, event)} value={inputVal} autoComplete="off" variant="outlined" />)
        } else if(item.input_type == "date") {
            input = (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            name={name}
                            margin="normal"
                            id="date-picker-dialog"
                            label={item.label}
                            format="dd/MM/yyyy"
                            value={this.state.dob}
                            onChange={(event) => this.handleChange(0, event)}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                </MuiPickersUtilsProvider>);
        } else if(item.input_type == "drop_down") {
            input = (
                    <TextValidator select label={item.label} name={name} required={required} onChange={(event) => this.handleChange(0, event)} validators={['required']} errorMessages={['This field is required!']} value={inputVal} variant="outlined">
                        {item.dropdown.map(op => (
                            <MenuItem key={op.dropdownId} value={op.dropdownId}>
                                {op.title}
                            </MenuItem>
                        ))}
                    </TextValidator>
                    );
        }
        return input;
    }

    handleChange(row, event) {
        let name = event.target.name;
        var someProperty = {...this.state.submitBloodTest};
        someProperty[name][row] = event.target.value;
        this.setState({someProperty});
    }

    render() {
        var { vertical, horizontal, bloodTest, bloodTestId, bloodTestInput, isLoadedBloodTestInput, bloodTestFormSubmit, bloodTestSubmitSuccess } = this.state;
        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
    
        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({bloodTestSubmitSuccess: false});
        };
        return(
            <div>
                <Snackbar anchorOrigin={{ vertical, horizontal }} open={bloodTestSubmitSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">Your activity data has been submitted successfully!</Alert>
                </Snackbar>
                <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit} id="bloodTestForm">

                    <Form.Row as={Col} xs={12}>
                        <TextField select required label="Select a blood test" name="activity" variant="outlined" onChange={this.bloodTestInput} value={bloodTestId}>
                            {bloodTest.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Form.Row>

                    { bloodTestInput ? (<>
                        {
                            isLoadedBloodTestInput ? (<>
                                {
                                    bloodTestInput.result.map(item => (
                                        <Form.Row as={Col} md={4} xs={12}>
                                            {this.formInput(item)}
                                        </Form.Row>
                                    ))
                                }
                            <Form.Row as={Col} xs={12} className={`justify-content-center`}>
                                <Button className={`my-btn px-5`} variant="contained" type="submit" disabled={bloodTestFormSubmit} formNoValidate>Submit</Button>
                            </Form.Row>
                            </>) : (<div className={`sweet-loading customLoader`}>
                                        <DotLoader
                                            color={'#ef3c67'}
                                            loading={this.state.loading}  
                                        />
                                    </div>)
                        }
                    </> ) : null
                    }

                </ValidatorForm>
            </div>
        );
    }
}

export default BloodTest;