import React, { Component } from "react";
import styles from "./Shopping-Cart.module.scss";
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {Link} from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Badge, OverlayTrigger, Popover, ListGroup, Card, Figure, Image } from 'react-bootstrap';
import { connect } from 'react-redux';

class ShoppingCart extends Component{

    constructor(props){
        super(props);
        this.state = {
            addClass: false
        }
    }
    toggle() {
        this.setState ({
            addClass: !this.state.addClass
        })
    }

    render(){
        const CartPopover = (
            <Popover className={`${styles.shoppingCartPopup} ${styles.helpPopup} customShoppingClass`}>
                <Popover.Title as="h3">
                    {this.props.items.length} Tests in your cart {this.props.total > 0 ? (<span className={`${styles.subTotalAmt}`}>Sub Total <b>₹{this.props.total.toFixed(2)}</b></span>) : null}
                </Popover.Title>
        
                <Popover.Content className={`${styles.shoppingCartBody}`}>
                    {
                        this.props.items.map((item, i) => (
                            <ListGroup className={`${styles.shoppingCartListDiv}`} key={i}>
                                <ListGroup.Item className={`${styles.shoppingCartListItem} ${styles.CartItemDiv}`}>
                                    <Card className={`${styles.CartItemCard}`}>
                                        <Button className={`${styles.crossBtn}`} onClick={() => this.props.removeItem(item)}>
                                            <ClearIcon className={`${styles.crossBtnIcon}`} />
                                        </Button>
                                        <Figure>
                                            <Image width="30" className="mx-auto" src={window.location.origin + "/assets/images/home/doctor_card.jpg"} alt="Book Appointment" />
                                        </Figure>
                                        <Card.Body className={`${styles.CartItemBody}`}>
                                            <Card.Title className={`${styles.CartItemTitle}`}>
                                                {item.test_name}
                                            </Card.Title>
                                            <Card.Text className={`${styles.CartItemText} d-none`}>
                                                <span>Preparation-</span>
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className={`${styles.CartItemFooter}`}>
                                            <span>
                                                {item.discount_percentage > 0 ? (
                                                    <del>₹{item.mrp}</del>
                                                ) : null } ₹{item.final_mrp}
                                            </span>
                                        </Card.Footer>
                                    </Card>
                                </ListGroup.Item>
                            </ListGroup>
                        ))
                    }
                </Popover.Content>
        
                <Link to="/checkout" state={{ prevPath: 'checkout' }} variant="contained" className={`btn-block w-100`}>
                    <button disabled={this.props.items.length > 0 ? false : true} className={`my-btn btn-block`}>Proceed to Checkout</button>
                </Link>
            </Popover>
        );
        return(
            <OverlayTrigger trigger="click" placement="bottom" overlay={CartPopover}>
                <Button onClick={this.toggle.bind(this)} className={this.state.addClass ? `${styles.cart_filed} ${styles.helpDiv} ${styles.showPopupCancelBtn}` : `${styles.cart_filed} ${styles.helpDiv}`}>
                    <ShoppingCartIcon className={`${styles.cart_filedIcon}`} /><Badge className={`${styles.CartBadge}`} variant="primary">{this.props.items.length > 0 ? this.props.items.length : null}</Badge>
                    <ClearIcon className={`${styles.cancelPopupIcon}`} />
                </Button>
            </OverlayTrigger>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        items: state.diagnosticCart.items,
        total: state.diagnosticCart.total
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (item) => {dispatch({type: 'REMOVE_ITEM', item: item})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);