import React, { Component } from "react";
import styles from './All-Specialization.module.scss';
import { Image, Card, Container, Breadcrumb, Row, Col, Figure} from 'react-bootstrap';
import {Link} from 'react-router-dom';

class AllSpecialization extends Component{
    render() {
        return(
            <div className="content bgGray pb-0">
                <Container fluid className={`mt-n3`}>
                    <Container>
                        <Row>
                            <Col xs={12} className={`breadcrumbDiv`}>
                                <Breadcrumb>
                                    <Link to="/">Home</Link>
                                    <Link to="/diagnostic">Doctor</Link>
                                    <Breadcrumb.Item active>All Health Departments</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>

                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`${styles.allDepartments}`}>
                    <Container>
                        <Row className={`${styles.medicalDepartmentRow}`}>
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/cardiology.svg" alt="Cardiology" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Cardiology</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We treat all cardiac ailments with our best in service professionals and intensive quality care. We offer cardiothoracic surgery and vascular surgery packages through our state of the art facility within an outstanding expenditure.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/neurology.svg" alt="Neurology" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Neurology</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We have a team full of experts and qualified neurologist and neurosurgeons who have years of medical knowledge and worked on several complex disorders.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/orthopedic.svg" alt="Orthopedic" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Orthopaedics</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            We are offering the forefront service for unmatched orthopedic treatments with our latest equipments and surgical advancements aiming to maintain the highest standards of medical and homely care.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/pulmonary.svg" alt="Pulmonary" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Pulmonary</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            Any respiratory disorder can’t go untreated with us. We offer exemplary services to treat tuberculosis and chest disease maintaining highest order of medical hygiene.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={12}>
                                <Card className={`${styles.medicalDepartmentCard}`}>
                                    <Figure>
                                        <span>
                                            <Image width="30" className="mx-auto" src="assets/images/health_department/dental.svg" alt="Dental" />
                                        </span>
                                    </Figure>
                                    <Card.Body className={`${styles.medicalDepartmentBody}`}>
                                        <Card.Title className={`${styles.medicalDepartmentTitle}`}>
                                            <Link to="/">Dental Clinic</Link>
                                        </Card.Title>
                                        <Card.Text className={`${styles.medicalDepartmentText}`}>
                                            Our Multi spatiality dental care provides you the strength and confidence to smile more each day. From dental surgeries, to cleaning and filling, we are happy to serve you better and whiter teeth each day.
                                        </Card.Text>
                                        <Link to="/" className={`${styles.medicalDepartmentBtn} my-btn`}>View More</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default AllSpecialization;