import React, { Component } from "react";
import styles from "./Doctor-Clinic-Item.module.scss";
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import { Image, Row, Col, Figure } from 'react-bootstrap';
import ClinicPhotoGallery from "../Clinic-Photo-Gallery/Clinic-Photo-Gallery";
import StarRatingComponent from 'react-star-rating-component';
import AppoitnmentDateTime from "../../../../Appoitnment-Date-Time/Appoitnment-Date-Time";

class DoctorClinicItem extends Component {
    constructor(props) {
        super(props);
        this.state = {addClass: false};
        this.state = {
            rating: 2.0,
        };
    }
    toggle() {
        this.setState ({
            addClass: !this.state.addClass
        });
    }
     
    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
    }

    render() {
        function slotTime(slot) {
            var slotTime = slot.split(':');
            var hours = slotTime[0];
            var minutes = slotTime[1];
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0'+hours : hours;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
        const { rating } = this.state;

        return(
            <Row>      
                <Col sm={4} md={3} xs={12} className={`px-md-2 px-0`}>
                    <Figure>
                        <Image className="mx-auto" thumbnail src={"https://stage.drray24.in/assets/images/co/doctor/clinic/" + this.props.clinic.images[0].image_name}  fluid alt="Profile Photo" />
                    </Figure>

                    <div>
                        <ClinicPhotoGallery images={this.props.clinic.images}/>
                    </div>
                </Col>

                <Col sm={8} md={9} xs={12} className={`${styles.detailsContent}`}>
                    <h3 className={`${styles.detailsContentName}`}>
                        {this.props.clinic.name}
                        <span className={`${styles.ratingReadOnly} customRating`}>
                            <b className={`${styles.ratingReadOnlyText}`}>2.0</b>
                            <StarRatingComponent 
                                name="rate1"
                                editing={false}
                                starCount={5}
                                value={rating}
                                onStarClick={this.onStarClick.bind(this)}
                            />
                        </span>
                    </h3>
                    <ul className={`list-unstyled ${styles.detailsContentList}`}>
                        <li className={`d-flex flex-wrap`}>
                            <span className={`${styles.fb20}`}>Location:</span>
                            <span className={`${styles.fb80}`}>
                            {this.props.clinic.address}, {this.props.clinic.locality}, {this.props.clinic.city_name}, {this.props.clinic.state_name}, {this.props.clinic.pincode}
                            </span>
                        </li>
                        <li className={`d-flex flex-wrap`}>
                            <span className={`${styles.fb20}`}>Fees:</span>
                            <span className={`${styles.fb80}`}>
                                ₹{this.props.clinic.consultation_fees}
                            </span>
                        </li>
                        <li className={`d-flex flex-wrap`}>
                            <span className={`${styles.fb20}`}>Payment:</span>
                            <span className={`${styles.fb80}`}>
                                Online and Cash Available
                            </span>
                        </li>
                        <li className={`d-flex flex-wrap`}>
                            <span className={`${styles.fb20}`}>Waiting:</span>
                            <span className={`${styles.fb80}`}>
                                Max. {this.props.clinic.consultation_duration} mins wait + Verified details <Button>Prime <AlarmOnIcon /></Button>
                            </span>
                        </li>
                        <li className={`d-flex flex-wrap`}>
                            <span className={`${styles.fb20}`}>Timing:</span>
                            <ul className={`list-unstyled ${styles.detailsContentList} ${styles.detailsContentTimeList}`}>
                                {
                                    this.props.clinic.visit_timing.map(item => (
                                        <li className={`d-flex flex-wrap`}>
                                            <span className={`${styles.fb20}`}>{item.week}</span>
                                    <span className={`${styles.fb80}`}>{slotTime(item.start_time_1)} - {slotTime(item.end_time_1)}{item.start_time_2 ? ', '+ slotTime(item.start_time_2) +' - '+ slotTime(item.end_time_2) : null }{}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                    </ul>
                    
                    <p className={`text-md-right text-center mt-3 ${styles.detailsContentBtn}`}>
                        <Button onClick={this.toggle.bind(this)} className={this.state.addClass ? `my-btn ${styles.bookAppointmentActive}` : `my-btn`} variant="contained">
                            Book an Appointment <ExpandMoreIcon />
                        </Button>
                    </p>
                </Col>

                <Col xs={12} className={this.state.addClass ? `${styles.dateTimeSlotHide} ${styles.dateTimeSlotShow}` : `${styles.dateTimeSlotHide}`}>
                    <AppoitnmentDateTime slots={this.props.clinic.slots} clinicId={this.props.clinic.id} />
                </Col>
            </Row>
        );
    }
}

export default DoctorClinicItem;