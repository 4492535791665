import React, { Component } from "react";
import styles from "./Single-Doctor-List.module.scss";
import PaymentIcon from '@material-ui/icons/Payment';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Image, Col, Figure, Card, ListGroup } from 'react-bootstrap';
import AppoitnmentDateTime from "../../Appoitnment-Date-Time/Appoitnment-Date-Time";

class SingleDoctorList extends Component {
    constructor(props) {
        super(props);
        this.state = {addClass: false}
    }

    toggle() {
        this.setState ({
            addClass: !this.state.addClass
        });
    }

    render() {
        function doctorName(name) {
            if(name) {
                var name = name.replace(" ", "-");
            }
            return name;
        }
        return(
            <div className={`${styles.profileDetails}`}>
                <Card className={`${styles.profileDetailDivCard}`}>
                    <Card.Header className={`${styles.profileDetailHeader}`}>

                        <Figure className={`${styles.profileDetailProfileImg}`}>
                            <Image className="mx-auto" src={window.location.origin + "/assets/images/home/doctors/image_1.jpg"} fluid alt="Profile Photo" />
                        </Figure>

                        <div className={`${styles.profileDetailContent}`}>
                            <Col md={7} xs={12} className={`${styles.profileDetailContentLeft}`}>
                                <h1>
                                    Dr. {this.props.doctor.doctor_name}, <span>{this.props.doctor.specialization}</span>
                                </h1>
                                <h5>
                                    {this.props.doctor.clinic_name}, <span>{this.props.doctor.address}, {this.props.doctor.locality}, {this.props.doctor.city_name}, {this.props.doctor.state_name}, {this.props.doctor.pincode}</span>
                                </h5>
                                <span className={`${styles.likePercentage}`}>
                                    <ThumbUpIcon /> 92%
                                </span>
                            </Col>

                            <Col md={5} xs={12} className={`${styles.profileDetailContentRight}`}>
                                <h3 className={`${styles.profileBriefText}`}>
                                    <VerifiedUserIcon className={`${styles.profileBriefIcon} text-success`} /> Medical Registration <b>Verified</b>
                                </h3>
                                <h3 className={`${styles.profileBriefText}`}>
                                    <PaymentIcon className={`${styles.profileBriefIcon}`} /> Consultation Fee: <b>₹{this.props.doctor.consultation_fees}</b>
                                </h3>
                            </Col>
                        </div>
                    </Card.Header>

                    <Card.Body className={`${styles.profileDetailBody}`}>
                        <ListGroup horizontal className={`${styles.profileDetailListG}`}>
                            <ListGroup.Item className={`${styles.bookProfileBtn}`}>
                                <Link to={`/search/doctor/${doctorName(this.props.doctor.doctor_name)}/${this.props.doctor.doctor_id}`}>
                                    <Button className={`my-btn ${styles.allprofilesBtn}`} variant="contained">View Profile <DoubleArrowIcon className={`${styles.allprofilesBtnArrow}`} /> </Button>
                                </Link>
                            </ListGroup.Item>

                            <ListGroup.Item className={`${styles.bookProfileBtn}`}>
                                <Button onClick={this.toggle.bind(this)} className={this.state.addClass ? `my-btn ${styles.bookAppointmentActive}` : `my-btn`} variant="contained">
                                    Book an Appointment <ExpandMoreIcon />
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
                
                <div className={this.state.addClass ? `${styles.dateTimeSlotHide} ${styles.dateTimeSlotShow}` : `${styles.dateTimeSlotHide}`}>
                    <AppoitnmentDateTime slots={this.props.doctor.slots} clinicId={this.props.doctor.clinic_id} />
                </div>
            </div>
        );
    }
}

export default SingleDoctorList;