import React, { Component } from "react";
import styles from './After-Login.module.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import { Popover, OverlayTrigger, ListGroup, Image } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class AfterLogin extends Component{

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout () {
        this.props.logout();
        this.props.history.push('/');
    }

    render() {

        const popover = (
            <Popover id="popover-basic" className={`${styles.userListPopup} ${styles.helpPopup}`}>
              <Popover.Title as="h3">
                  <span>
                    <Image src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} roundedCircle height="60" width="60" alt="User" />
                  </span>
                  <span>
                      <p>shashank@draay24.com</p>
                      <p>+91-{this.props.userData.mobile}</p>
                  </span>
              </Popover.Title>
              <Popover.Content className={`${styles.userListBody}`}>
                <ListGroup horizontal className={`${styles.userListDiv}`}>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/my-account">My Account</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/my-appointment">My Appointments</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/my-doctor">My Doctor</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/prescription">Prescription</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/my-test">My Tests</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/my-order">My Medicine Orders</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <Link to="/order-medicine">Order Medicine</Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={`${styles.userListItem}`}>
                        <button onClick={this.handleLogout}>Logout</button>
                    </ListGroup.Item>
                </ListGroup>
              </Popover.Content>
            </Popover>
        );

        return(
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <Button className={`${styles.afterLoginBtn} ${styles.helpLocationBtn} myLocationBtn`}>
                    <Image src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} roundedCircle height="35" width="35" alt="User" /> <span className={`${styles.afterLoginText}`}>{this.props.userData.f_name}</span> <ExpandMoreIcon />
                </Button>
            </OverlayTrigger>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {dispatch({type: 'LOGOUT'})}
    }
}

export default connect(null, mapDispatchToProps)(withRouter(AfterLogin));