import React, {Component} from 'react';
import styles from './Testimonial.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image, Card, Container, Row, Col } from 'react-bootstrap';

const responsiveTestimonials = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 1,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 1,
        partialVisibilityGutter: 30
    }
};

class TestimonialComponent extends Component {
    render() {
        return(
            <Container fluid className={`sectionSpace bg-white`}>
                <Container>
                    
                    <div className={styles.testimonialDiv}>
                        <Row>
                            <h3 className="customHeading">What Our Clients Are Saying</h3>
                        </Row>

                        <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay
                        autoPlaySpeed={5000}
                        centerMode={false}
                        className={`customSliderDiv ${styles.meetSpecialistSlider}`}
                        containerClass="container-with-dots"
                        dotListClass="custom-dot-list-style"
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsiveTestimonials}
                        showDots={true}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                        >
                            <Col className={styles.medicalServices}>
                                <Card className={styles.medicalCard}>
                                    <Card.Header className={styles.medicalCardHeader}>
                                        <Image className="mx-auto" roundedCircle src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} alt="Client Says" />
                                    </Card.Header>
                                    <Card.Body className={styles.medicalCardBody}>
                                        <Card.Text className={styles.medicalCardText}>
                                            Very helping and caring staff with polite gestures and knowledge of all things. I am 73 years old widow, and was having a problem with my routine checkups due to joint pain; DrRay24 has been of great support to make my life better and easier even at this age.
                                        </Card.Text>
                                        <Card.Link href="#" className={styles.medicalCardLink}>Avinash Kumar</Card.Link>
                                        <Card.Text className={styles.medicalCardText}>
                                            Delhi
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col className={styles.medicalServices}>
                                <Card className={styles.medicalCard}>
                                    <Card.Header className={styles.medicalCardHeader}>
                                        <Image className="mx-auto" roundedCircle src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} alt="Client Says" />
                                    </Card.Header>
                                    <Card.Body className={styles.medicalCardBody}>
                                        <Card.Text className={styles.medicalCardText}>
                                            Got stressed due to my newly found allergy problem it caused me a lot of trouble since I am a motor mechanic and have to work outside in uneven temperature. After allergy testing, I got reports fast and the assistance provided by doctors is amazing.
                                        </Card.Text>
                                        <Card.Link href="#" className={styles.medicalCardLink}>Jyoti Kumari</Card.Link>
                                        <Card.Text className={styles.medicalCardText}>
                                            Gurugram
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className={styles.medicalServices}>
                                <Card className={styles.medicalCard}>
                                    <Card.Header className={styles.medicalCardHeader}>
                                        <Image className="mx-auto" roundedCircle src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} alt="Client Says" />
                                    </Card.Header>
                                    <Card.Body className={styles.medicalCardBody}>
                                        <Card.Text className={styles.medicalCardText}>
                                            What I love the most about drray24 is that they not only help you recover physically but emotionally and mentally. That’s the best service and medicine I have received lately from you. Thank you a lot!
                                        </Card.Text>
                                        <Card.Link href="#" className={styles.medicalCardLink}>Sidhart Verma</Card.Link>
                                        <Card.Text className={styles.medicalCardText}>
                                            Noida
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col className={styles.medicalServices}>
                                <Card className={styles.medicalCard}>
                                    <Card.Header className={styles.medicalCardHeader}>
                                        <Image className="mx-auto" roundedCircle src={window.location.origin + "/assets/images/home/customer_img/user.jpg"} alt="Client Says" />
                                    </Card.Header>
                                    <Card.Body className={styles.medicalCardBody}>
                                        <Card.Text className={styles.medicalCardText}>
                                            So overwhelmed! Honestly, I was never fond of treatment hospitals and doctors but after associating with you, I was amazed by the cleanliness and facilities you are providing to the patients. It changed the way I see labs now! 
                                        </Card.Text>
                                        <Card.Link href="#" className={styles.medicalCardLink}>Sarang Jain</Card.Link>
                                        <Card.Text className={styles.medicalCardText}>
                                            Gurugram
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Carousel>
                    </div>
                </Container>
            </Container>
        );
    }
}

export default TestimonialComponent;