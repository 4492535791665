import React, { Component } from 'react';
import styles from './My-Report-List.module.scss';
import MaterialTable from 'material-table';

import { forwardRef } from 'react';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn } from "@material-ui/icons";

class MyReportListComponent extends Component {
    render() {

        function MaterialTableDemo(data) {
            const [state, setState] = React.useState({
                columns: [
                    { title: "Sr. No.", render: rowData => rowData.tableData.id + 1  },
                    { title: 'Invoice Id', field: 'invoice_id', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Barcode Id', field: 'barcode_id', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Patient Name', field: 'name', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Mobile', field: 'mobile', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Add Date', field: 'report_approve_date', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Action', field: 'action', cellStyle: { fontSize: '.9rem' }, render: patientAction => (<a href={"https://stage.drray24.in/assets/pdf/lab/test-report/"+patientAction.pdf_name} target="_blanck" className={`my-btn ${styles.actionView}`}>View</a>)  },
                  ],
                  data: data.myTests,
            });

            const tableIcons = {
                Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
                ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
            }
          
            return (
              <MaterialTable
                title=""
                columns={state.columns}
                data={state.data}
                icons={tableIcons}
              />
            );
        }

        return(
            <MaterialTableDemo myTests={this.props.myTests} />
        );
    }
}

export default MyReportListComponent;