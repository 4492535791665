import React, {Component} from 'react';
import styles from './User-Profile-Header.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom'
import ImageUploaderComponent from './Image-Uploader/Image-Uploader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MainSliderComponent from '../../Home/Main-Slider/Main-Slider';

class UserProfileHeader extends Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            appName: this.props.match.params.appName
        }
    }

    handleLogout () {
        this.props.logout();
        this.props.history.push('/');
    }

    componentDidMount() {
        let appName = this.props.match.params;
    }

    render() {
        var {appName} = this.state;
        return(
            <>

            {
                appName == 'app' ? (
                    
                    <Container fluid className={`mt-n3 p-0 mb-md-5 mb-0 userPanelBottomSpace`}>
                        <Container>
                            <Row>
                                <Col xs={4} className={`${styles.navbarLink}`}>
                                    <NavLink to="/doctor" activeClassName={`${styles.active}`}>Doctors <span className={`d-block`}>Book an appointment</span></NavLink>
                                </Col>
                                <Col xs={4} className={`${styles.navbarLink}`}>
                                    <NavLink to="/pharmacy" activeClassName={`${styles.active}`}>Pharmacy <span className={`d-block`}>Book medicine</span></NavLink>
                                </Col>
                                <Col xs={4} className={`${styles.navbarLink}`}>
                                    <NavLink to="/diagnostic" activeClassName={`${styles.active}`}>Diagnostic <span className={`d-block`}>Book blood test</span></NavLink>
                                </Col>
                            </Row>
                        </Container>

                        <MainSliderComponent />
                    </Container>

                ) : (

                    <Container fluid className={`mt-n3 p-0 userPanelBottomSpace`}>
                        <div className={`${styles.userProfileUpper}`}>
                            <Container>
                                <Row className={`flex-row-reverse align-items-end ${styles.userProfileUpperRow}`}>
                                    <ImageUploaderComponent />

                                    <div className={`${styles.edit_profile}`}>
                                        <Link to="/edit-profile" className={`my-btn`}>
                                            Edit Profile
                                        </Link>
                                    </div>
                                </Row>
                            </Container>
                        </div>

                        <div className={`${styles.userProfileLower}`}>
                            <Container>
                                <Row className={`${styles.userProfileLowerRow}`}>
                                    <h4>{this.props.userData.f_name} {this.props.userData.l_name}</h4>
                                    <Button onClick={this.handleLogout} className={`${styles.logoutBtn}`}>Logout</Button>
                                </Row>
                            </Container>
                        </div>
                    </Container>

                )
            }
            </>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        userData: state.userAuth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {dispatch({type: 'LOGOUT'})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileHeader));