import React, {Component, useState} from 'react';
import styles from "./Edit-Phone-Number.module.scss";
import { Col, Form } from 'react-bootstrap';
import { Button, TextField } from '@material-ui/core';
import Modal from 'react-bootstrap/Modal';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

class EditPhoneNumber extends Component{
    render() {
        /***** modal-popup *******/
        function EditPhoneNumberPopup() {
            const [show, setShow] = useState(false);
          
            const handleClose = () => setShow(false);
            const handleShow = () => setShow(true);
          
            return (
              <>
                <Button className={`my-btn ${styles.editFiledText} ${styles.helpPopup}`} variant="contained" onClick={handleShow}>Edit</Button>
          
                <Modal show={show} onHide={handleClose} centered className="customModal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Phone Number
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        
                        <Form className="customForm">
                            <div id="loginForm" className={`loginForm row`}>
                                <Form.Row as={Col} xs={12} className={`${styles.EditFormText}`}>
                                    <p>
                                        We will send you an OTP code via SMS.
                                    </p>
                                </Form.Row>

                                <Form.Row as={Col} xs={12}>
                                    <TextField required label="Enter New Number" name="userNewNumber" variant="outlined" />
                                </Form.Row>
                                
                                <Form.Row as={Col} xs={12}>
                                    <Button className="my-btn mx-auto px-5" variant="contained" type="submit">
                                        Send Otp
                                    </Button>

                                    <Button className="my-btn my-btn-second mx-auto px-5" variant="contained" type="button">
                                        Cancel
                                    </Button>
                                </Form.Row>
                            </div>

                            <div id="loginFormWidOtp" className={`loginFormWidOtp row`}>
                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                    <p>
                                        We have sent OTP on entered mobile number and registered email id, if not received within 3 minutes please click on <span class="resend_otp">resend otp!</span>
                                    </p>
                                </Form.Row>

                                <Form.Row as={Col} xs={12}>
                                    <TextField required label="Enter otp" name="userOTP" variant="outlined" />
                                </Form.Row>

                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection} ${styles.OtpSection}`}>
                                    <Button id="loginFormWidOtpBtn" className={`my-btn mt-3 px-5 btn-block loginFormWidOtpBtn`} variant="contained" type="submit">
                                        Verify
                                    </Button>

                                    <span className={`${styles.OtpBackBtn} mt-3`}><ChevronLeftIcon /></span>
                                </Form.Row>
                            </div>
                        </Form>

                    </Modal.Body>
                </Modal>
              </>
            );
        }

        return(
            <div>
                <EditPhoneNumberPopup />
            </div>
        );
    }
}

export default EditPhoneNumber;