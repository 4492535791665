import React, {Component} from 'react';
import styles from './Main-Slider.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';

const responsiveMainBanner = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
};

class MainSliderComponent extends Component{
    render() {
        return(
            <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={100000000}
            centerMode={false}
            className={`customSliderDiv ${styles.customCarousel}`}
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsiveMainBanner}
            dotListClass="custom-dot-list-style"
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
            >
                <div>
                    <Link to="/search/specialization/Cardiologist/3" variant="contained">
                        <Image className="w-100 d-none d-md-block" src={window.location.origin + "/assets/images/home/home_banner1.svg"} alt="First slide" />
                        <Image className="w-100 d-block d-md-none" src={window.location.origin + "/assets/images/home/home_banner1_m.svg"} alt="First slide" />
                    </Link>
                    <div className={`${styles.mainBannerContent}`}>
                        <h3>
                            Don’t Ignore symptoms, <span>Consult doctors now!</span>
                        </h3>
                        <Link className={`my-btn`} to="/search/specialization/Cardiologist/3" variant="contained">
                            Book Now
                        </Link>
                    </div>
                </div>
                
                <div>
                    <Link to="/search/specialization/Cardiologist/3" variant="contained">
                        <Image className="w-100 d-none d-md-block" src={window.location.origin + "/assets/images/home/home_banner2.svg"} alt="Second slide" />
                        <Image className="w-100 d-block d-md-none" src={window.location.origin + "/assets/images/home/home_banner2_m.svg"} alt="Second slide" />
                    </Link>
                    <div className={`${styles.mainBannerContent}`}>
                        <h3>
                            Get well soon with our trusted team of <span className="d-inline">experienced doctors.</span>
                        </h3>
                        <Link className={`my-btn`} to="/search/specialization/Cardiologist/3" variant="contained">
                            Book Now
                        </Link>
                    </div>
                </div>

                <div>
                    <Link to="/diagnostic" variant="contained">
                        <Image className="w-100 d-none d-md-block" src={window.location.origin + "/assets/images/home/home_banner3.svg"} alt="Third slide" />
                        <Image className="w-100 d-block d-md-none" src={window.location.origin + "/assets/images/home/home_banner3_m.svg"} alt="Third slide" />
                    </Link>
                    <div className={`${styles.mainBannerContent}`}>
                        <h3>
                            Trust the best minds of the medical, <span>world for your fast recovery!</span>
                        </h3>
                        <Link className={`my-btn`} to="/search/specialization/Cardiologist/3" variant="contained">
                            Book Now
                        </Link>
                    </div>
                </div>
            </Carousel>
        );
    }
}

export default MainSliderComponent;