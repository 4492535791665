import React, {Component} from 'react';
import styles from './Footer.module.scss';
import { Image, Container, Row, Col, Nav, Figure } from 'react-bootstrap';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { Link, withRouter } from 'react-router-dom';
import TestimonialComponent from './Testimonial/Testimonial';
import DownloadAppComponent from './Download-App/Download-App';

class Footer extends Component {
    render() {
        var route = ['my-account', 'my-doctor', 'my-appointment', 'prescription', 'my-test', 'my-report', 'profile', 'graph-view', 'add-member', 'edit-profile', 'search', 'checkout', 'login', 'register', 'appointment', 'manage-address'];
        var currentRoute = this.props.location.pathname.split('/');
        if(!route.includes(currentRoute[1])) {
            return(
                <footer className="footer">
                    <TestimonialComponent />

                    <DownloadAppComponent />

                    <Container fluid className={`${styles.footerMain}`}>

                        <Container>

                            <Row>

                                <Col md={3} className={`${styles.footerList}`}>
                                    <h4>Information</h4>
                                    <Nav className={`${styles.footerListNav}`}>
                                        <Link to="/about">About Us</Link>
                                        <Link to="/careers">Careers</Link>
                                        <Link to="/contact">Contact Us</Link>
                                        <Link to="/terms-conditions">Terms & Conditions</Link>
                                        <Link to="/faq">FAQ</Link>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </Nav>
                                </Col>

                                <Col md={3} className={`${styles.footerList}`}>
                                    <h4>For Patients</h4>
                                    <Nav className={`${styles.footerListNav}`}>
                                        <a>Read Health Care</a>
                                        <a>Read About Medicines</a>
                                        <a>Book Diagnostic Test</a>
                                        <a>Book Full Body Checkups</a>
                                        <Link to="/refund">Read Refund Policy</Link>
                                    </Nav>
                                </Col>

                                <Col md={3} className={`${styles.footerList}`}>
                                    <h4>Our Services</h4>
                                    <Nav className={`${styles.footerListNav}`}>
                                        <a>Book An Appointment</a>
                                        <a>Online Medicine</a>
                                        <a>Lab Tests</a>
                                    </Nav>
                                </Col>

                                <Col md={3} className={`${styles.footerList}`}>
                                    <Figure className="mb-0 ">
                                        <Image src={window.location.origin + "/assets/images/logo.png"} alt="Logo" fluid width="150" />
                                    </Figure>
                                    <h6>
                                        DrRay24 is a comprehensive certified laboratory with advanced facilities for any test, diagnosis, and treatment with 24/7 support. We commit to your wellness.
                                    </h6>
                                    <Nav className={`${styles.addressDetail} ${styles.footerListNav}`}>
                                        <p>
                                            <RoomIcon className={`${styles.addressIcon}`} /> 315, 3rd Floor, Ocus Quantum Internal Rd, Sector 51, Gurugram, Haryana 122003
                                        </p>
                                        <p><Nav.Link href="tel:9856000000"><PhoneIcon /> +91 8448677438</Nav.Link></p>
                                        <p><Nav.Link href="mailto:info@drray24.in"><EmailIcon /> info@drray24.in</Nav.Link></p>
                                    </Nav>
                                </Col>

                            </Row>

                            <Row>
                                <Col className={`${styles.copyWriteLine}`}>
                                    <p>
                                        &copy; 2017, DrRay24. All rights reserved
                                    </p>
                                </Col>
                            </Row>

                        </Container>

                    </Container>
                </footer>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(Footer);