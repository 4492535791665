import React, {Component} from 'react';
import styles from './Home.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Image, CardGroup, Card, Container, Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Service from '../../services/Service';
import DoctorSpecialistComponent from '../Carousel-Card/Doctor-Specialist/Doctor-Specialist';
import MainSliderComponent from './Main-Slider/Main-Slider';

const responsiveItemList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

const responsiveTestimonials = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 1,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 1,
        partialVisibilityGutter: 30
    }
};

const responsiveMainBanner = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
};

class Home extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            diagnostic: []
        }
    }

    componentDidMount() {
        const API = Service.get('diagnostic');
        API.then(json => {
            this.setState({
                isLoading: true,
                diagnostic: json
            });
        });
        this.props.currentRoute(null);
        document.title = "Home"
    }

    render() {
        var {diagnostic, isLoading} = this.state;
        return(
            <div className="content mt-n3">
                <MainSliderComponent />                

                <CardGroup className={styles.weOffers}>
                    <Container>
                        <Row className={styles.row}>
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Image width="50" className="mx-auto" src="assets/images/home/doctor_card.jpg" alt="Book Appointment" />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>Doctors</Card.Title>
                                        <Card.Text className={styles.text}>
                                            Our 250 plus MBBs and MD Doctors are professionals in their respective departments. They treat every patient like a member of their own family.
                                        </Card.Text>
                                        <Link to="/doctor" className={`${styles.weOffersBtn} my-btn`} variant="contained">Book Now</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Image width="50" className="mx-auto" src="assets/images/home/pharmacy_card.jpg" alt="Book Pharmacy" />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>Pharmacy</Card.Title>
                                        <Card.Text className={styles.text}>
                                            Our pharmacist passions the effective use of drugs. We take special precautions to maintain the safest production, discovery, and disposal methods.
                                        </Card.Text>
                                        <Link to="/pharmacy" className={`${styles.weOffersBtn} my-btn`} variant="contained">Book Now</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4}>
                                <Card className={styles.weOffers_card}>
                                    <Image width="50" className="mx-auto" src="assets/images/home/diagnostics_card.jpg" alt="Book Diagnostics" />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>Diagnostics</Card.Title>
                                        <Card.Text className={styles.text}>
                                            We handle some of the most critical cases of illness with intensive care. Count on us for health checkups, X-rays, MRI, Health screenings, CT scan ECG, and more.
                                        </Card.Text>
                                        <Link to="/diagnostic" className={`${styles.weOffersBtn} my-btn`} variant="contained">Book Now</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </CardGroup>

                <Container fluid className={styles.aboutContent}>
                    <Container>
                        <Row>
                            <Col md={5} xs={12}>
                                <Image src="assets/images/home/about_us.png" alt="About Us" fluid />
                            </Col>

                            <Col md={7} xs={12} className={styles.aboutContent_text}>
                                <h1>
                                    Welcome To Drray <span className="d-block">Better Life Healthcare</span>
                                </h1>
                                <p>
                                    We are here to break healthcare as a privilege and make treatments and diagnosis available for everyone. This venture started as our concern for the rising number of mortality rates, deaths, diseases, and poor treatment in healthcare sectors. Being one of India’s top diagnostic laboratories we expertise in best industrial practices. We have come up with defining solutions through the combined efforts of technology, artificial intelligence, and specialties of medicines to share the best benefits with our clients. What makes us unique is our talked about ethical practices, availability of wide testing facilities, seamless quality of work, and affordability of services.
                                </p>
                                <Link to="/about" className={`${styles.aboutBtn} my-btn`}>Read More</Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.customItemSlider} bg-white`}>
                    <Container>
                        <Col className="px-0">
                            <h3 className="customHeading">Meet Our Specialist</h3>
                            <p className="sectionText">
                                We cater to all kinds of emergency medical services, appointments, and primary treatments for all ages through our team of real heroes. Our specialists are available 24/7 for everyone with any disease.
                            </p>
                        </Col>

                        <DoctorSpecialistComponent />
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.appointmentBanner}`}>
                    <Container>
                        <Row>
                            <Col xs className={styles.appointmentBanner_text}>
                                <p>Need a doctor for check-up?</p>
                                <h3>
                                    Just make an appointment & you’re done
                                </h3>
                                <Button className="my-btn" variant="contained">Make an appointment</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`sectionSpace ${styles.customItemSlider} pb-0 bg-white`}>
                    <Container>
                        <Col className="px-0">
                            <h3 className="customHeading">Our Medical Services</h3>
                            <p className="sectionText">
                                Our top range of medical services is known well for its highly efficient and accurate results every time. We cover everything from Clinical Pathology, Genetics, Hematology, Microbiology, and Serology to name some.
                            </p>
                        </Col>
                        { isLoading ? 
                            (
                                <Carousel
                                additionalTransfrom={0}
                                arrows={false}
                                autoPlay
                                autoPlaySpeed={10000}
                                centerMode={false}
                                className={`customSliderDiv ${styles.meetSpecialistSlider}`}
                                containerClass="container-with-dots"
                                dotListClass="custom-dot-list-style"
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={10}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsiveItemList}
                                showDots={true}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                                    {
                                        diagnostic.result.health_category.map((item, i) => (
                                            <Col className={styles.medicalServices} key={i}>
                                                <Card className={styles.medicalCard}>
                                                    <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`}>
                                                        <Card.Header className={styles.medicalCardHeader}>
                                                            <Image className={`mx-auto ${styles.servicesImg}`} src="assets/images/home/services/immunisations.jpg" alt="immunisations" />
                                                            <Image className={`mx-auto ${styles.servicesImg_c}`} src="assets/images/home/services/immunisations_c.jpg" alt="immunisations" />
                                                        </Card.Header>
                                                    </Link>
                                                    <Card.Body className={styles.medicalCardBody}>
                                                        <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`} className={styles.medicalCardLink}>{item.name}</Link>
                                                        <Card.Text className={`${styles.medicalCardText} d-none`}>
                                                            Lorem ipsum dolor sit amet adipiscing.
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer className={styles.medicalCardFooter}>
                                                        <Link to={`/diagnostic/health-risk/${encodeURIComponent(item.name.replace(/ /g, "-").toLowerCase())}/${item.id}`} className={`my-btn ${styles.medicalBtn}`}>See</Link>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Carousel>
                            ) : null}
                    </Container>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        cartItems: state.items,
        total: state.total,
        route: state.route
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        currentRoute: (route) => {dispatch({type: 'CURRENT_ROUTE', route: route})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);