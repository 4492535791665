import React, { Component } from 'react';
import styles from './Contact.module.scss';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import EmailIcon from '@material-ui/icons/Email';
import { Button } from '@material-ui/core';
import { Container, Image, Row, Col, Breadcrumb, Card, Form } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

class Contact extends Component{

    componentDidMount() {
        document.title = "Contact"
    }

    state = {
        formData: {
            userName: '',
            userEmail: '',
            userPhone: '',
            userEnquiry: '',
        },
        submitted: false,
    }

    handleChange = (event) => {
        const {formData} = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    render() {
        const { formData, submitted } = this.state;

        return(
            <div className="content">
                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>Contact Us</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Contact Us
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={`${styles.contactDetail} ${styles.fluid} bg-white`}>
                    <Container>
                        <Row>
                            <Col sm={4} xs={4}>
                                <Card className={styles.card}>
                                    <PinDropIcon className={styles.fontIcon} />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>
                                            Location
                                        </Card.Title>
                                        <Card.Text className={styles.text}>
                                            315, 3rd Floor, Ocus Quantum Internal Rd, Sector 51, Gurugram, Haryana 122003
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={4}>
                                <Card className={styles.card}>
                                    <PhoneInTalkIcon className={styles.fontIcon} />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>
                                            Phone
                                        </Card.Title>
                                        <Card.Link href="tel:9856000000" className={styles.link}>
                                            +91 9856000000
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col sm={4} xs={4}>
                                <Card className={styles.card}>
                                    <EmailIcon className={styles.fontIcon} />
                                    <Card.Body className={styles.body}>
                                        <Card.Title className={styles.title}>
                                            Email
                                        </Card.Title>
                                        <Card.Text className={styles.text}>
                                            info@drray24.in
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={[styles.contactForm, styles.sectionSpace]}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={9} md={10} xs={12}>
                                <h3 className="customHeading">Get In Touch With Us</h3>
                                <p className="sectionText">
                                    We would love to hear from you. For any concerns, queries, and suggestions feel free to connect with us anytime. We would be more than happy to serve you. Also you can raise your willingness and connect for special services like pick and drop, elderly healthcare and home transfers with us. We priories your time and value each feedback with great honor.
                                </p>
                            </Col>
                            
                            <Col lg={9} md={10} xs={12}>
                                <ValidatorForm ref="form" className="customForm row" onSubmit={this.handleSubmit}>
                                    <Form.Row as={Col} xs={12}>
                                        <TextValidator label="Enter your name" name="userName" variant="outlined" onChange={this.handleChange}
                                        value={formData.userName} validators={['required']} errorMessages={['this field is required']} />
                                    </Form.Row>
                                    
                                    <Form.Row as={Col} sm={6} xs={12}>
                                        <TextValidator label="Enter your email" name="userEmail" variant="outlined" onChange={this.handleChange}
                                        value={formData.userEmail} validators={['required', 'isEmail']} errorMessages={['this field is required', 'email is not valid']} />
                                    </Form.Row>
                                    
                                    <Form.Row as={Col} sm={6} xs={12}>
                                        <TextValidator label="Enter your phone" name="userPhone" variant="outlined" onChange={this.handleChange}
                                        value={formData.userPhone} errorMessages={['this field is required']}
                                        validators={['required']} />
                                    </Form.Row>
                                    
                                    <Form.Row as={Col} xs={12}>
                                        <TextValidator label="Tell us about your enquiry" name="userEnquiry" variant="outlined" onChange={this.handleChange}
                                        value={formData.userEnquiry} validators={['required']} errorMessages={['this field is required']} />
                                    </Form.Row>
                                    
                                    <Form.Row as={Col} xs={12}>
                                        <Button className="my-btn mt-4 mx-auto px-5" variant="contained" type="submit" disabled={submitted}>
                                            {
                                                (submitted && 'Your form is submitted!') || (!submitted && 'Submit')
                                            }
                                        </Button>
                                    </Form.Row>
                                </ValidatorForm>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className="p-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.810256752196!2d77.06620251558378!3d28.42498168250013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1876a0db4f59%3A0xe50fe47fb8af8ed1!2sGETTOOWELL%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1584360948303!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </Container>

            </div>
        );
    }
}

export default Contact;