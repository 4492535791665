import React, {Component} from 'react';
import styles from './My-Account.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DetailsIcon from '@material-ui/icons/Details';
import BookIcon from '@material-ui/icons/Book';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {Link} from 'react-router-dom';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';

class MyAccount extends Component {
    
    componentDidMount() {
        document.title = "My Account"
    }

    render() {
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Accounts
                                    <span>
                                        Overview Description
                                    </span>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/my-doctor" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <LocalHospitalIcon />
                                                </h6>
                                                <p>Doctor</p>
                                                <span>Check doctor list</span>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/my-oder" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <ListAltIcon />
                                                </h6>
                                                <p>Order</p>
                                                <span>Check your order</span>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/prescription" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <DetailsIcon />
                                                </h6>
                                                <p>Prescription</p>
                                                <span>Check doctor prescription</span>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/book-test" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <BookIcon />
                                                </h6>
                                                <p>Book Test</p>
                                                <span>Check your test</span>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/edit-profile" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <ContactsIcon />
                                                </h6>
                                                <p>Profile</p>
                                                <span>Check your profile</span>
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col md={4} sm={6} xs={12} className={`mb-4`}>
                                        <Link to="/add-member" className={`w-100 position-relative d-block`}>
                                            <div className={`${styles.userDetailBox}`}>
                                                <h6>
                                                    <PersonAddIcon />
                                                </h6>
                                                <p>Add Memeber</p>
                                                <span>Add family members</span>
                                            </div>
                                        </Link>
                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default MyAccount;