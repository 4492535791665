import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function DeliveryMode() {
  const [value, setValue] = React.useState('cod');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" className={`customRadio deliveryMode1`}>
      <FormLabel component="legend">Payment Method <sup>*</sup></FormLabel>
      <RadioGroup aria-label="label" name="deliveryMode1" value={value} onChange={handleChange}>
        <FormControlLabel value="cod" control={<Radio />} label="COD" />
      </RadioGroup>
    </FormControl>
  );
}