import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import Pharmacy from './pages/Pharmacy/Pharmacy.js';
import Diagnostic from './pages/Diagnostic/Diagnostic.js';
import Checkout from './pages/Checkout/Checkout.js';
import About from './pages/About/About.js';
import Contact from './pages/Contact/Contact.js';
import Careers from './pages/Careers/Careers.js';
import Header from './common/Header/Header.js';
import Footer from './common/Footer/Footer.js';
import TermsConditions from './pages/Terms-Conditions/Terms-Conditions';
import PrivacyPolocy from './pages/Privacy-Policy/Privacy-Policy';
import Faq from './pages/Faq/Faq';
import Refund from './pages/Refund/Refund';
import TestPackages from './pages/Diagnostic/Test-Packages/Test-Packages';
import HealthRisk from './pages/Diagnostic/Health-Risk/Health-Risk.js';
import TestDetail from './pages/Diagnostic/Test-Detail/Test-Detail.js';
import Tests from './pages/Diagnostic/Tests/Tests.js';
import ScrollToTop from './common/ScrollToTop.js';
import Doctor from './pages/Doctor/Doctor.js';
import ThankYou from './pages/Checkout/Thank-You/Thank-You.js';
import DoctorList from './pages/Doctor/Doctor-List/Doctor-List.js';
import DoctorDetails from './pages/Doctor/Doctor-Details/Doctor-Details.js';
import Appointment from './pages/Doctor/Appointment/Appointment.js';
import MyAccount from './pages/User-Profile/My-Account/My-Account.js';
import LoginComponent from './pages/Login/Login.js';
import RegisterComponent from './pages/Register/Register.js';
import EditProfile from './pages/User-Profile/Edit-Profile/Edit-Profile.js';
import MyDoctor from './pages/User-Profile/My-Doctor/My-Doctor.js';
import ThankYouRegister from './pages/Register/Thankyou-Register/Thankyou-Register.js';
import PageNotFound from './pages/Page-Not-Found/Page-Not-Found.js';
import MyAppointment from './pages/User-Profile/My-Appointment/My-Appointment.js';
import PrescriptionComponent from './pages/User-Profile/Prescription/Prescription.js';
import AddMembers from './pages/User-Profile/Add-Members/Add-Members.js';
import ProfileComponent from './pages/User-Profile/Profile/Profile.js';
import ForgotPassword from './pages/Forgot-Password/Forgot-Password.js';
import MyTest from './pages/User-Profile/My-Test/My-Test.js';
import MyReport from './pages/User-Profile/My-Report/My-Report.js';
import PrescriptionView from './pages/User-Profile/Prescription/Prescription-View/Prescription-View.js';
import GraphViewComponent from './pages/User-Profile/Profile/Graph-View/Graph-View.js';
import OrderMedicine from './pages/User-Profile/Order-Medicine/Order-Medicine.js';
import Protected from './Protected';
import AllSpecialization from './pages/Doctor/All-Specialization/All-Specialization.js';
import ManageAddress from './pages/User-Profile/Manage-Address/Manage-Address.js';

class App extends  Component{
    
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="wrapper">
            <Header/>
            <Switch>
              <Route exact path="/" component = {Diagnostic} />
              <Route exact path="/pharmacy" component = {Pharmacy} />
              <Route exact path="/diagnostic" component = {Diagnostic} />
              <Route exact path="/diagnostic/tests" component = {Tests} />
              <Route exact path="/diagnostic/test-packages" component = {TestPackages} />
              <Route exact path="/diagnostic/tests/:testName/:test_code" component = {TestDetail} />
              <Route exact path="/diagnostic/packages/:testName/:test_code" component = {TestDetail} />
              <Route exact path="/doctor" component = {Doctor} />
              <Route exact path="/doctor-list" component = {DoctorList} />
              <Route exact path="/diagnostic/health-risk/:name/:id" component = {HealthRisk} />
              <Route exact path="/search/specialization/:name/:id" component = {DoctorList} />
              <Route exact path="/about" component = {About} />
              <Route exact path="/contact" component = {Contact} />
              <Route exact path="/careers" component = {Careers} />
              <Route exact path="/terms-conditions" component = {TermsConditions} />
              <Route exact path="/privacy-policy" component = {PrivacyPolocy} />
              <Route exact path="/faq" component = {Faq} />
              <Route exact path="/refund" component = {Refund} />
              <Route exact path="/thank-you" component = {ThankYou} />
              <Route exact path="/doctor-details" component = {DoctorDetails} />
              <Route exact path="/login" component = {LoginComponent} />
              <Route exact path="/register" component = {RegisterComponent} />
              <Route exact path="/forgot-password" component = {ForgotPassword} />
              <Route exact path="/thankyou-register" component = {ThankYouRegister} />
              <Route exact path="/prescription-view" component = {PrescriptionView} />
              <Route exact path="/graph-view" component = {GraphViewComponent} />
              <Route exact path="/order-medicine" component = {OrderMedicine} />
              <Route exact path="/search/doctor/:name/:id" component = {DoctorDetails} />
              <Route exact path="/appointment/:appTime/:clinicId" component = {Appointment} />
              <Route exact path="/checkout"><Protected cmp={Checkout} /></Route>
              <Route exact path="/my-account"><Protected cmp={MyAccount} /></Route>
              <Route exact path="/my-account/platform/:appName"><Protected cmp={MyAccount} /></Route>
              <Route exact path="/edit-profile"><Protected cmp={EditProfile} /></Route>
              <Route exact path="/my-doctor"><Protected cmp={MyDoctor} /></Route>
              <Route exact path="/my-appointment"><Protected cmp={MyAppointment} /></Route>
              <Route exact path="/prescription"><Protected cmp={PrescriptionComponent} /></Route>
              <Route exact path="/my-test"><Protected cmp={MyTest} /></Route>
              <Route exact path="/my-report"><Protected cmp={MyReport} /></Route>
              <Route exact path="/profile"><Protected cmp={ProfileComponent} /></Route>
              <Route exact path="/add-member"><Protected cmp={AddMembers} /></Route>
              <Route exact path="/all-specialization" component = {AllSpecialization} />
              <Route exact path="/manage-address" component = {ManageAddress} />
              <Route exact path="" component = {PageNotFound} />
            </Switch>
            <Footer/>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
