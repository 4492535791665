import React, { Component } from "react";
import styles from './Test-package-card.module.scss';
import { Card, Col, ListGroup } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

class TestPackageCard extends Component{
    render() {
        return(
            <Card className={`${styles.healthPackageCard} rounded-0`}>
                <Card.Header className={styles.healthPackageHeader}>
                    <Col className={styles.offPriceDiv}>
                        {this.props.test.discount_percentage > 0 ? (
                            <h5>{this.props.test.discount_percentage.replace(/\.00$/,'')}% <span>Off</span></h5>
                        ) : null }
                    </Col>
                    <Link to={`/diagnostic/packages/${encodeURIComponent(this.props.test.test_name.replace(/ /g, "-").toLowerCase())}/${this.props.test.test_code}`}>
                        {this.props.test.test_name}
                    </Link>
                </Card.Header>

                <Card.Body className={styles.healthPackageBody}>
                    <Card.Title className={styles.healthPackageTitle}>
                        Includes : <b> {this.props.test.total_parameters} Parameters </b>
                    </Card.Title>
                    <Card.Text className={styles.healthPackageText}>
                        <ListGroup as="ul" variant="flush" className={styles.healthPackageList}>
                            {
                                this.props.test.parameters.map((item, i) => (
                                    <ListGroup.Item as="li" key={i} className={styles.healthPackageListItem}>{item.test_name}</ListGroup.Item>
                                ))
                            }
                            <ListGroup.Item as="li" className={styles.healthPackageListItem}>
                                <Link to={`/diagnostic/packages/${encodeURIComponent(this.props.test.test_name.replace(/ /g, "-").toLowerCase())}/${this.props.test.test_code}`}>Know More...</Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Text>
                </Card.Body>

                <Card.Footer className={styles.healthPackageFooter}>
                    <Col>
                        <h5>
                            ₹{this.props.test.final_mrp}&nbsp;
                            {this.props.test.discount_percentage > 0 ? (
                                <del>₹{this.props.test.mrp}</del>
                            ) : null }
                        </h5>
                    </Col>

                    <Link to="/checkout">
                        <button className={`my-btn ${styles.healthPackageBtn}`} onClick={() => this.props.addToCart(this.props.test)}>Book Now</button>
                    </Link>
                </Card.Footer>
            </Card>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (item) => {dispatch({type: 'ADD_TO_CART', item: item})}
    }
}

export default connect(null, mapDispatchToProps)(TestPackageCard);