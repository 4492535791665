import React, { Component } from "react";
import styles from './Back-To-Top.module.scss';
import BackToTop from "react-back-to-top-button";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class BackToTopButton extends Component{
    render() {
        return(
            <div className={`${styles.backToTopBtn}`}>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <span>
                    <ArrowBackIosIcon />
                </span>
            </BackToTop>
            </div>
        );
    }
}

export default BackToTopButton;