import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {Link} from 'react-router-dom';

import { forwardRef } from 'react';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn } from "@material-ui/icons";

class AddMemberListComponent extends Component {

  render() {

      function MaterialTableDemo(data) {
          const [state, setState] = React.useState({
            columns: [
              { title: "Sr. No.", render: rowData => rowData.tableData.id + 1  },
              { title: 'Patient Name', field: 'patient_name', cellStyle: { fontSize: '.9rem' } },
              { title: 'Relation', field: 'relation', cellStyle: { fontSize: '.9rem' } },
              { title: 'DOB', field: 'dob', cellStyle: { fontSize: '.9rem' } },
            ],
            data: data.members,
          });

          const tableIcons = {
              Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
              Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
              Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
              Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
              DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
              Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
              Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
              Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
              FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
              LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
              NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
              PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
              ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
              Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
              SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
              ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
              ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          }
        
          return (
            <MaterialTable
              title="Members"
              columns={state.columns}
              data={state.data}
              icons={tableIcons}
            />
          );
      }

      return(
          <MaterialTableDemo members={this.props.members} />
      );
  }
}

export default AddMemberListComponent;