import React, { Component} from "react";
import styles from './Doctor-List.module.scss';
import { TextField, MenuItem } from '@material-ui/core';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import Filter from '../../Filter/Filter';
import {Link} from 'react-router-dom';
import SingleDoctorList from "./Single-Doctor-List/Single-Doctor-List";
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';

const currencies = [
    {
      value: 'relevance',
      label: 'Relevance',
    },
    {
      value: 'LowHigh',
      label: 'Price - Low to High',
    },
    {
      value: 'HighLow',
      label: 'Price - High to Low',
    },
    {
      value: 'YearExperience',
      label: 'Years of Experience',
    },
];

class DoctorList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            doctors: []
        }
    }

    componentDidMount() {
        var params = this.props.match.params;
        const formData = new FormData();
        formData.append('spl_id', params.id);
        const API = Service.post('doctor/splSearch', formData);
        API.then(json => {
            this.setState({
                isLoading: true,
                doctors: json['result']
            });
        });
        document.title = params.name;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({isLoading: false});
            const formData = new FormData();
            formData.append('spl_id', nextProps.match.params.id);
            const API = Service.post('doctor/splSearch', formData);
            API.then(json => {
                this.setState({
                    isLoading: true,
                    doctors: json['result']
                });
            });
        }
    }

    render() {
        var {isLoading, doctors} = this.state;
        if(isLoading) {
            return(
                <div className={`content bgGray`}>
                    <Container fluid>
                        <Container>
                            <Row>
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Home</Link>
                                        <Breadcrumb.Item active>Doctors</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className={`${styles.doctorListDiv}`}>
                        <Container>
                            <Row className={`${styles.cf}`}>
                                <Col md={3} xs={12} className={`${styles.filterSticky}`}>
                                    <Filter />
                                </Col>

                                <Col md={9} xs={12}>

                                    <Col className={`customSecondHeading ${styles.filterTop}`}>
                                        <h5>
                                            {doctors.length} {this.props.match.params.name} found!
                                            <span>
                                                Our Search Result
                                            </span>
                                        </h5>

                                        <TextField id="SortBy" className={`${styles.sortByFilter} customDropDown`} select required label="Sort By" name="SortBy" variant="outlined">
                                            {currencies.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>

                                    <Row className={`${styles.listingRow}`}>
                                        {
                                            doctors.map(item => (
                                                <Col xs="12" className={`${styles.listingRowItems}`}>
                                                    <SingleDoctorList doctor={item} />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            );
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default DoctorList;