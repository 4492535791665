import React, { Component, useState } from 'react';
import styles from './My-Doctor-List.module.scss';
import {Link} from 'react-router-dom';
import { forwardRef } from 'react';
import MUIDataTable from 'mui-datatables';

class MyDoctorList2Component extends Component{
    render() {
        function MUIDataTableDemo(doctors) {
            const [responsive, setResponsive] = useState("vertical");
            const [tableBodyHeight, setTableBodyHeight] = useState("400px");
            const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
          
            const [state, setState] = React.useState({
                columns : [
                    {
                        name: "srNo", label: "Sr. No.", options: {filter: true, sort: true}
                    },
                    {
                        name: "Image",
                        options: {
                            filter: true,
                            sort: false,
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <Link to="/">
                                        <img src='assets/images/home/doctors/image_1.jpg' style={{width: 40, borderRadius: '50%'}}/>
                                    </Link>
                                );
                            }
                        }
                    },
                    {
                        name: "Doctor Name",
                        options: {
                            filter: true,
                            sort: false,
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <Link to={{ pathname: '/foo/'+`${doctors.doctors[dataIndex].doctor_id}` }}>{doctors.doctors[dataIndex].doctor_name}</Link>
                                );
                            }
                        }
                    },
                    {
                        name: "mobile", label: "Phone No.", options: {filter: true, sort: false}
                    },
                    {
                        name: "last_visit", label: "Last Visit", options: {filter: true, sort: false}
                    }
                ],
                data: doctors.doctors,
            })
          
            const options = {
              filter: true,
              filterType: "dropdown",
              responsive,
              selectableRows: false, // for row checkbox
              print: false,
              download: false,
              viewColumns: false,
              filterTable: false
            };
          
            return (
               <MUIDataTable
                  title={"ACME Employee list"}
                  data={state.data}
                  columns={state.columns}
                  options={options}
                />
            );
        }

        return(
            <MUIDataTableDemo doctors={this.props.doctors}/>
        );
    }
}

export default MyDoctorList2Component;