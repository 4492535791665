import React, {Component} from 'react';
import styles from './Order-Medicine.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';
import ComingSoon from '../../Coming-Soon/Coming-Soon';

class OrderMedicine extends Component {

    componentDidMount() {
        document.title = "Order Medicine"
    }

    render() {
        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Order Medicine
                                    <span>
                                        Medicine List
                                    </span>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`tableMainDiv`}>
                                        <ComingSoon />
                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default OrderMedicine;