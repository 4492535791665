import React, { Component } from "react"
import styles from './Terms-Conditions.module.scss';
import { Container, Image, Row, Col, Breadcrumb } from 'react-bootstrap';


class TermsConditions extends Component{

    componentDidMount() {
        document.title = "Terms & Conditions"
    }

    render() {
        return(
            <div className="content">

                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="Contact Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>Terms & Conditions</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Terms & Conditions
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={styles.staticPageContent}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <p>
                                    GETTOOWELL Technology Private Limited (referred to as us, we, our, GETTOOWELL or company) is the owner and operator of the website www.drray24.in as well as the software and other applications provided through the website or mobile application ‘drray24’.
                                </p>
                                <p>
                                    User should carefully read these terms and conditions and the privacy policy of the company available at <a href="http://www.drray24.in/privacy-policy.php" target="_blank" rel="noopener noreferrer">http://www.drray24.in/privacy-policy.php</a> before he/she can decide to avail the services of our website. These terms and conditions and the privacy policy together constitute a legal agreement between the user and GETTOOWELL in connection with his/her visit to the website and his/her use of services:
                                </p>
                                <p>
                                    The legal agreement applies to the user whether he/she is - a medical practitioner or healthcare provider wishing to be registered or already registered on the website (referred to as doctor, practitioner, you or user); or a patient or his/her representatives, searching for doctors through the website (referred to as end-user, you or user); or otherwise a user of the website (referred to as visitor, you or user).
                                </p>
                                <p>
                                    This legal agreement applies to all services of GETTOOWELL available on the website, including - listing of profiles of doctors with their contact details, will be available to users of the website; and for others - facility to create and maintain accounts; search for doctors via name, specialty, geographical area etc. that may be available on our website; and to make appointments with doctors.
                                </p>
                                <p>
                                    GETTOOWELL at its sole discretion may change its services from time to time, and the legal agreement will apply to the user’s visit and use of our website services, as well as to all information provided by the user at any given point of time.
                                </p>
                                <p>
                                    This legal agreement defines the terms and conditions of use of the website and also describes the manner in which the company will treat the user’s account while he/she is registered as a member on our website. If the user has any question about any part of the legal agreement, he/she can feel free to contact us at info@drray24.in.
                                </p>
                                <p>
                                    By accessing our website for use of services, it implies that the user irrevocably accept all the terms & conditions as stipulated in this legal agreement, the subscription terms of service and privacy policy, as available on our website, and agree to abide by them. Through availing of any service, the user signifies his/her acceptance of the terms of this legal agreement.
                                </p>
                                <p>
                                    However, the company reserves the right to change, revise or remove any part of the legal agreement and/or any reason and at any point of time, and such changes shall be informed to the user in writing. Therefore, it is advised that the user should read the legal agreement at regular intervals. The use of our website by the user following any such change constitutes the user’s agreement to follow and be bound by the legal agreement so changed.
                                </p>
                                <p>
                                    The user acknowledges that he/she will be bound by this legal agreement for availing any of our website services. If the user disagrees with any portion of the legal agreement, he/she will not use our website or any of its services with immediate effect.
                                </p>
                                <p>
                                    The user’s access to use of the website and its services will be completely at the GETTOOWELL’s discretion.
                                </p>

                                <h5>
                                    Terms of use
                                </h5>
                                <p>
                                    In order to use our services, register, visit or use the website in any manner, the user must be 18 years or above. By accepting this legal agreement, you represent and warrant to GETTOOWELL that you have attained the age of 18 years or older, and that you have the authority, right and capacity to use the website and its services, and agree to and abide by this legal agreement.
                                </p>

                                <h5>
                                    Terms of use for all users excluding doctors
                                </h5>
                                <p>
                                    The terms in this clause are applicable only to users other than doctors.
                                </p>
                                <p>
                                    The terms of personal information and personal data are defined in the privacy policy.
                                </p>
                                <p>
                                    The company has access to the registered users’ personal data such as email address or telephone number, through website, for communication purpose to provide users with better way of booking appointments and for obtaining feedback regarding doctors and their practice.
                                </p>
                                <p>
                                    The privacy policy states about the type of information collected from users; the usage of such information; and how and to whom the company will disclose such information.
                                </p>
                                <p>
                                    You are expected to read and understand our privacy policy, to ensure that you have the knowledge about that certain data or information is being collected; purpose of collection; and the intended recipients of that data.
                                </p>
                                <p>
                                    We will not be responsible for the authenticity of the personal data supplied to us by you or to any other person acting on behalf of the company.
                                </p>
                                <p>
                                    You are responsible for maintaining the confidentiality of your account access information and password, if you are registered on the website. In case of any suspected unauthorized use of your account or password, you should immediately notify us. The company will not be liable for any of your loss caused due to any unauthorized use of your account. However, you may be liable for the losses of the company or such other parties as the case may be, due to any unauthorized use of your account.
                                </p>
                                <p>
                                    If you provide any false, inaccurate, or incomplete information, or the company has reasonable grounds to suspect that such information is incorrect, incomplete or false, the company has the right to discontinue your services at its sole discretion.
                                </p>
                                <p>
                                    The company may use information collected from you for debugging customer support related issues.
                                </p>

                                <h5>
                                    Listing content
                                </h5>
                                <p>
                                    The company collects and displays information on its website regarding the doctor’s profile and practice, those listed on the website, for example - doctor’s qualification, specialization, location, visiting hours etc. The company takes reasonable efforts to ensure that their information is updated at frequent intervals. The company screens the information submitted by the doctors, but it cannot be held liable for any inaccuracies, despite such reasonable efforts.
                                </p>
                                <p>
                                    Either the company or any of its service providers provide its services on an ‘as is’ and ‘as available’ basis, and without any warranties or conditions. The company does not make any representation, warranty or guarantee, express or implied about the website or the services. We do not guarantee the accuracy of any information provided by the end-users on the website. The company disclaims all liability, to the fullest extent permitted by law, arising out of the end-user’s use of the website, its services, and warranties made by other users, or data provided by them on the website.
                                </p>
                                <p>
                                    Our website may be linked to 3rd party websites, or our business partners. We have no control over, and not liable or responsible for validity, reliability, or quality of such websites. The inclusion of any link on our website does not imply that the company endorses the linked site. You may use the links and services at your own risk.
                                </p>
                                <p>
                                    The company shall not be liable for, any damages caused due to, any viruses that may affect your computer or any other device such as mobile phone etc. on account of your access to or browsing the website or downloading any of the data, images, text, video or audio content from the website. If you are dissatisfied with the website, you can discontinue using the website with immediate effect.
                                </p>
                                <p>
                                    In case the company determines that user has provided any false, or inaccurate information, the company reserves the right to immediately suspend the user’s access to the company’s website or any of the user’s account with the company and makes such declaration on its website alongside the user’s name or his clinic’s name as determined by the company for the protection of its business and in the interests of other users. In such a case the user shall be liable to indemnify the company for any loss incurred as a result of any misrepresentation or fraudulent feedback that has adversely affected us or our users.
                                </p>

                                <h5>
                                    Appointment booking
                                </h5>
                                <p>
                                    We will ensure that patients are provided confirmed appointment booking. However, we have no liability if such an appointment will be later cancelled by the doctor, or he/she will not be available for appointment.
                                </p>
                                <p>
                                    If any patient decides to engage with any doctor, found through website search result, to seek medical services, the patient shall be doing so at his/her own risk.
                                </p>
                                <p>
                                    The company is not involved in providing any healthcare or medical advice or diagnosis and hence will not be responsible for any interactions between the patient and the doctor. We believe that the patient understands and agrees that the company will not be responsible for: any patient interaction and for any associated issues patient has with the doctor; the ability or inability of the doctor, in fulfilling their obligations towards patients; any wrong medication given by the doctor, or low quality of treatment being provided to the patient or any medical negligence by the doctor; any kind of misbehavior by the doctor or his/her staff; and rescheduling or cancellation of booked appointment.
                                </p>
                                <p>
                                    Patients are allowed to provide their feedback on experiences with the doctors, however, patients should ensure that, the same is provided in accordance with the law applicable.
                                </p>

                                <h5>
                                    No practitioner-user relationship
                                </h5>
                                <p>
                                    It should be noted that any information, data, graphics, suggestions, guidance etc. that may be available on the website may be provided by medical professional. However, the provision of such information does not build a licensed medical-professional and patient relationship between the company and the end-user. Hence, does not constitute a medical advice or diagnosis or treatment of any particular condition.
                                </p>
                                <p>
                                    It should be noted that, the data that user obtain from the company website, or its sponsors, advertisers, licensors etc. is for informational purpose only. The company makes no guarantee, or warranty w.r.t. professional qualifications, work experience or other information provided on its website. The company, in any case, shall not be liable to the user or anyone else for any decision made or action taken by them on such information.
                                </p>
                                <p>
                                    In case of any medical emergency, either you contact an ambulance service or hospital directly. Our services are not considered to be a substitute for getting in touch with emergency healthcare.
                                </p>

                                <h5>
                                    Doctors’ terms
                                </h5>
                                <p>
                                    The doctor should understand that the information that is shared by the patient during consultation is personal and is subject to all applicable privacy laws, shall be confidential and subject to patient and doctor privilege.
                                </p>
                                <p>
                                    The doctor should understand that when a patient books a time-slot with him/her for consultation, he/she must comply with the time-slot to the best of their availability. The practitioner must notify patient, in case of any delay, to their best possible ability.
                                </p>
                                <p>
                                    The doctor should understand that we make no guarantee or promise for any uninterrupted communication and the doctor shall not hold us liable, if for any reason the communication is not delivered, or delivered late or not accessed, despite the efforts undertaken by the company, to the patient.
                                </p>
                                <p>
                                    It shall be the responsibility of the doctor to ensure that the details provided by the patient is complete and accurate; and understands that the company shall not be liable for any errors in the data included in any communication between the doctor and the patient.
                                </p>
                                <p>
                                    The company reserves the right to take down or to display the profile of any doctor with or without prior notice to the concerned doctor.
                                </p>
                                <p>
                                    The doctor must ensure that he/she will use our services in accordance with the law applicable. Any violation of applicable law due to any doctor’s use of our services is his/her sole responsibility, and the company will not be liable for the same.
                                </p>
                                <p>
                                    The company reserves the right to take critical content and feedback for all the doctors, hospitals, clinics and/or healthcare providers registered with us.
                                </p>
                                <p>
                                    The content of drray24.in is the copyright of GETTOOWELL Technology Pvt. Ltd.
                                </p>

                                <h5>
                                    Sponsored ads:
                                </h5>
                                <p>
                                    The company reserves the right to display any sponsored ads on its website. However, we may not be liable for any reliability of any information displayed in the Sponsored ads.
                                </p>
                                
                                <h5>
                                    Termination:
                                </h5>
                                <p>
                                    The company reserves the right to cease any user’s access to the company’s services with or without prior notice.
                                </p>
                                
                                <h5>
                                    Contact Info:
                                </h5>
                                <p>
                                    If you have any query you write us at: info@drray24.in or can reach us from the following hyperlink: <a href="http://www.drray24.in/contact.php" target="_blank" rel="noopener noreferrer">http://www.drray24.in/contact.php</a>
                                </p>
                                <p>
                                    You have read and understood the Terms and Conditions and Privacy Policy of the company. If you disagree with these conditions, we request you to not use this website. 
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default TermsConditions;