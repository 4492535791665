import React, { Component } from "react";
import styles from "./Thankyou-Register.module.scss";
import DoneIcon from '@material-ui/icons/Done';
import MoodIcon from '@material-ui/icons/Mood';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ThankYouRegister extends Component {

    componentDidMount() {
        document.title = "Thank You"
    }

    render() {
        return(
            <div className={`content ${styles.bodyBG}`}>
                <Container fluid className={`${styles.thankYouDiv} sectionSpace`}>
                    <Container>
                        <Row>
                            <Col xs={12} className={`${styles.congratsText}`}>
                                <ThumbUpOutlinedIcon />
                            </Col>
                            <Col xs={12}>
                                <h1>
                                    Thank
                                    <span><b>Y<MoodIcon />u</b></span>
                                </h1>
                            </Col>
                            <Col xs={12}>
                                <h4>
                                    <span>For the registered with us.! <DoneIcon /></span>
                                </h4>
                            </Col>
                            <Col xs={12}>
                                <p>
                                    Now you are member a drray24. Please login...
                                </p>
                            </Col>
                            <Col xs={12} className={`${styles.thankYouBtn}`}>
                                <Link to="/login" className={`my-btn px-4`}>
                                    Login with us
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default ThankYouRegister;