import React, { Component } from 'react';
import MaterialTable from 'material-table';

import { forwardRef } from 'react';
import { AddBox, Check, Clear, DeleteOutline, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn } from "@material-ui/icons";

class AfterActivityListComponent extends Component {
    render() {

        function MaterialTableDemo() {
            const [state, setState] = React.useState({
                columns: [
                    { title: 'Sr. No.', field: 'srNumber', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Doctor Name', field: 'name', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Patient Name', field: 'patientName', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Rx Date', field: 'dateTime', cellStyle: { fontSize: '.9rem' } },
                    { title: 'Action', field: 'action', cellStyle: { fontSize: '.9rem' }  },
                  ],
                  data: [
                    { srNumber: '1', name: 'Shashank Verma', patientName: 'Arvind', dateTime: '17 Feb 2020, 10:00 AM', action: 'View' },
                    { srNumber: '2', name: 'Arvind', patientName: 'Shashank', dateTime: '04 Mar 2020, 11:00 AM', action: 'View' },
                ],
            });

            const tableIcons = {
                Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
                ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
            }
          
            return (
              <MaterialTable
                title="Vitals"
                columns={state.columns}
                data={state.data}
                icons={tableIcons}
              />
            );
        }

        return(
            <MaterialTableDemo />
        );
    }
}

export default AfterActivityListComponent;