import React, {Component} from 'react';

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// return component
import ReactFusioncharts from "react-fusioncharts";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const dataSource = {
    chart: {
        caption: "Blood Glucose",
        subcaption: "",
        xaxisname: "Date",
        yaxisname: "Blood Glucose",
        numbersuffix: "",
        theme: "fusion",
        showsum: "1",
        plottooltext:
            "$label produces <b>$dataValue</b> of energy from $seriesName",
        drawcrossline: "1"
    },
    categories: [
        {
            category: [
                {
                    label: "09-07-2020"
                },
                {
                    label: "10-07-2020"
                },
                {
                    label: "11-07-2020"
                },
                {
                    label: "12-07-2020"
                },
                {
                    label: "13-07-2020"
                },
                {
                    label: "14-07-2020"
                }
            ]
        }
    ],
    dataset: [
        {
            seriesname: "Before Breakfast",
            data: [
            {
                value: "400"
            },
            {
                value: "830"
            },
            {
                value: "500"
            },
            {
                value: "420"
            },
            {
                value: "790"
            },
            {
                value: "380"
            }
            ]
        },
        {
            seriesname: "Before Lunch",
            data: [
            {
                value: "350"
            },
            {
                value: "620"
            },
            {
                value: "410"
            },
            {
                value: "370"
            },
            {
                value: "720"
            },
            {
                value: "310"
            }
            ]
        },
        {
            seriesname: "Before Dinner",
            data: [
            {
                value: "210"
            },
            {
                value: "400"
            },
            {
                value: "450"
            },
            {
                value: "180"
            },
            {
                value: "570"
            },
            {
                value: "270"
            }
            ]
        },
        {
            seriesname: "Bedtime",
            data: [
            {
                value: "180"
            },
            {
                value: "330"
            },
            {
                value: "230"
            },
            {
                value: "160"
            },
            {
                value: "440"
            },
            {
                value: "350"
            }
            ]
        }
    ]
};

class BloodGlucoseComponent extends Component{
    render() {
        return(
            <ReactFusioncharts className={`graphChart`}
                type="stackedcolumn2d"
                width="100%"
                height="400"
                dataFormat="JSON"
                dataSource={dataSource}
            />
        );
    }
}

export default BloodGlucoseComponent;