import React, { Component } from "react";
import styles from "./Test-Detail.module.scss";
import {Link} from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AlarmIcon from '@material-ui/icons/Alarm';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col, Breadcrumb, Card, Accordion, ListGroup } from 'react-bootstrap';
import TestPackageCard from '../Cards/Test-package-card/Test-package-card';
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';
import { connect } from 'react-redux';

const responsivePackagesList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3.5,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 676,
            min: 1
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 678
        },
        items: 3,
        partialVisibilityGutter: 30
    }
};

class TestDetail extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            detail: []
        }
    }

    componentDidMount() {
        var params = this.props.match.params;
        const formData = new FormData();
        formData.append('test_code', params.test_code);
        const API = Service.post('diagnostic/detail', formData);
        API.then(json => {
            this.setState({
                isLoading: true,
                detail: json
            });
        });
        document.title = "Tests | " + params.testName;
    }

    render() {
        var {isLoading, detail} = this.state;
        if(isLoading) {
            return(
                <div className="content bgGray">
                    <Container fluid className={`mt-n3`}>
                        <Container>
                            <Row>
                                <Col xs={12} className={`breadcrumbDiv`}>
                                    <Breadcrumb>
                                        <Link to="/">Home</Link>
                                        <Link to="/diagnostic">Diagnostic</Link>
                                        <Breadcrumb.Item active>Test Detail</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    
                    <Container fluid>
                        <Container>
                            <Row>
                                <Col xs={12} className={`${styles.packageDetailDiv}`}>
                                    <Card className={`${styles.packageDetailDivCard}`}>
                                        <Card.Header className={`${styles.packageDetailHeader}`}>
                                            <div className={`${styles.packageDetailContent}`}>
                                                <Col md={7} xs={12} className={`${styles.packageDetailContentLeft}`}>
                                                    <h1>
                                                        {detail.result.tests.test_name}
                                                    </h1>
                                                    { detail.result.total_parameters ? ( <h5>Includes : <span>{detail.result.total_parameters} Parameters</span></h5> ) : null }
                                                </Col>
                                                
                                                <Col md={5} xs={12} className={`${styles.packageDetailContentRight}`}>
                                                    {detail.result.tests.discount_percentage > 0 ? (
                                                        <div>
                                                            <h4>
                                                                Total Price : <del>₹{detail.result.tests.mrp}</del>
                                                            </h4>
                                                            <h3>
                                                                Our price : ₹{detail.result.tests.final_mrp}/-
                                                            </h3>
                                                        </div>
                                                    ) : (
                                                        <h3>
                                                            Total price : ₹{detail.result.tests.final_mrp}/-
                                                        </h3>
                                                    )}
                                                </Col>
                                            </div>
                                        </Card.Header>

                                        <Card.Body className={`${styles.packageDetailBody}`}>
                                            <div className={`${styles.packageDetailBodyContent}`}>
                                                <Col md={6} xs={12}>
                                                    
                                                    <p>
                                                        {detail.result.tests.short_description}
                                                    </p>
                                                </Col>

                                                <Col md={4} xs={12}>
                                                    <Card.Title className={`text-center`}>Recommended for</Card.Title>
                                                    <ListGroup horizontal className={`${styles.recommendedList}`}>
                                                        <ListGroup.Item>
                                                            <PersonIcon className={`${styles.recommendedIcon}`} /> Male
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <PersonIcon className={`${styles.recommendedIcon}`} /> Female
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <CalendarTodayIcon className={`${styles.recommendedIcon}`} /> 5-99 Years
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>

                                                <Col md={2} xs={12}>
                                                {detail.result.tests.discount_percentage > 0 ? (
                                                    <span className={`${styles.offPriceDiv}`}>Save {detail.result.tests.discount_percentage.replace(/\.00$/,'')}%</span>
                                                ) : null}
                                                </Col>
                                            </div>

                                            <ListGroup horizontal className={`${styles.packageBrief}`}>
                                                <ListGroup.Item className={`${styles.packageBriefText}`}>
                                                    <AlarmIcon className={`${styles.packageBriefIcon}`} /> Reporting Time: <b>24 Hours</b>
                                                </ListGroup.Item>
                                                <ListGroup.Item className={`${styles.packageBriefText}`}>
                                                    <LocalDiningIcon className={`${styles.packageBriefIcon}`} /> Fasting Time: <b>10 Hours</b>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card.Body>

                                        <Card.Footer className={`${styles.packageDetailFooter}`}>
                                            <Link to="/checkout">
                                                <button className={`my-btn ${styles.allPackagesBtn}`} onClick={() => this.props.addToCart(detail.result.tests)}>Book Now <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} /> </button>
                                            </Link>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                {
                                    detail.result.total_parameters ? 
                                    (<Col xs={12} className={`${styles.parameterDiv}`}>
                                        <Card className={`${styles.parameterCard}`}>
                                            <Card.Header className={`${styles.parameterCardHeader}`}>
                                                Test Details (Parameters included : {detail.result.total_parameters})
                                            </Card.Header>

                                            <Card.Body className={`${styles.parameterCardBody}`}>
                                            <ListGroup as="ul" className={`${styles.parameterSingleTest}`}>
                                                {
                                                    detail.result.test_master.map((item, i) => (
                                                        <ListGroup.Item as="li" key={i}>
                                                            <Link to="/PackageDetail">
                                                                {item.test_name}
                                                            </Link>
                                                        </ListGroup.Item>
                                                    ))
                                                }
                                            </ListGroup>
                                            {
                                                detail.result.group_test ?
                                                    (<Accordion defaultActiveKey={0} className={styles.accordionDiv}>
                                                        {
                                                            detail.result.group_test.map((item, index) => (
                                                                <Card className={styles.card}>
                                                                    <Accordion.Toggle as={Card.Header} className={styles.header} eventKey={index}>
                                                                        {item.test_name} <small>No. of parameters : {item.total_parameters}</small>
                                                                    </Accordion.Toggle>

                                                                    <Accordion.Collapse eventKey={index}>
                                                                        <Card.Body className={styles.body}>
                                                                            {
                                                                                item.parameters.map(para => (
                                                                                    <Link to={`/diagnostic/tests/${encodeURIComponent(para.test_name.replace(/ /g, "-").toLowerCase())}/${para.test_code}`} target="_blank">
                                                                                        {para.test_name}
                                                                                    </Link>
                                                                                ))
                                                                            }
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            ))
                                                        }
                                                    </Accordion>) : null 
                                            }
                                            </Card.Body>
                                            <Card.Footer className={`${styles.parameterCardFooter}`}>
                                                <Link to="/checkout">
                                                    <button className={`my-btn ${styles.allPackagesBtn}`} onClick={() => this.props.addToCart(detail.result.tests)}>Book Now <DoubleArrowIcon className={`${styles.allPackagesBtnArrow}`} /> </button>
                                                </Link>
                                            </Card.Footer>
                                        </Card>
                                    </Col> ) : null
                                }

                                <Col xs={12} className="mt-5 pt-5">

                                    <Col className="px-0 mb-5">
                                        <h3 className="customHeading">Other Health Checkup Packages</h3>
                                    </Col>

                                    <Carousel
                                    additionalTransfrom={0}
                                    arrows={true}
                                    autoPlay
                                    autoPlaySpeed={5000}
                                    centerMode={false}
                                    className={`${styles.healthPackageSlider}`}
                                    containerClass=""
                                    dotListClass=""
                                    draggable
                                    focusOnSelect={false}
                                    infinite
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={10}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside={false}
                                    responsive={responsivePackagesList}
                                    showDots={false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    >
                                        {
                                            detail.result.health_package.map((item, i) => (
                                                <Col key={i} className={styles.healthPackage}>
                                                    <TestPackageCard test={item} />
                                                </Col>
                                            ))
                                        }
                                    </Carousel>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (item) => {dispatch({type: 'ADD_TO_CART', item: item})}
    }
}

export default connect(null, mapDispatchToProps)(TestDetail);