import React, {Component} from 'react';
import styles from './Prescription-View.module.scss';
import { Row, Container, Card, Col, Table, ListGroup } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

class PrescriptionView extends Component {

    componentDidMount() {
        this.props.currentRoute('prescription-view');
        document.title = "Prescription"
    }

    componentWillUnmount() {
        this.props.currentRoute(null)
    }
    render() {
        return(
            <div className="content">

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={12} md={12} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Prescription View
                                    <span>
                                        Description
                                    </span>
                                    <Link to="/" className={`my-btn ${styles.topSaveBtn}`}>
                                        Print
                                    </Link>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`tableMainDiv`}>
                                        
                                        <Card className={`${styles.doctorDetailCard} ${styles.helpTestCard}`}>
                                            <Card.Body className={`${styles.doctorDetailCardBody}`}>
                                                <Row>
                                                    <Col md={6} xs={12} className={`${styles.doctorDetailCardBodyLeft}`}>
                                                        <Card.Title className={`${styles.doctorDetailCardTitle}`}>
                                                            Dr. Arvind Dhiman <span><b>Cardiologist</b></span>
                                                        </Card.Title>
                                                        <Card.Subtitle className={`${styles.doctorDetailCardSubtitle}`}>
                                                            Registration No: <span><b>123456789</b></span>
                                                        </Card.Subtitle>
                                                        <Card.Subtitle className={`${styles.doctorDetailCardSubtitle}`}>
                                                            Date/Time: <span><b>07 Oct 2019 , 06:00 PM</b></span>
                                                        </Card.Subtitle>
                                                    </Col>

                                                    <Col md={6} xs={12} className={`${styles.doctorDetailCardBodyRight}`}>
                                                        <div className={`${styles.doctorDetailCardBodyRightBox}`}>
                                                            <address className={`${styles.doctorDetailAddress}`}>
                                                                <b>Drray24 Clinic</b>
                                                                315, 3rd Floor, Ocus Quantum Internal Rd, Sector 51 Samaspur, Haryana, Gurgaon-122003.
                                                            </address>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card className={`${styles.patientDetailCard}`}>
                                            <Card.Body className={`${styles.patientDetailCardBody}`}>
                                                <Row className={`${styles.patientDetailCardRow}`}>
                                                    <Col md={4} xs={6} className={`${styles.patientDetailCardCol}`}>
                                                        Name : <span>abcd</span>
                                                    </Col>
                                                    <Col md={4} xs={6} className={`${styles.patientDetailCardCol}`}>
                                                        Gender : <span>Male</span>
                                                    </Col>
                                                    <Col md={4} xs={6} className={`${styles.patientDetailCardCol}`}>
                                                        Age : <span>40 yrs</span>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card className={`${styles.patientProblemCard}`}>
                                            <Card.Body className={`${styles.patientProblemCardBody}`}>
                                                <Row className={`${styles.patientProblemCardRow}`}>
                                                    <Col md={6} xs={12} className={`${styles.patientProblemCardCol}`}>
                                                        Chief Complaint : <span>Hot Flashes, Irregular Periods</span>
                                                    </Col>
                                                    <Col md={6} xs={12} className={`${styles.patientProblemCardCol}`}>
                                                        Medical History : <span>Painful Intercourse, Discharge</span>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        
                                        <Card className={`${styles.medicineDetailCard}`}>
                                            <Card.Header className={`${styles.medicineDetailCardHeader}`}>
                                                Medicine Details
                                            </Card.Header>

                                            <Card.Body className={`${styles.medicineDetailCardBody}`}>
                                                <div className={`${styles.medicineDetailCardTable}`}>
                                                    <Table responsive bordered className={`${styles.prescriptionTable}`}>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Medicine Name
                                                                </th>
                                                                <th>
                                                                    Frequency
                                                                </th>
                                                                <th>
                                                                    Day
                                                                </th>
                                                                <th>
                                                                    Medicine Comment
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    1 2 3 100MG TABLET
                                                                </td>
                                                                <td>
                                                                    Once a day(OD)
                                                                </td>
                                                                <td>
                                                                    5
                                                                </td>
                                                                <td>
                                                                    Morning
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    1 2 3 100MG TABLET
                                                                </td>
                                                                <td>
                                                                    Once a day(OD)
                                                                </td>
                                                                <td>
                                                                    5
                                                                </td>
                                                                <td>
                                                                    Morning
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Card className={`${styles.medicineDetailCard}`}>
                                            <Card.Header className={`${styles.medicineDetailCardHeader} ${styles.testNameCardHeader}`}>
                                                Test Name
                                            </Card.Header>

                                            <Card.Body className={`${styles.medicineDetailCardBody}`}>
                                                <ListGroup as="ul" className={`${styles.testNameList}`}>
                                                    <ListGroup.Item as="li" className={`${styles.testNameListItem}`}>
                                                        THYROID PROFILE TOTAL
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className={`${styles.testNameListItem}`}>
                                                        FREE THYROID PROFILE
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className={`${styles.testNameListItem}`}>
                                                        Vitamin B12
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className={`${styles.testNameListItem}`}>
                                                        THYROID PROFILE TOTAL
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className={`${styles.testNameListItem}`}>
                                                        THYROID PROFILE TOTAL
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>

                                        <Card className={`${styles.reportBottomCard}`}>
                                            <Card.Body className={`${styles.reportBottomCardBody}`}>
                                                <Row className={`${styles.reportBottomCardRow}`}>
                                                    <Col md={6} xs={12} className={`${styles.reportBottomCardCol}`}>
                                                        Note : This is a Computer generated print. No Signature required.
                                                    </Col>
                                                    <Col md={6} xs={12} className={`${styles.reportBottomCardCol}`}>
                                                        Provided By www.drray24.in
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        cartItems: state.items,
        total: state.total,
        route: state.route
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        currentRoute: (route) => {dispatch({type: 'CURRENT_ROUTE', route: route})}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionView);