import React, { Component } from "react";
import styles from './Header-Fixed.module.scss';
import { useEffect, useState } from "react";
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import DoctorSearch from '../Doctor-Search/Doctor-Search';
import ShoppingCart from '../Shopping-Cart/Shopping-Cart';
import AfterLogin from '../After-Login/After-Login';
import {Navbar, Nav, Image, Container, NavItem } from 'react-bootstrap';

class HeaderFixedComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token")
        };
    }

    render() {
        function HeaderFixed(data) {
            useEffect(() => {
              const header = document.getElementById("myHeader");
              const middle = document.getElementById("myHeaderHelp");
              const sticky = header.offsetTop;
              const scrollCallBack = window.addEventListener("scroll", () => {
                if (window.pageYOffset > sticky) {
                  header.classList.add("sticky");
                  middle.classList.add("myHeaderHelp");
                } else {
                  header.classList.remove("sticky");
                  middle.classList.remove("myHeaderHelp");
                }
              });
              return () => {
                window.removeEventListener("scroll", scrollCallBack);
              };
            }, []);
            
            const [isAddClass, setAddClass] = useState(false);
            const toggleTrueFalse = () => setAddClass(!isAddClass);
            const parmElem = useRouteMatch('/my-account/platform/:appName');
            const appName = parmElem ? parmElem.params.appName : '';
          
            return (
                <>
                <Container fluid id="myHeader" className={`${styles.fixedHeaderMain}`}>
                    <Container className={`${styles.containerSpace}`}>
            
                        <Navbar className={`${styles.nav_link}`} expand="md">
                            <Link className={`${styles.navbarBrand}`} to="/">
                                <Image src={window.location.origin + '/assets/images/logo.png'} alt="Logo" fluid  />
                            </Link>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleTrueFalse}
                            className={isAddClass ? `${styles.navbarIconBar}` : `${styles.navbarIconBar} ${styles.iconCollapsed}`}>
                                <span className={`icon-bar ${styles.iconBar} ${styles.topBar}`}></span>
                                <span className={`icon-bar ${styles.iconBar} ${styles.middleBar}`}></span>
                                <span className={`icon-bar ${styles.iconBar} ${styles.bottomBar}`}></span>
                            </Navbar.Toggle>
            
                            <Navbar.Collapse id="basic-navbar-nav" className={`${styles.navbarCollapse}`}>

                                {
                                    appName == 'app' ? null : (
                                        <Nav className={`mr-auto ${styles.nav_menu} d-none`}>
                                            <NavLink to="/doctor" activeClassName={`${styles.active}`}>Doctors <span className={`d-block`}>Book an appointment</span></NavLink>
                                            <NavLink to="/pharmacy" activeClassName={`${styles.active}`}>Pharmacy <span className={`d-block`}>Book medicine</span></NavLink>
                                            <NavLink to="/diagnostic" activeClassName={`${styles.active}`}>Diagnostic <span className={`d-block`}>Book blood test</span></NavLink>
                                        </Nav>
                                    )
                                }
                                
                                <Nav className={`${styles.nav_account} ml-auto`}>
                                    <span className={`d-none d-md-block`}>
                                        <DoctorSearch />
                                    </span>
                                        {
                                            data.userData.token ? (
                                                <span>
                                                    <AfterLogin userData={data.userData} />
                                                </span>
                                            ) : (
                                                <>
                                                <Link to="/login">Login</Link>
                                                <Link to="/register">Register</Link>
                                                </>
                                            )
                                        }
                                    
                                    <span className={`d-md-block d-none`}>
                                        <ShoppingCart />
                                    </span>
            
                                    <NavItem className={`${styles.mobile_social_icon} d-md-none d-block`}>
                                        <Nav className={`${styles.nav_account}`}>
                                            <Link to="https://www.facebook.com/Drray24lab/" className={`${styles.social_link}`}><FacebookIcon /></Link>
                                            <Link to="https://twitter.com/GETTOOWELL" className={`${styles.social_link}`}><TwitterIcon /></Link>
                                            <Link to="https://www.youtube.com/channel/UCqMz3W8ffANVD1yybiv8ArA" className={`${styles.social_link}`}><YouTubeIcon /></Link>
                                            <Link to="https://www.linkedin.com/company/gettoowell-technology-pvt-ltd" className={`${styles.social_link}`}><LinkedInIcon /></Link>
                                            <Link to="https://www.instagram.com/gettoowell/" className={`${styles.social_link}`}><InstagramIcon /></Link>
                                        </Nav>
                                    </NavItem>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Container>
                </Container>
                <span className={`d-md-none d-block`}>
                    <ShoppingCart />
                </span>
                </>
            );
        }


        return(
            <HeaderFixed userData={this.props.userData} />
        );
    }
}

export default HeaderFixedComponent;
