import React, {Component} from 'react';
import styles from './Forgot-Password.module.scss';
import { Row, Container, Col, Form } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

class ForgotPassword extends Component{

    componentDidMount() {
        document.title = "Forgot Password"
    }

    state = {
        formData: {
            mobile: '',
        },
        submitted: false,
    }

    handleChange = (event) => {
        const {formData} = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    render(){
        const { formData, submitted } = this.state;

        return(
            <Container fluid className={`${styles.loginRegisterDiv}`}>
                <Container>
                    <Row className="justify-content-center">

                    <Col lg={5} md={6} xs={12} className={`${styles.loginRegisterBox}`}>

                        <div className={`${styles.accountSection}`}>
                            <div className={`${styles.accountIcon}`}>
                                <PersonIcon />
                            </div>

                            <p>
                                Forgot Password
                            </p>
                        </div>

                        <ValidatorForm ref="form" className="customForm" onSubmit={this.handleSubmit}>
                            <div id="loginForm" className={`loginForm row`}>
                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                    <p>
                                        Provide us the mobile of your Drray24 account and we will send you an otp with instructions to reset your password.
                                    </p>
                                </Form.Row>

                                <Form.Row as={Col} xs={12}>
                                    <TextValidator label="Enter your mobile number" type="text" name="mobile" variant="outlined" onChange={(event) => this.handleChange(event)} value={this.state.mobile} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{10,10}$']} errorMessages={['This field is required', 'Enter only number.', 'Mobile number not valid']} autoComplete="off" />
                                </Form.Row>

                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection}`}>
                                    <Button id="loginFormBtn" className={`my-btn px-5 btn-block loginFormBtn`} variant="contained" type="submit" disabled={this.state.formSubmit}>Send me Instructions</Button>
                                </Form.Row>
                            </div>

                            <div id="loginFormWidOtp" className={`loginFormWidOtp w-100`}>
                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                    <p>
                                        We have sent OTP on entered mobile number and registered email id, if not received within 3 minutes please click on <span class="resend_otp">resend otp!</span>
                                    </p>
                                </Form.Row>

                                <Form.Row as={Col} xs={12}>
                                    <TextValidator label="Enter Otp" name="userOTP" variant="outlined" />
                                </Form.Row>

                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection} ${styles.OtpSection}`}>
                                    <Button id="loginFormWidOtpBtn" className={`my-btn px-5 btn-block loginFormWidOtpBtn`} variant="contained" type="submit">
                                        Verify
                                    </Button>

                                    <span className={`${styles.OtpBackBtn} mt-3`}><ChevronLeftIcon /></span>
                                </Form.Row>
                            </div>
                        </ValidatorForm>

                    </Col>

                    </Row>
                </Container>
            </Container>
        );
    }
}

export default ForgotPassword;