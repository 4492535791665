import React, { Component } from "react";
import styles from "./List-Loader.module.scss";
import ContentLoader from "react-content-loader";
import { Container, Row, Col } from 'react-bootstrap';

const FilterLeft = () => (
    <ContentLoader className={`${styles.FilterLeftLoader}`}
        speed={.5}
        height={300}
        backgroundColor="#e3e3e3"
        foregroundColor="#fcfbfb"
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="42" />

        <circle cx="5" cy="92" r="5" />
        <rect x="25" y="90" rx="0" ry="0" width="80%" height="5" />
        <circle cx="5" cy="122" r="5" />
        <rect x="25" y="120" rx="0" ry="0" width="60%" height="5" />
        <circle cx="5" cy="152" r="5" />
        <rect x="25" y="150" rx="0" ry="0" width="70%" height="5" />
        <circle cx="5" cy="182" r="5" />
        <rect x="25" y="180" rx="0" ry="0" width="60%" height="5" />
        <circle cx="5" cy="212" r="5" />
        <rect x="25" y="210" rx="0" ry="0" width="60%" height="5" />
        <circle cx="5" cy="242" r="5" />
        <rect x="25" y="240" rx="0" ry="0" width="60%" height="5" />
        <circle cx="5" cy="272" r="5" />
        <rect x="25" y="270" rx="0" ry="0" width="60%" height="5" />
      </ContentLoader>
)

const FilterTopLeft = () => (
    <ContentLoader 
      speed={.5}
      height={50}
      backgroundColor="#e3e3e3"
      foregroundColor="#fcfbfb"
    >
      <rect x="4" y="0" rx="3" ry="3" width="2" height="45" />
  
      <rect x="20" y="10" rx="3" ry="3" width="35%" height="6" />
      <rect x="20" y="30" rx="3" ry="3" width="58%" height="6" />
    </ContentLoader>
)

const FilterTopRight = () => (
    <ContentLoader 
      rtl
      speed={.5}
      height={50}
      backgroundColor="#e3e3e3"
      foregroundColor="#fcfbfb"
    >
      <rect x="0" y="10" rx="3" ry="3" width="25%" height="35" /> 
    </ContentLoader>
)

const ListLoaderDiv = () => (
  <ContentLoader 
    speed={.5}
    height={160}
    backgroundColor="#e3e3e3"
    foregroundColor="#fcfbfb"
  >
    <rect x="4" y="8" rx="3" ry="3" width="2" height="160" />
    <rect x="4" y="158" rx="3" ry="3" width="100%" height="2" />
    <rect x="365" y="9" rx="3" ry="3" width="2" height="160" />
    <rect x="5" y="8" rx="3" ry="3" width="100%" height="2" />

    <rect x="68" y="26" rx="3" ry="3" width="35%" height="6" />
    <rect x="68" y="45" rx="3" ry="3" width="70%" height="6" />
    <rect x="68" y="60" rx="3" ry="3" width="50%" height="6" />
    <rect x="20" y="110" rx="3" ry="3" width="30%" height="6" />
    <rect x="20" y="130" rx="3" ry="3" width="20%" height="6" />
    <rect x="250" y="110" rx="3" ry="3" width="25%" height="35" />
    <circle cx="40" cy="40" r="20" />
  </ContentLoader>
)

class ListLoader extends Component{
    render(){
        return(
            <div className={`content bgGray`}>
            <Container fluid>
                <Container>
                    <Row className={`${styles.loaderMainDiv}`}>
                        <Col lg={3} md={4} xs={12}>
                            <FilterLeft />
                        </Col>

                        <Col lg={9} md={8} xs={12}>
                            <Col className={`${styles.filterTop}`}>
                                <FilterTopLeft />
                                <FilterTopRight />
                            </Col>

                            <Row className={`${styles.listingRow}`}>
                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>

                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>
                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>

                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>
                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>

                                <Col md={6} xs={12}>
                                    <Col className={`${styles.singleListLoaderDiv}`}>
                                        <ListLoaderDiv />
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            </div>
        );
    }
}

export default ListLoader