import React, {Component} from 'react';
import styles from './Doctor-Specialist.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col } from 'react-bootstrap';
import DoctorCard from '../../Card-View/Doctor-Card/Doctor-Card';

const responsiveItemList = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

class DoctorSpecialistComponent extends Component{
    render() {
        return(
            <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className={`customSliderDiv ${styles.meetSpecialistSlider} ${styles.helpSpecialistSlider}`}
            containerClass="container-with-dots"
            dotListClass="custom-dot-list-style"
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsiveItemList}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            >
                <Col>
                    <DoctorCard />
                </Col>
                
                <Col>
                    <DoctorCard />
                </Col>

                <Col>
                    <DoctorCard />
                </Col>
                
                <Col>
                    <DoctorCard />
                </Col>
                
                <Col>
                    <DoctorCard />
                </Col>
            </Carousel>
        );
    }
}

export default DoctorSpecialistComponent;