import React, { Component } from "react";
import styles from './Appoitnment-Date-Time.module.scss';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { Col, ListGroup } from 'react-bootstrap';
import Service from '../../../services/Service';

class AppoitnmentDateTime extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            slots: this.props.slots,
            clinicId: this.props.clinicId,
            morningSlots: 0,
            afternoonSlots: 0,
            eveningSlots: 0,
            currentSlide: ''
        }
        this.getSlots = this.getSlots.bind(this);
    }

    componentDidMount() {
        var morningSlots = 0;
        var afternoonSlots = 0;
        var eveningSlots = 0;
        if(this.state.slots.morning) {
            this.state.slots.morning.map((si) => {
                if(!si.status) {
                    morningSlots++;
                }
            })
        }
        if(this.state.slots.afternoon) {
            this.state.slots.afternoon.map((si) => {
                if(!si.status) {
                    afternoonSlots++;
                }
            })
        }
        if(this.state.slots.evening) {
            this.state.slots.evening.map((si) => {
                if(!si.status) {
                    eveningSlots++;
                }
            })
        }
        this.setState({
            morningSlots: morningSlots,
            afternoonSlots: afternoonSlots,
            eveningSlots: eveningSlots
        });
    }

    getSlots(d, i) {
        this.setState({isLoading: true});
        var date = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
        const formData = new FormData();
        formData.append('clinic_id', this.state.clinicId);
        formData.append('date', date);
        const API = Service.post('doctor/getSlots', formData);
        var morningSlots = 0;
        var afternoonSlots = 0;
        var eveningSlots = 0;
        API.then(json => {
            if(json.result.morning) {
                json.result.morning.map((si) => {
                    if(!si.status) {
                        morningSlots++;
                    }
                })
            }
            if(json.result.afternoon) {
                json.result.afternoon.map((si) => {
                    if(!si.status) {
                        afternoonSlots++;
                    }
                })
            }
            if(json.result.evening) {
                json.result.evening.map((si) => {
                    if(!si.status) {
                        eveningSlots++;
                    }
                })
            }
            this.setState({
                    isLoading: false,
                    slots: json.result,
                    currentSlide: i,
                    morningSlots: morningSlots,
                    afternoonSlots: afternoonSlots,
                    eveningSlots: eveningSlots
                });

        });
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true
        };

        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let today = new Date();
        var newdate = new Date();
        newdate.setDate(today.getDate()+15);
        var dateArray = new Array();
        let i = 0;
        while (today <= newdate) {
            if(i == 0) {
                dateArray.push({fullDate: new Date(today), active: true, day: today.getDay(), date: today.getDate(), month: today.getMonth()});
            }
            else {
                dateArray.push({fullDate: new Date(today), day: today.getDay(), date: today.getDate(), month: today.getMonth()});
            }
            today.setDate(today.getDate() + 1);
            i++;
        }

        function slotTime(slot) {
            var dateTime = new Date(slot);
            var hours = dateTime.getHours();
            var minutes = dateTime.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function strToTime(slot) {
            var dateTime = new Date(slot);
            return dateTime.getTime();
        }

        function AppSlots(data) {
            return (
                <Col sm={10} xs={10} className={`${styles.dateTimeSlotContent}`}>
                    {
                        data.slots.length == 0 ? (
                            <>
                                <h1 className={`${styles.sooryText} text-center mt-4`}>S<SentimentVeryDissatisfiedIcon />RRY!!</h1>
                                <p className={`text-center`}>The Doctor is unavailable today</p>
                                <p className={`text-center`}>Please book appointment to another day.</p>
                            </>
                        ) : (
                            <>
                                {
                                    data.slots.morning ? (
                                        <>
                                            <div className={`${styles.dateTimeSlotContentHeading}`}>
                                                Morning <span>{data.morningSlots} slots available</span>
                                            </div>
                                            <ListGroup as="ul" className={`${styles.timeSlotsList}`} horizontal>
                                                {
                                                    data.slots.morning.map(item => (
                                                        item.status ? (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem} ${styles.disabled}`} disabled="disabled">
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>) : (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem}`}>
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>)
                                                    ))
                                                }
                                            </ListGroup>
                                        </>
                                    ) : null
                                }
                                {
                                    data.slots.afternoon ? (
                                        <>
                                            <div className={`${styles.dateTimeSlotContentHeading}`}>
                                                Afternoon <span>{data.afternoonSlots} slots available</span>
                                            </div>
                                            <ListGroup as="ul" className={`${styles.timeSlotsList}`} horizontal>
                                                {
                                                    data.slots.afternoon.map(item => (
                                                        item.status ? (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem} ${styles.disabled}`} disabled="disabled">
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>) : (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem}`}>
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>)
                                                    ))
                                                }
                                            </ListGroup>
                                        </>
                                    ) : null
                                }
                                {
                                    data.slots.evening ? (
                                        <>
                                            <div className={`${styles.dateTimeSlotContentHeading}`}>
                                                Evening <span>{data.eveningSlots} slots available</span>
                                            </div>
                                            <ListGroup as="ul" className={`${styles.timeSlotsList}`} horizontal>
                                                {
                                                    data.slots.evening.map(item => (
                                                        item.status ? (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem} ${styles.disabled}`} disabled="disabled">
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>) : (<ListGroup.Item as="li" active className={`${styles.timeSlotsListItem}`}>
                                                            <Link to={`/appointment/${strToTime(item.time)}/${data.clinicId}`}>{slotTime(item.time)}</Link>
                                                        </ListGroup.Item>)
                                                    ))
                                                }
                                            </ListGroup>
                                        </>
                                    ) : null
                                }
                            </>
                        )
                    }
                </Col>
            );
        }

        return(
            <div className={`${styles.AppoitnmentBookSlots}`}>
                <Col sm={2} xs={2} className={`${styles.dateTimeSlots} customeSlotsDiv`}>
                    <Slider {...settings}>
                        {
                            dateArray.map((item, i) => (
                                <div className={`${styles.dateTimeSlotsDiv}`}>
                                    <h3 key={i} onClick={() => this.getSlots(item.fullDate, i)} className={`${styles.slotsDateText} ${i==this.state.currentSlide ? styles.slotsDateActive : null}`}>{dayNames[item.day]+' '+item.date+' '+monthNames[item.month]}</h3>
                                </div>
                            ))
                        }
                    </Slider>
                </Col>
                {
                    this.state.isLoading ? (<div className={`sweet-loading customLoader col-sm-10 col-xs-9`}>
                        <DotLoader
                            color={'#ef3c67'}
                            loading={this.state.loading}  
                        />
                    </div>) : (<AppSlots slots={this.state.slots} clinicId={this.state.clinicId} morningSlots={this.state.morningSlots} afternoonSlots={this.state.afternoonSlots} eveningSlots={this.state.eveningSlots}/>)
                }
            </div>
        );
    }
}

export default AppoitnmentDateTime;