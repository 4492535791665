import React, { Component } from "react";
import styles from './Photos.module.scss';
import { SRLWrapper } from "simple-react-lightbox";
import { ListGroup } from 'react-bootstrap';

class PhotoGallery extends Component {
    render() {
        return(
            <div className="MyComponent">
                <SRLWrapper>
                    <ListGroup horizontal className={`${styles.photoListLightBox}`}>
                        {
                            this.props.images.map(item => (
                                <ListGroup.Item className={`${styles.photoListLightBoxItem}`}>
                                    <a href={"https://stage.drray24.in/assets/images/co/doctor/clinic/" + item.image_name} data-attribute="SRL">
                                        <img height="50" src={"https://stage.drray24.in/assets/images/co/doctor/clinic/thumb/" + item.image_name} alt="Clinic 1" />
                                    </a>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </SRLWrapper>
                </div>
        )
    }
}

export default PhotoGallery;