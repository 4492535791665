import React, {Component} from 'react';
import styles from './Login.module.scss';
import { Row, Container, Col, Form } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Service from '../../services/Service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';

// Random component
const Completionist = () => <span className={`${styles.resend_otp}`}>Resend OTP!</span>;

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <h6>
        Resend OTP in {minutes}:{seconds}
      </h6>
    );
  }
};

class LoginComponent extends Component {

    componentDidMount() {
        document.title = "Login"
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            numInputs: 6,
            isDisabled: false,
            hasErrored: false,
            separator: '-',
            isInputNum: true,
            focusStyle: true,
            mobile: '',
            password: '',
            formSubmit: false,
            otpForm: false,
            vertical: 'top',
            horizontal: 'center',
            notFound: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOtpForm = this.handleOtpForm.bind(this);
        this.loginWithOtp = this.loginWithOtp.bind(this);
        if(this.props.userData.token){
            this.props.history.push('/my-account');
        }
    }

    loginWithOtp(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('mobile', this.state.mobile);
        const API = Service.post('login/loginWithOtp', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if (json.status == 200) {
                this.setState({otpForm: true});
            } else {
                alert("Something went wrong!");
            }
        });
    }

    handleOTPSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('mobile', this.state.mobile);
        formData.append('otp', this.state.otp);
        formData.append('type', 2);
        const API = Service.post('register/otp', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if (json.status === 200) {
                if(json.result === 1) {
                    var userData = {
                        mobile: this.state.mobile,
                        user_id: json.user_data['id'],
                        f_name: json.user_data['f_name'],
                        l_name: json.user_data['l_name'],
                        profile_pic: json.user_data['profile_pic'],
                        last_login: json.user_data['last_login'],
                        token: json.user_data['token']
                    };
                    this.props.login(userData);
                    this.props.history.push('/my-account');
                } else {
                    alert("Wrong OTP!");
                }
            } else if(json.status === 400) {
                this.setState({otpMatch: false});
                if(json.validation_errors.mobile) {
                    alert(json.validation_errors.mobile);
                } else {
                    alert("Something went wrong!");
                }
            } else {
                this.setState({otpMatch: false});
                alert("Something went wrong!");
            }
        });
    }
    
    handleOtpForm(){
        this.setState(state => ({
            otpForm : !state.otpForm
        }));
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('mobile', this.state.mobile);
        formData.append('password', this.state.password);
        const API = Service.post('login', formData);
        API.then(json => {
            this.setState({formSubmit: false});
            if (json.status == 200) {
                if (json.login_status == 1) {
                    var userData = {
                        mobile: this.state.mobile,
                        user_id: json.user_data['id'],
                        f_name: json.user_data['f_name'],
                        l_name: json.user_data['l_name'],
                        profile_pic: json.user_data['profile_pic'],
                        last_login: json.user_data['last_login'],
                        token: json.user_data['token']
                    };
                    this.props.login(userData);
                    this.props.history.push('/my-account');
                } else if (json.login_status == 5) {
                    this.setState({notFound: true});
                }
            } else {
                alert("Something went wrong!");
            }
        });
    }
    
    handleOtpChange = otp => {
        this.setState({ otp });
    };

    render() {
        const { mobile, vertical, horizontal, notFound, otp, numInputs, isDisabled, hasErrored, separator, isInputNum, focusStyle } = this.state;

        function CheckboxLabels() {
            const [state, setState] = React.useState({
                checkedA: true,
              });
            
              const handleChange = (event) => {
                setState({ ...state, [event.target.name]: event.target.checked });
              };

              return(
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" />}
                        label="Remember me for 30 days"
                    />
                </FormGroup>
              );
        }

        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({notFound: false});
            
        };


        const otpFormToggle = this.state.otpForm;

        return(
            <div className={`content`}>
                
                <Container fluid className={`${styles.loginRegisterDiv}`}>
                    <Container>
                        <Row className="justify-content-center">

                            <Col lg={4} md={5} xs={12} className={`${styles.loginRegisterBox}`}>
                                <div className={`${styles.accountSection}`}>
                                    <div className={`${styles.accountIcon}`}>
                                        <PersonIcon />
                                    </div>

                                    <p>Login to drray24 account</p>
                                </div>
                                <Snackbar anchorOrigin={{ vertical, horizontal }} open={notFound} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="error">The Mobile Number or Password does not match!</Alert>
                                </Snackbar>
                                <ValidatorForm ref="form" className="customForm" onSubmit={this.handleSubmit}>
                                    {
                                        !otpFormToggle ? (
                                            <div id="loginForm" className={`loginForm row ${styles.mobileMargin0}`}>
                                                <Form.Row as={Col} xs={12}>
                                                    <TextValidator label="Enter your mobile number" type="text" name="mobile" variant="outlined" onChange={(event) => this.handleChange(event)} value={this.state.mobile} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{10,10}$']} errorMessages={['This field is required', 'Enter only number.', 'Mobile number not valid']} autoComplete="off" />
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12}>
                                                    <TextValidator label="Enter password" type="password" name="password" variant="outlined" value={this.state.password} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} />
                                                </Form.Row>
                                                
                                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection}`}>
                                                    <Link className={`mt-3 d-none`} to="/forgot-password">Forgot Password?</Link>
                                                    {
                                                        mobile.length == 10 ? (
                                                            <Button className={`my-btn ${styles.requestOtpBtn}`} variant="contained" type="Button" onClick={this.loginWithOtp}>Request With OTP</Button>
                                                        ) : null
                                                    }
                                                    <Button id="loginFormBtn" className={`my-btn px-5`} variant="contained" type="submit" disabled={this.state.formSubmit}>Login</Button>
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12} className={`${styles.haveAccount}`}>
                                                    <p>
                                                        Don't have an account? <Link to="/register">Register Now</Link>
                                                    </p>
                                                </Form.Row>
                                            </div>
                                        ) : (
                                            <div id="loginFormWidOtp" className={`loginFormWidOtp row ${styles.mobileMargin0}`}>
                                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                                    <p>
                                                        Verify with OTP
                                                    </p>
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12}>
                                                    <div className={`${styles.phoneNumberEdit}`}>
                                                        {mobile} <EditIcon className={`${styles.phoneNumberEditIcon}`} onClick={this.handleOtpForm} />
                                                    </div>
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12} className={`${styles.loginOtpFiled}`}>
                                                    <OtpInput
                                                        inputStyle={{
                                                            width: '2.5rem',
                                                            height: '2.5rem',
                                                            margin: '0.5rem',
                                                            fontSize: '1.8rem',
                                                            borderRadius: 4,
                                                            border: '1px solid rgba(0,0,0,0.3)',
                                                        }}
                                                        numInputs={numInputs}
                                                        name="otp"
                                                        value={this.state.otp}
                                                        isDisabled={isDisabled}
                                                        hasErrored={hasErrored}
                                                        errorStyle="error"
                                                        onChange={this.handleOtpChange}
                                                        separator={<span>{separator}</span>}
                                                        isInputNum={isInputNum}
                                                        focusStyle= {focusStyle}
                                                    />
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12} className={`${styles.loginFormWidOtpText}`}>
                                                    <Countdown date={Date.now() + 5000} renderer={renderer} />
                                                </Form.Row>

                                                <Form.Row as={Col} xs={12} className={`${styles.forgotSection} ${styles.OtpSection}`}>
                                                    <Button id="loginFormWidOtpBtn" className={`my-btn px-5 btn-block loginFormWidOtpBtn`} variant="contained" type="submit">
                                                        Submit
                                                    </Button>

                                                    <span className={`${styles.OtpBackBtn} mt-3`} onClick={this.handleOtpForm}><ChevronLeftIcon /></span>
                                                </Form.Row>
                                            </div>
                                        )
                                    }
                                </ValidatorForm>

                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        userData: state.userAuth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userData) => {dispatch({type: 'LOGIN', userData: userData})},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);