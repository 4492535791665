import React, {Component} from 'react';
import styles from './Graph-View.module.scss';
import { Row, Container, Card, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import UserProfileHeader from '../../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../../User-Profile-Sidebar/User-Profile-Sidebar';
import GraphTabs from './Graph-Tabs/Graph-Tabs';

class GraphViewComponent extends Component {

    componentDidMount() {
        document.title = "Graphs"
    }

    render() {
        return(
            <div className="content">
                
                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <h5>
                                    Graph
                                    <span>
                                        Details of activity
                                    </span>
                                    <Link to="/profile" className={`my-btn ${styles.topSaveBtn}`}>
                                        <ArrowBackIosIcon />Back
                                    </Link>
                                </h5>

                                <Row className={`mt-5`}>

                                    <Col xs={12} className={`${styles.profileSectionDiv}`}>
                                        <Card className={`${styles.profileSectionCard} ${styles.helpCard}`}>
                                            <Card.Title className={`${styles.profileSectionCardTitle}`}>Your Body Activity</Card.Title>

                                            <GraphTabs />
                                        </Card>
                                    </Col>
                                    
                                </Row>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default GraphViewComponent;