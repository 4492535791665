import React, { Component } from "react";
import styles from './Doctor-Card.module.scss';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Image, CardGroup, Card, Nav } from 'react-bootstrap';
import {Link} from 'react-router-dom';

class DoctorCard extends Component {
    render() {
        return(
            <CardGroup className={styles.meetSpecialist}>
                <Card className={styles.card}>
                    <Link to="/doctor-details" className={styles.cardLinkImg}>
                        <Image className="mx-auto" src={window.location.origin + "/assets/images/home/doctors/image_1.jpg"} fluid alt="Book An Appointment" />
                    </Link>
                    <Card.Body className={styles.body}>
                        <Nav className={styles.nav}>
                            <Nav.Item className={styles.navItem}>
                                <Link to="/" className={`${styles.facebook} d-inline-block ${styles.navLink}`}><FacebookIcon className={styles.socialIcon} /></Link>
                            </Nav.Item>
                            <Nav.Item className={styles.navItem}>
                                <Link to="/" className={`${styles.twitter} d-inline-block ${styles.navLink}`}><TwitterIcon className={styles.socialIcon} /></Link>
                            </Nav.Item>
                            <Nav.Item className={styles.navItem}>
                                <Link to="/" className={`${styles.linkedin} d-inline-block ${styles.navLink}`}><LinkedInIcon className={styles.socialIcon} /></Link>
                            </Nav.Item>
                            <Nav.Item className={styles.navItem}>
                                <Link to="/" className={`${styles.youtube} d-inline-block ${styles.navLink}`}><YouTubeIcon className={styles.socialIcon} /></Link>
                            </Nav.Item>
                        </Nav>
                        <Link to="/doctor-details" className={styles.cardLink}>
                            Dr. Name
                        </Link>
                        <Card.Text className={styles.cardText}>
                            Designation
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        );
    }
}

export default DoctorCard;