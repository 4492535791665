import React, { Component, useState } from "react";
import styles from "./Checkout.module.scss";
import {Link} from 'react-router-dom';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import { Button } from '@material-ui/core';
import { TextField, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DeliveryMode from "./Checkout.deliveryMode";
import OtpGenerate from './Otp-Generate/OtpGenerate';
import { Container, Navbar, Nav, Figure, Image, Row, Col, Badge, Card, Accordion, ListGroup, ListGroupItem, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import AuthService from '../../services/Auth-Service';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import { DotLoader } from 'react-spinners';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
import HttpsIcon from '@material-ui/icons/Https';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withRouter } from "react-router";

const timeslot = [
    {
        value: '06 AM - 07 AM',
        label: '06 AM - 07 AM',
    },
    {
        value: '07 AM - 08 AM',
        label: '07 AM - 08 AM',
    },
    {
        value: '08 AM - 09 AM',
        label: '08 AM - 09 AM',
    },
    {
        value: '09 AM - 10 AM',
        label: '09 AM - 10 AM',
    },
    {
        value: '10 AM - 11 AM',
        label: '10 AM - 11 AM',
    },
    {
        value: '11 AM - 12 PM',
        label: '11 AM - 12 PM',
    },
    {
        value: '12 PM - 01 PM',
        label: '12 PM - 01 PM',
    },
    {
        value: '01 PM - 02 PM',
        label: '01 PM - 02 PM',
    },
    {
        value: '02 PM - 03 PM',
        label: '02 PM - 03 PM',
    },
    {
        value: '03 PM - 04 PM',
        label: '03 PM - 04 PM',
    },
    {
        value: '04 PM - 05 PM',
        label: '04 PM - 05 PM',
    },
    {
        value: '05 PM - 06 PM',
        label: '05 PM - 06 PM',
    },
    {
        value: '06 PM - 07 PM',
        label: '06 PM - 07 PM',
    },
    {
        value: '07 PM - 08 PM',
        label: '07 PM - 08 PM',
    },
    {
        value: '08 PM - 09 PM',
        label: '08 PM - 09 PM',
    }
];

class Checkout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            states: [],
            city: [],
            f_name: null,
            l_name: null,
            dob: new Date(),
            gender: "1",
            genderName: "Male",
            mobile: null,
            confirmMobile: null,
            email: null,
            state_id: null,
            city_id: null,
            locality: null,
            pincode: null,
            address: null,
            landmark: null,
            addressType: "1",
            sampleCollectionDate: new Date(),
            sampleCollectionTime: null,
            placeOrder: false,
            saveAddress: false,
            addClass: false,
            showHideClass: false,
            accor: "0",
            addresses: [],
            deliveryAddress: null,
            update: false,
            updateAddressId: null,
            otpPopup: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePatient = this.handlePatient.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCity = this.getCity.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        const API = AuthService.get('diagnostic_checkout');
        API.then(res => {
            this.setState({
                isLoading: true,
                states: res['states'],
                addresses: res['address'],
                deliveryAddress: res['default_address']
            });
        });
        document.title = "Checkout"
    }

    handleMobile = (status) => {
        if(status.confirm) {
            this.setState({
                accor: "1",
                confirmMobile: this.state.mobile,
                otpPopup: false
            });
        } else {
            this.setState({
                otpPopup: status.popup
            });
        }
    }

    handleDeliver(add) {
        this.setState ({
            deliveryAddress: add
        })
    }

    handleEdit() {
        this.setState ({
            showHideClass: !this.state.showHideClass
        })
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
        if(name === 'gender') {
            if(value == 1)
                this.setState({genderName: 'Male'});
            else if(value == 2)
                this.setState({genderName: 'Female'});
            else
                this.setState({genderName: 'Other'});
        }
    }

    handleDateChange(date, name) {
        this.setState({[name]: date});
    }

    getCity(event, id='') {
        let stateId = id ? id : event.target.value;
        const formData = new FormData();
        formData.append('state_id', stateId);
        const API = AuthService.post('diagnostic_checkout/city', formData);
        API.then(json => {
            this.setState({
                state_id: stateId,
                city: json
            });
        });
    }

    dateConvert(str) {
        const date = new Date(str),
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }

    accordionPrevious() {
        var pre = this.state.accor - 1;
        this.setState({accor: pre.toString()});
    }

    handlePatient(e){
        if(this.state.confirmMobile == this.state.mobile) {
            this.setState({accor: "1"});
        } else {
            const formData = new FormData();
            formData.append('mobile', this.state.mobile);
            const API = AuthService.post('diagnostic_checkout/otp', formData);
            API.then(res => {
                if(res.otp == 'success') {
                    this.setState({otpPopup: true});
                }  else {
                    alert("Something went wrong!");
                }
            })
        }
    }

    handleAddress(e){
        e.preventDefault();
        this.setState({saveAddress: true});
        var apiUrl = this.state.update ? 'diagnostic_checkout/update_address' : 'diagnostic_checkout/address';
        const formData = new FormData();
        formData.append('id', this.state.updateAddressId);
        formData.append('state', this.state.state_id);
        formData.append('city', this.state.city_id);
        formData.append('locality', this.state.locality);
        formData.append('pincode', this.state.pincode);
        formData.append('address', this.state.address);
        formData.append('landmark', this.state.landmark);     
        formData.append('lable', this.state.addressType);
        const API = AuthService.post(apiUrl, formData);
        API.then(json => {
            this.setState({saveAddress: false});
            if(json.status === 200) {
                const API = AuthService.get('diagnostic_checkout');
                API.then(res => {
                    this.setState({
                        addresses: res['address'],
                        state_id: '',
                        city_id: '',
                        locality: '',
                        pincode: '',
                        address: '',
                        landmark: '',
                        addressType: "1",
                        deliveryAddress: json.delevery_address,
                        showHideClass: false,
                        update: false
                    });
                });
                this.setState({accor: "2"});
            } else {
                alert("Something went wrong!");
            }
        });
    }

    editAddress(add) {
        this.setState ({
            state_id: add.state_id,
            city_id: add.city_id,
            locality: add.locality,
            pincode: add.pincode,
            address: add.address,
            landmark: add.landmark,
            addressType: add.lable,
            showHideClass: !this.state.showHideClass,
            update: true,
            updateAddressId: add.id
        });
        this.getCity('', add.state_id);
    }

    cancelAddress() {
        this.setState ({
            state_id: '',
            city_id: '',
            locality: '',
            pincode: '',
            address: '',
            landmark: '',
            showHideClass: !this.state.showHideClass,
            update: false
        });
    }

    setAddress() {
        this.setState({accor: "2"});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({placeOrder: true});
        const formData = new FormData();
        formData.append('f_name', this.state.f_name);
        formData.append('l_name', this.state.l_name);
        formData.append('dob', this.dateConvert(this.state.dob));
        formData.append('gender', this.state.gender);
        formData.append('mobile', this.state.mobile);
        formData.append('email', this.state.email ? this.state.email : '');
        formData.append('address_id', this.state.deliveryAddress.id);
        formData.append('sampleCollectionDate', this.dateConvert(this.state.sampleCollectionDate));
        formData.append('sampleCollectionTime', this.state.sampleCollectionTime);
        formData.append('tests', JSON.stringify(this.props.cartItems));
        const API = AuthService.post('diagnostic_checkout', formData);
        API.then(json => {
            this.setState({placeOrder: false});
            if(json.status === 200) {
                localStorage.removeItem("diagnosticCart");
                this.props.checkout();
                this.props.history.push('/thank-you');
            } else {
                alert("Something went wrong!");
            }
        });
    }

    render() {
        return(
            <div className={`content ${styles.bodyBG} ${styles.mainContainer} ${styles.helpDiv}`}>
                <Container fluid className={`${styles.anotherHeader}`}>
                    <Container>
                        <Row>
                            <Navbar expand="md" className={`col-12 ${styles.anotherHeaderMain}`}>
                                <Link className={`${styles.anotherHeaderLogo}`} to="/">
                                    <Image src={window.location.origin + '/assets/images/logo.png'} alt="Logo" fluid  />
                                </Link>
                                <Nav className={`ml-auto ${styles.anotherHeaderLink}`}>
                                    <span className={`${styles.securityText}`}>
                                        <HttpsIcon /> Secure
                                    </span>
                                    <span className={`${styles.securityText}`}>
                                        <VerifiedUserIcon /> Verified
                                    </span>
                                </Nav>
                                <Nav className={`ml-auto ${styles.anotherHeaderLink} d-none`}>
                                    <Link to="/login">Login</Link>
                                    <Link to="/signup">Signup</Link>
                                </Nav>
                            </Navbar>
                        </Row>
                    </Container>
                </Container>
                
                <Container fluid>
                    <Container>
                        <Row>
                            <Col lg={8} md={7} xs={12} className={`order-md-1 order-2`}>
                                {
                                    this.props.cartItems.length > 0 ? (
                                        <>
                                        {
                                            this.state.isLoading ? (
                                                <Accordion defaultActiveKey="0" activeKey={this.state.accor} className={`${styles.accordionDiv}`}>
                                                    <Card className={`${styles.card}`}>
                                                        <div as={Card.Header} className={`${styles.header}`}>
                                                            <Badge>1</Badge> Patient Details <small>{this.state.f_name} {this.state.l_name} {this.state.mobile ? '| '+this.state.mobile : null} {this.state.genderName ? '| '+this.state.genderName : null} {this.state.email ? '| '+this.state.email : null}</small>
                                                        </div>

                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body className={`${styles.body}`}>
                                                                <ValidatorForm ref="form" className={`customForm row`} onSubmit={this.handlePatient}>
                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <TextValidator required label="First Name" name="f_name" autoComplete="off" onChange={(event) => this.handleChange(event)} value={this.state.f_name}  helperText="First Name (Report will be generated with this name)" validators={['required']} errorMessages={['This field is required']} variant="outlined" />
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <TextValidator label="Last Name" name="l_name" autoComplete="nope" value={this.state.value} onChange={(event) => this.handleChange(event)} helperText="" variant="outlined" />
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker
                                                                                name="sampleCollectionDate"
                                                                                margin="normal"
                                                                                id="date-picker-dialog"
                                                                                label="Select date of birth"
                                                                                format="dd/MM/yyyy"
                                                                                value={this.state.dob}
                                                                                onChange={(date, name) => this.handleDateChange(date, 'dob')}
                                                                                KeyboardButtonProps={{
                                                                                'aria-label': 'change date',
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <FormControl component="fieldset" className={`customRadio`}>
                                                                            <FormLabel component="legend">Gender <sup>*</sup></FormLabel>
                                                                            <RadioGroup aria-label="gender" name="gender" value={this.state.gender} onChange={(event) => this.handleChange(event)}>
                                                                                <FormControlLabel value="1" control={<Radio />} label="Male" />
                                                                                <FormControlLabel value="2" control={<Radio />} label="Female" />
                                                                                <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <TextValidator required label="Enter Patient Number" name="mobile" value={this.state.mobile} autoComplete="nope" onChange={(event) => this.handleChange(event)} helperText="Patient's Number (OTP will be sent to this number)" validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{10,10}$']} errorMessages={['This field is required', 'Enter only number.', 'Mobile number not valid']} variant="outlined" />
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                        <TextValidator label="Enter Patient Email" name="email" value={this.state.email} autoComplete="nope" onChange={(event) => this.handleChange(event)} type="email" helperText="Patient's Email (Your report will be sent to this email)" validators={['isEmail']} errorMessages={['Email is not valid']} variant="outlined" />
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} xs={12}>
                                                                        {
                                                                            this.state.otpPopup ? (
                                                                                <OtpGenerate mobile={this.state.mobile} confilrmMobile={this.handleMobile} />
                                                                            ) : null
                                                                        }
                                                                    </Form.Row>

                                                                    <Form.Row as={Col} xs={12}>
                                                                        <Button className="my-btn my-btn-second mx-auto px-5" type="submit" variant="contained" formNoValidate>Next</Button>
                                                                    </Form.Row>
                                                                </ValidatorForm>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className={`${styles.card}`}>
                                                        <div as={Card.Header} className={`${styles.header}`}>
                                                            <Badge>2</Badge> Patient Address 
                                                            {
                                                                this.state.deliveryAddress ? (
                                                                    <small>{this.state.deliveryAddress.address}, {this.state.deliveryAddress.landmark}, {this.state.deliveryAddress.locality}, {this.state.deliveryAddress.city_name}, {this.state.deliveryAddress.state_name}, {this.state.deliveryAddress.pincode}</small>
                                                                ) : null
                                                            }
                                                        </div>

                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body className={`${styles.body}`}>
                                                                {
                                                                    this.state.addresses.length > 0 ? (
                                                                        <>
                                                                        <div className={this.state.showHideClass ? `${styles.formAddressRow} ${styles.hide}` : `${styles.formAddressRow}`}>
                                                                            {this.state.addresses.map(add => (
                                                                                <Form.Row as={Col} lg={6} md={12} xs={12} className={`${styles.addressRow}`}>
                                                                                    <Col className={this.state.deliveryAddress.id == add.id ? `${styles.addressRowCol} ${styles.helpDiv} ${styles.active}` : `${styles.addressRowCol} ${styles.helpDiv}`}>
                                                                                        <h6><CheckBoxSharpIcon /></h6>
                                                                                        <p>
                                                                                            {add.address}, {add.landmark}, {add.locality}, {add.city_name}, {add.state_name}, {add.pincode}
                                                                                            <span onClick={ () => this.editAddress(add) }><EditIcon /></span>
                                                                                        </p>
                                                                                        <Button onClick={ () => this.handleDeliver(add) } className="my-btn mx-auto px-5" variant="contained">Deliver Here</Button>
                                                                                    </Col>
                                                                                </Form.Row>
                                                                            ))}
                                                                            <Form.Row as={Col} lg={6} md={12} xs={12} className={`${styles.addressRow}`}>
                                                                                <Col className={`${styles.addressRowCol} ${styles.helpDiv}`}>
                                                                                    <h4 onClick={this.handleEdit}>
                                                                                        <span><LocalHospitalIcon /></span>
                                                                                        <em>Add Address</em>
                                                                                    </h4>
                                                                                </Col>
                                                                            </Form.Row>
                                                                        </div>
                                                                        <ValidatorForm ref="form" className={`customForm w-100`} onSubmit={this.handleAddress}>
                                                                            {
                                                                                this.state.showHideClass ? (
                                                                                    <div className={`${styles.formFiledRow} ${styles.show}`}>
                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <TextValidator select required label="Select state" type="number" name="state_id" value={this.state.state_id} onChange={(event) => this.getCity(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                                                                {this.state.states.map(option => (
                                                                                                    <MenuItem key={option.id} value={option.id}>
                                                                                                        {option.state_name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextValidator>
                                                                                        </Form.Row>

                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <TextValidator select required label="Select city" type="number" name="city_id" value={this.state.city_id} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                                                                {this.state.city.map(option => (
                                                                                                    <MenuItem key={option.id} value={option.id}>
                                                                                                        {option.city_name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextValidator>
                                                                                        </Form.Row>
                                                                                        
                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <TextValidator required label="Locality" name="locality" value={this.state.locality} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                        </Form.Row>
                                                                                        
                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <TextValidator required label="PinCode" name="pincode" value={this.state.pincode} onChange={(event) => this.handleChange(event)} validators={['required', 'matchRegexp:^[0-9]*$', 'matchRegexp:^.{6,6}$']} errorMessages={['This field is required', 'Enter only number.', 'Pincode is not valid']} autoComplete="nope" variant="outlined" />
                                                                                        </Form.Row>
                                                                                        
                                                                                        <Form.Row as={Col} xs={12}>
                                                                                            <TextValidator required label="Enter Address" multiline rows="3" name="address" value={this.state.address} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                        </Form.Row>
                                                                                        
                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <TextValidator id="Landmark" required label="Enter Landmark" name="landmark" value={this.state.landmark} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                        </Form.Row>

                                                                                        <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                            <FormControl component="fieldset" className={`customRadio`}>
                                                                                                <FormLabel component="legend">Lable <sup>*</sup></FormLabel>
                                                                                                <RadioGroup aria-label="addressType" name="addressType" value={this.state.addressType} onChange={(event) => this.handleChange(event)}>
                                                                                                    <FormControlLabel value="1" control={<Radio />} label="Home" />
                                                                                                    <FormControlLabel value="2" control={<Radio />} label="Work" />
                                                                                                    <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                                                                </RadioGroup>
                                                                                            </FormControl>
                                                                                        </Form.Row>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            <Form.Row as={Col} xs={12} className={this.state.showHideClass ? `d-flex m-0` : `d-none m-0`}>
                                                                                <Button onClick={ () => this.cancelAddress() } as={Button} className="my-btn mx-auto px-4" variant="contained">Cancel</Button>
                                                                                <Button as={Button} className="my-btn my-btn-second mx-auto px-5" type="submit" variant="contained" formNoValidate>Save & Next</Button>
                                                                            </Form.Row>

                                                                            <Form.Row as={Col} xs={12} className={this.state.showHideClass ? `d-none` : `d-flex`}>
                                                                                <Button as={Button} className="my-btn mx-auto px-md-5 px-3" variant="contained" onClick={ () => this.accordionPrevious() }>Previous</Button>
                                                                                <Button as={Button} className="my-btn my-btn-second mx-auto px-5" onClick={ () => this.setAddress() } variant="contained">Next</Button>
                                                                            </Form.Row>
                                                                        </ValidatorForm>
                                                                        </>
                                                                    ) : (
                                                                        <ValidatorForm ref="form" className={`customForm`} onSubmit={this.handleAddress}>
                                                                            <div className={`${styles.formFiledRow} ${styles.show}`}>
                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <TextValidator select required label="Select state" type="number" name="state_id" value={this.state.state_id} onChange={(event) => this.getCity(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                                                        {this.state.states.map(option => (
                                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                                {option.state_name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextValidator>
                                                                                </Form.Row>

                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <TextValidator select required label="Select city" type="number" name="city_id" value={this.state.city_id} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                                                        {this.state.city.map(option => (
                                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                                {option.city_name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextValidator>
                                                                                </Form.Row>
                                                                                
                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <TextValidator required label="Locality" name="locality" value={this.state.locality} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                </Form.Row>
                                                                                
                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <TextValidator required label="PinCode" type="number" name="pincode" value={this.state.pincode} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                </Form.Row>
                                                                                
                                                                                <Form.Row as={Col} xs={12}>
                                                                                    <TextValidator required label="Enter Address" multiline rows="3" name="address" value={this.state.address} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                </Form.Row>
                                                                                
                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <TextValidator id="Landmark" required label="Enter Landmark" name="landmark" value={this.state.landmark} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} autoComplete="nope" variant="outlined" />
                                                                                </Form.Row>

                                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                                    <FormControl component="fieldset" className={`customRadio`}>
                                                                                        <FormLabel component="legend">Lable <sup>*</sup></FormLabel>
                                                                                        <RadioGroup aria-label="addressType" name="addressType" value={this.state.addressType} onChange={(event) => this.handleChange(event)}>
                                                                                            <FormControlLabel value="1" control={<Radio />} label="Home" />
                                                                                            <FormControlLabel value="2" control={<Radio />} label="Work" />
                                                                                            <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                </Form.Row>
                                                                            </div>
                                                                            <Form.Row as={Col} xs={12} className="d-flex">
                                                                                <Button className="my-btn mx-auto px-md-5 px-3" onClick={ () => this.accordionPrevious() } variant="contained">Previous</Button>
                                                                                <Button className="my-btn my-btn-second mx-auto px-5" type="submit" variant="contained" formNoValidate>Next</Button>
                                                                            </Form.Row>
                                                                        </ValidatorForm>)
                                                                }  
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                    
                                                    <Card className={`${styles.card}`}>
                                                    <ValidatorForm ref="form" className={`customForm`} onSubmit={this.handleSubmit}>
                                                        <div as={Card.Header} className={`${styles.header}`}>
                                                            <Badge>3</Badge> Select Time And Delivery Mode
                                                        </div>

                                                        <Accordion.Collapse eventKey="2">
                                                            <Card.Body className={`${styles.body}`}>

                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker
                                                                                name="sampleCollectionDate"
                                                                                margin="normal"
                                                                                id="date-picker-dialog"
                                                                                label="Select sample collection date"
                                                                                format="dd/MM/yyyy"
                                                                                value={this.state.sampleCollectionDate}
                                                                                onChange={(date, name) => this.handleDateChange(date, 'sampleCollectionDate')}
                                                                                KeyboardButtonProps={{
                                                                                'aria-label': 'change date',
                                                                                }}
                                                                            />
                                                                    </MuiPickersUtilsProvider>
                                                                </Form.Row>

                                                                <Form.Row as={Col} lg={6} md={12} xs={12}>
                                                                    <TextValidator required select label="Select sample collection time" name="sampleCollectionTime" value={this.state.sampleCollectionTime} onChange={(event) => this.handleChange(event)} validators={['required']} errorMessages={['This field is required']} variant="outlined">
                                                                        {timeslot.map((option, i) => (
                                                                            <MenuItem key={i} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextValidator>
                                                                </Form.Row>

                                                                <Form.Row as={Col} xs={12}>
                                                                    <DeliveryMode />
                                                                </Form.Row>

                                                                <Form.Row as={Col} xs={12}>
                                                                    <Button className="my-btn mx-auto px-md-5 px-3" variant="contained" onClick={ () => this.accordionPrevious() }>Previous</Button>
                                                                    <Button className="my-btn my-btn-second mx-auto px-5 text-uppercase" disabled={this.state.placeOrder} variant="contained" type="submit" formNoValidate>
                                                                        Place Now
                                                                    </Button>
                                                                </Form.Row>

                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                        </ValidatorForm>
                                                    </Card>
                                                </Accordion>
                                            ) : (
                                                <div className={`sweet-loading customLoader`}>
                                                    <DotLoader
                                                        color={'#ef3c67'}
                                                        loading={this.state.loading}  
                                                    />
                                                </div>
                                            )
                                        }
                                        
                                        </>
                                    ) : (
                                        <Card className={`${styles.emptyCart} ${styles.helpEmptyCart}`}>
                                            <Figure>
                                                <RemoveShoppingCartIcon />
                                            </Figure>
                                            <Card.Body className={`${styles.emptyCartBody}`}>
                                                <Card.Text className={`${styles.emptyCartBodyText}`}>
                                                    Your cart is empty
                                                </Card.Text>
                                                <Link to="/diagnostic" className={`my-btn`} variant="primary">
                                                    Add Tests
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            </Col>

                            <Col lg={4} md={5} xs={12} className={`${styles.priceDetail} order-md-2 order-1`}>
                                <Card className={`${styles.priceDetailCard}`}>
                                    <Card.Header className={`${styles.priceDetailHeader}`}>
                                        Your Cart <span className={``}>( {this.props.cartItems.length} Tests )</span>
                                    </Card.Header>

                                    <Card.Body className={`${styles.CartItemView}`}>
                                        <div as={Col} xs={12} className={`${styles.CartItemDiv}`}>
                                            {
                                                this.props.cartItems.map((item) => (
                                                    <Card className={`${styles.CartItemCard}`}>
                                                        <Figure>
                                                            <Image width="30" className="mx-auto" src={window.location.origin + "/assets/images/home/doctor_card.jpg"} alt="Book Appointment" />
                                                        </Figure>
                                                        <Card.Body className={`${styles.CartItemBody}`}>
                                                            <Card.Title className={`${styles.CartItemTitle}`}>
                                                                {item.test_name}
                                                            </Card.Title>
                                                            <Card.Text className={`${styles.CartItemText} d-none`}>
                                                                <span>Preparation-</span>
                                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.
                                                            </Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer className={`${styles.CartItemFooter}`}>
                                                        <span>
                                                            {item.discount_percentage > 0 ? (
                                                                <del>₹{item.mrp}</del>
                                                            ) : null } ₹{item.final_mrp}
                                                        </span>
                                                        </Card.Footer>
                                                    </Card>
                                                ))
                                            }
                                        </div>
                                    </Card.Body>
                                    
                                    <Card.Body className={`${styles.priceDetailBody}`}>
                                        <ListGroup as="ul" className={`list-group-flush`}>
                                            <ListGroupItem as="li">
                                                <span>Total Payble</span>
                                                <span>₹ {this.props.total.toFixed(2)}/-</span>
                                            </ListGroupItem>
                                        </ListGroup>
                                        {
                                            this.props.totalDisc > 0 ? (
                                                <Card.Text className={`${styles.priceDetailBodyText}`}>
                                                    Your total saving on this health booking ₹ {this.props.totalDisc.toFixed(2)}
                                                </Card.Text>
                                            ) : null
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`${styles.anotherFooter}`}>
                    <Container>
                        <Row>
                            <p>
                                © 2017, DrRay24. All rights reserved
                            </p>
                        </Row>
                    </Container>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        cartItems: state.diagnosticCart.items,
        total: state.diagnosticCart.total,
        totalDisc: state.diagnosticCart.totalDisc
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkout: () => {dispatch({type: 'CHECKOUT'})}
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));