import React, { Component } from "react";
import styles from './Test-card.module.scss';
import { Image, Card, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Button } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ListGroup} from 'react-bootstrap';
import { connect } from 'react-redux';

class TestCard extends Component{

    render() {
        
        var cartItem = this.props.cartItems.find(item => this.props.test.test_code == item.test_code);
        return(
            <Card className={cartItem ? `${styles.bookedTest} rounded-0 singleTestCard` : `${styles.bookedTest} rounded-0`}>
                <Card.Body className={styles.bookedTestBody}>
                    <span>
                        <Image width="50" src={window.location.origin + "/assets/images/home/doctor_card.jpg"} alt="Book Appointment" />
                    </span>

                    <Col>
                        <Card.Title className={styles.bookedTestTitle}>
                            <Link to={`/diagnostic/tests/${encodeURIComponent(this.props.test.test_name.replace(/ /g, "-").toLowerCase())}/${this.props.test.test_code}`}>{this.props.test.test_name}</Link>
                        </Card.Title>
                        <Card.Text className={styles.bookedTestText}>
                            {this.props.test.short_description.substring(0, 60)}...
                        </Card.Text>
                    </Col>

                    <ListGroup as="ul" className={`${styles.addCartBtn}`}>
                        {
                            cartItem ? (
                                <ListGroup.Item as="li" className='ClassPass active'>
                                    <Button className={`${styles.addCartBtnCommon} my-btn`} onClick={() => this.props.removeItem(this.props.test)} variant="contained">
                                        <ShoppingCartIcon className={`${styles.addCartBtnIcon} activeCartBtnHide`} />
                                        <DeleteForeverIcon className={`${styles.addCartBtnIcon} activeCartBtnShow`} />
                                    </Button>
                                </ListGroup.Item>
                            ) : (
                                <ListGroup.Item as="li" className='ClassPass'>
                                    <Button className={`${styles.addCartBtnCommon} my-btn`} onClick={() => this.props.addToCart(this.props.test)} variant="contained">
                                        <ShoppingCartIcon className={`${styles.addCartBtnIcon} activeCartBtnHide`} />
                                        <DeleteForeverIcon className={`${styles.addCartBtnIcon} activeCartBtnShow`} />
                                    </Button>
                                </ListGroup.Item>
                            )
                        }
                    </ListGroup>
                </Card.Body>

                <Card.Footer className={styles.bookedTestFooter}>
                    <Col>
                        <h5>

                        ₹{this.props.test.final_mrp}&nbsp;
                        {this.props.test.discount_percentage > 0 ? (
                            <del>₹{this.props.test.mrp}</del>
                        ) : null }
                        </h5>
                        {this.props.test.discount_percentage > 0 ? (
                            <p>Save upto {this.props.test.discount_percentage.replace(/\.00$/,'')}% </p>
                        ) : null }
                    </Col>

                    <Link to={`/diagnostic/tests/${encodeURIComponent(this.props.test.test_name.replace(/ /g, "-").toLowerCase())}/${this.props.test.test_code}`} className={`my-btn ${styles.bookedTestBtn}`} variant="contained">
                        Know More
                    </Link>
                </Card.Footer>
            </Card>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        cartItems: state.diagnosticCart.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (item) => {dispatch({type: 'ADD_TO_CART', item: item})},
        removeItem: (item) => {dispatch({type: 'REMOVE_ITEM', item: item})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestCard);