import React, {Component} from 'react';
import styles from './Add-Members.module.scss';
import { Row, Container, Col, Form } from 'react-bootstrap';

import { Button } from '@material-ui/core';
import UserProfileHeader from '../User-Profile-Header/User-Profile-Header';
import UserProfileSidebar from '../User-Profile-Sidebar/User-Profile-Sidebar';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { TextField, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AddMemberListComponent from './Add-Members-List/Add-Members-List';
import AuthService from '../../../services/Auth-Service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const RelationOption = [
    {
      value: 'Mother',
      label: 'Mother',
    },
    {
      value: 'Father',
      label: 'Father',
    },
    {
      value: 'Brother',
      label: 'Brother',
    },
];

class AddMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitSuccess: false,
            isLoading: false,
            formSubmit: false,
            f_name: '',
            l_name: '',
            dob: new Date(),
            relation: '',
            gender: '',
            vertical: 'top',
            horizontal: 'center',
            members: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const API = AuthService.get('add_members');
        API.then(json => {
            this.setState({
                isLoading: true,
                members: json.result
            });
        });
        document.title = "Add Members"
    }

    handleDateChange(d, name) {
        if(d) {
            var dob = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
            this.setState({[name]: dob});
        }
    }

    formState = {
        formData: {
            PatientFName: '',
            PatientLName: '',
        },
        submitted: false,
    }

    /**handleChange = (event) => {
        const {formData} = this.formState;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }**/

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({formSubmit: true});
        const formData = new FormData();
        formData.append('f_name', this.state.f_name);
        formData.append('l_name', this.state.l_name);
        formData.append('dob', this.state.dob);
        formData.append('relation', this.state.relation);
        formData.append('gender', this.state.gender);

        const API = AuthService.post('add_members', formData);
        API.then(json => {
            this.setState({
                isLoading: false,
                formSubmit: false,
                f_name: '',
                l_name: '',
                dob: new Date(),
                relation: '',
                gender: '',
                submitSuccess: true
            });
        })
    }

    render() {
        const { isLoading, submitted, vertical, horizontal, submitSuccess, members } = this.state;

        function Alert(props: AlertProps) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        const handleClose = (reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({submitSuccess: false});
        };

        return(
            <div className="content">

                <UserProfileHeader />

                <Container fluid>
                    <Container>
                        <Row className={`position-relative`}>

                            <Col lg={2} md={3} xs={12} className={`px-0`}>
                                <UserProfileSidebar />
                            </Col>

                            <Snackbar anchorOrigin={{ vertical, horizontal }} open={submitSuccess} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">Member added successfully!</Alert>
                            </Snackbar>

                            <Col lg={10} md={9} xs={12} className={`customSecondHeading ${styles.userDetailMain}`}>
                                <ValidatorForm ref="form" className="customForm" onSubmit={this.handleSubmit}>
                                    <h5>
                                        Add Members
                                        <span>
                                            Member Details
                                        </span>
                                        <Button id="loginFormWidOtpBtn" className={`my-btn ${styles.topSaveBtn} loginFormWidOtpBtn`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                            Submit
                                        </Button>
                                    </h5>

                                    <Row className={`mt-5 px-md-3`}>

                                        <Form.Row as={Col} md={6} xs={12}>
                                            <TextValidator required label="First name" name="f_name" validators={['required']} errorMessages={['This field is required!']} onChange={this.handleChange} value={this.state.f_name} autoComplete="off" variant="outlined" />
                                        </Form.Row>

                                        <Form.Row as={Col} md={6} xs={12}>
                                            <TextValidator label="Last name" name="l_name" variant="outlined" value={this.state.l_name} onChange={this.handleChange} />
                                        </Form.Row>

                                        <Form.Row as={Col} md={4} xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="dob"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="Select date of birth"
                                                        format="dd/MM/yyyy"
                                                        value={this.state.dob}
                                                        onChange={(date, name) => this.handleDateChange(date, 'dob')}
                                                        KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        }}
                                                    />
                                            </MuiPickersUtilsProvider>
                                        </Form.Row>

                                        <Form.Row as={Col} md={4} xs={12}>
                                            <TextField required select label="Relation" name="relation" variant="outlined" value={this.state.relation} validators={['required']} errorMessages={['this field is required']} onChange={this.handleChange}>
                                                {RelationOption.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Form.Row>

                                        <Form.Row as={Col} md={4} xs={12}>
                                            <FormControl component="fieldset" className={`customRadio`}>
                                                <FormLabel component="legend">Gender <sup>*</sup></FormLabel>
                                                <RadioGroup aria-label="gender" name="gender" value={this.state.gender} onChange={(event) => this.handleChange(event)}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="2" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="3" control={<Radio />} label="Other" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Form.Row>

                                        <Form.Row as={Col} xs={12} className={`justify-content-center`}>
                                            <Button className={`my-btn px-5`} variant="contained" type="submit" disabled={this.state.formSubmit} formNoValidate>
                                                Submit
                                            </Button>
                                        </Form.Row>
                                        
                                    </Row>

                                </ValidatorForm>

                                <Col xs={12} className={`tableMainDiv ${styles.membersList}`}>
                                    <AddMemberListComponent members={members} />
                                </Col>

                            </Col>
                            
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

export default AddMembers;