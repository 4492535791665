import React, { Component } from "react";
import styles from "./Appointment.module.scss";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Container, Image, Row, Col, Figure, Card, Form } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import Service from '../../../services/Service';
import { DotLoader } from 'react-spinners';

class Appointment extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            appTime: null,
            slotDate: null,
            bookApp: [],
            f_name: null,
            l_name: null,
            email: null,
            mobile: null,
            confirmApp: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var params = this.props.match.params;
        var appTime = new Date(parseInt(params.appTime));
        var slotDate = monthNames[appTime.getMonth()] + " " + appTime.getDate() + ", " + appTime.getFullYear();
        this.setState({
            appTime: appTime,
            slotDate: slotDate
        });
        const formData = new FormData();
        formData.append('clinic_id', params.clinicId);
        const API = Service.post('doctor/book', formData);
        API.then(json => {
            this.setState({
                isLoading: true,
                bookApp: json['result']
            });
        });
        document.title = "Appointment | " + params.appTime;
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({confirmApp: true});
        const formData = new FormData();
        formData.append('f_name', this.state.f_name);
        formData.append('l_name', this.state.l_name);
        formData.append('mobile', this.state.mobile);
        formData.append('email', this.state.email);
        formData.append('app_time', this.props.match.params.appTime);
        formData.append('clinic_id', this.props.match.params.clinicId);
        formData.append('doctor_id', this.state.bookApp.doctor_id);
        const API = Service.post('doctor/bookApp', formData);
        API.then(json => {
            this.setState({confirmApp: false});
            if(json.status == 200) {
                this.props.history.push('/thank-you');
            } else if(json.status == 300) {
                alert("This slot already booked!");
            } else {
                alert("Something went wrong!");
            }
        });
    }

    render() {

        function slotTime(slot) {
            var dateTime = new Date(slot);
            var hours = dateTime.getHours();
            var minutes = dateTime.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        var {isLoading, bookApp} = this.state;
        if(isLoading) {
            return(
                <div className={`content bgGray`}>
                    <Container fluid>
                        <Container>
                            <Row className={`${styles.listingRow}`}>
                                            
                                <Col xs="12" className={`${styles.profileDetails}`}>
                                    <Card className={`${styles.profileDetailDivCard}`}>
                                        <Card.Header className={`${styles.profileDetailHeader}`}>

                                            <Figure className={`${styles.profileDetailProfileImg}`}>
                                                <Image className="mx-auto" src={window.location.origin + "/assets/images/home/doctors/image_1.jpg"} fluid alt="Profile Photo" />
                                            </Figure>

                                            <div className={`${styles.profileDetailContent}`}>
                                                <Col md={7} xs={12} className={`${styles.profileDetailContentLeft}`}>
                                                    <h1>
                                                        Dr. {bookApp.doctor_name}, <span>{bookApp.specialization}, </span>
                                                    </h1>
                                                    <h5>
                                                        {bookApp.clinic_name}, <span>{bookApp.address}, {bookApp.locality}, {bookApp.city_name}, {bookApp.state_name}, {bookApp.pincode}</span>
                                                    </h5>
                                                </Col>

                                                <Col md={5} xs={12} className={`${styles.profileDetailContentRight}`}>
                                                    <h3 className={`${styles.profileBriefText}`}>
                                                        In-clinic Appointment
                                                    </h3>
                                                    <h3 className={`${styles.profileBriefText}`}>
                                                        <CalendarTodayIcon className={`${styles.profileBriefIcon}`} /> On: <b>{this.state.slotDate}</b>
                                                    </h3>
                                                    <h3 className={`${styles.profileBriefText}`}>
                                                        <AccessTimeIcon className={`${styles.profileBriefIcon}`} /> At: <b>{slotTime(this.state.appTime)}</b>
                                                    </h3>
                                                </Col>
                                            </div>
                                        </Card.Header>
                                        <Form className={`customForm`} onSubmit={this.handleSubmit}>
                                            <Card.Body className={`${styles.profileDetailBody}`}>
                                                <Row>
                                                    <Col md={4} xs={12} className={`${styles.mapDirection}`}>
                                                        <Col className={`customSecondHeading w-auto px-0`}>
                                                            <h5>
                                                                Provide current location
                                                                <span>
                                                                    To see Doctors near you
                                                                </span>
                                                            </h5>
                                                        </Col>
                                                        <iframe className={`${styles.mapDirectionFrame}`} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.810256752196!2d77.06620251558378!3d28.42498168250013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1876a0db4f59%3A0xe50fe47fb8af8ed1!2sGETTOOWELL%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1584360948303!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                                    </Col>

                                                    <Col md={8} xs={12} className={`${styles.patientDetails}`}>
                                                        <Col className={`${styles.profileDetailBodyHeading}`}>
                                                            <h3 className="customHeading">Patient Details</h3>
                                                        </Col>

                                                        <Col className={`${styles.profileDetailBodySection}`}>
                                                            <Card.Text className={`${styles.profileDetailBodySectionHeading}`}>
                                                                Please provide following information about user:
                                                            </Card.Text>

                                                            <Row>
                                                                <Form.Row as={Col} sm={6} xs={12}>
                                                                    <TextField label="Enter First Name" name="f_name" value={this.state.f_name} required variant="outlined" onChange={(event) => this.handleChange(event)}
                                                                    validators={['required']} errorMessages={['First Name is required']} />
                                                                </Form.Row>

                                                                <Form.Row as={Col} sm={6} xs={12}>
                                                                    <TextField label="Enter last name" name="l_name" value={this.state.l_name} variant="outlined" onChange={(event) => this.handleChange(event)} />
                                                                </Form.Row>
                                                                
                                                                <Form.Row as={Col} xs={12}>
                                                                    <TextField label="Enter Your Email" name="email" value={this.state.email} variant="outlined" required onChange={(event) => this.handleChange(event)}
                                                                    validators={['required', 'isEmail']} errorMessages={['Email is required', 'email is not valid']} />
                                                                </Form.Row>
                                                                
                                                                <Form.Row as={Col} xs={12}>
                                                                    <TextField label="Enter Your Mobile No." name="mobile" value={this.state.mobile} variant="outlined" required onChange={(event) => this.handleChange(event)}
                                                                    errorMessages={['Mobile No. is required']} validators={['required']} />
                                                                </Form.Row>
                                                            </Row>
                                                        </Col>

                                                        <Col className={`${styles.profileDetailBodySection} ${styles.profileDetailFooter}`}>
                                                            <button type="submit" className={`my-btn ${styles.allprofilesBtn}`} disabled={this.state.confirmApp}>Confirm <DoubleArrowIcon className={`${styles.allprofilesBtnArrow}`} /> </button>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            );
        } else {
            return (
                <div className={`sweet-loading customLoader`}>
                    <DotLoader
                    color={'#ef3c67'}
                    loading={this.state.loading}  
                    />
                </div>
            );
        }
    }
}

export default Appointment;