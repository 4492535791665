import React, { Component } from 'react';
import styles from './About.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import { Container, Image, Row, Col, Breadcrumb, Card, ListGroup } from 'react-bootstrap';

class About extends Component {

    componentDidMount() {
        document.title = "About"
    }

    render() {
        return(
            <div className="content">

                <Container fluid className="mt-n3 p-0 staticPageHeading">
                    <Image className="d-block w-100" src="assets/images/about/about_banner.jpg" alt="About Us" />
                    <div className="staticPageHeading_main">
                        <Container>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <h1>About Us</h1>
                                </Col>

                                <Col sm={6} xs={12}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Home
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            About
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid className={`${styles.sectionSpace} ${styles.aboutContentMain}`}>
                    <Container>
                        <Row>
                            <h5>
                                DRRAY24 OFFERS ONE-STOP-SHOP
                            </h5>
                            <h3>
                                FOR ALL YOUR MEDICAL AND HEALTHCARE NEEDS AND PROVIDES SOLUTIONS IMPROVING YOUR HEALTH & BRINGING SENSE OF SECURITY.
                            </h3>
                            <p>
                                DRRAY24 is the brand product of GETTOOWELL Technology Private Limited. We are among the country’s fastest growing Healthcare Solutions Provider Platforms. We at DRRAY24 offer healthcare platform for all with the latest cutting-edge technology, ranging from end users to nearly all healthcare service providers including doctors, pathology & diagnostic labs, pharmaceutical companies and medical stores. We aim at providing affordable healthcare and treatment services at best prices anytime and anywhere, reaching every individual or family in India.
                            </p>
                            <p>
                                We are closely working towards bridging the gap between the healthcare service providers and the end-users within the country by providing smarter, safer, faster, innovative and tech-driven solutions. We look forward to help making the people's life easier and continuously strive to provide world-class services to our clients with ease and in timely manner.
                            </p>
                            <p>
                                We promote the maximum digitization of records as doctors can use our platform as a clinic management system where they can check their daily appointments on the go, can get quick access to all records, can provide online prescriptions, can check patient’s historical records, refer patients directly, client engagement, branding purposes etc.
                            </p>
                            <p>
                                Not only for the doctors, the patients can use our platform and can enter the whole new world for healthcare by availing the benefits like avoiding the long queues, low cost, check prescriptions online, save, track and maintain their daily health related activities.
                            </p>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`${styles.sectionSpace} ${styles.missionOffers}`}>
                    <Container>
                        <Row>
                            <Col md={6} xs={12}>
                                <h3>Vision & Mission</h3>
                                <Image src="assets/images/about/vision_mission.jpg" fluid alt="Vision Mission" />
                                <ul>
                                    <li>
                                        To be a leader in providing complete medical and healthcare solutions.
                                    </li>
                                    <li>
                                        To consistently deliver best and timely services to our clients.
                                    </li>
                                    <li>
                                        To provide excellent medical services at reasonable price.
                                    </li>
                                    <li>
                                        To encourage digitization of records with ease.
                                    </li>
                                    <li>
                                        To build long term relationship with our clients through delivering the highest quality every time.
                                    </li>
                                </ul>
                            </Col>
                            <span className={`${styles.blankLine}`}>&nbsp;</span>
                            <Col md={6} xs={12}>
                                <h3>We offer highest comfort and quality</h3>
                                <Image src="assets/images/about/highest_comfort.jpg" fluid alt="Highest Comfort" />
                                <ul>
                                    <li>
                                        At DRRAY24, we believe in superior customer satisfaction. To achieve this we strive to provide consistently superior quality services to our clients with the greatest comfort and at cost effective prices.
                                    </li>
                                    <li>
                                        We deliver better quality services to build and maintain our clients’ trust.
                                    </li>
                                    <li>
                                        Our ability to offer timely and hassle free access to each and every service, provide us an edge over others.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className={`${styles.whyChoose} ${styles.p0}`}>
                    <Row className="m-0">
                        <Col lg={6} xs={12} className={`${styles.whyChooseBanner}`}>&nbsp;</Col>

                        <Col lg={6} xs={12} className={`${styles.whyChooseContent} bg-white`}>
                            <h3>
                                Healthcare Solutions
                            </h3>
                            <p>
                                We at DRRAY24, strongly believes in delivering timely, unmatched and effective healthcare solutions with greater comfort to our customers. Our enthusiasm, loyalty and devotion for providing good quality service rewarded us in the form of long term relationships with our clients.
                            </p>
                            <ListGroup as="ul" className={`${styles.whyChooseOption}`}>
                                <ListGroup.Item as="li">
                                    <CheckIcon className={`${styles.fontIcon}`} />
                                    <Card className={`${styles.card}`}>
                                        <Card.Body className={`${styles.body}`}>
                                            <Card.Title className={`${styles.title}`}>
                                                24 /7 Emergency Services
                                            </Card.Title>
                                            <Card.Text className={`${styles.text}`}>
                                                Get round the clock access to our healthcare services. Get doctor’s appointment, consultation, or medicines - anytime, anywhere.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <CheckIcon className={`${styles.fontIcon}`} />
                                    <Card className={`${styles.card}`}>
                                        <Card.Body className={`${styles.body}`}>
                                            <Card.Title className={`${styles.title}`}>
                                                Modern Technologies
                                            </Card.Title>
                                            <Card.Text className={`${styles.text}`}>
                                                Get smarter healthcare services with modern technologies to stay healthy and fit in today’s fast moving lifestyle.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <CheckIcon className={`${styles.fontIcon}`} />
                                    <Card className={`${styles.card}`}>
                                        <Card.Body className={`${styles.body}`}>
                                            <Card.Title className={`${styles.title}`}>
                                                Passionate Doctors & Nurses
                                            </Card.Title>
                                            <Card.Text className={`${styles.text}`}>
                                                Get access to highly qualified and experienced doctors along with passionate and caring nursing staff everywhere and every time.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <CheckIcon className={`${styles.fontIcon}`} />
                                    <Card className={`${styles.card}`}>
                                        <Card.Body className={`${styles.body}`}>
                                            <Card.Title className={`${styles.title}`}>
                                                Protection Against Diseases
                                            </Card.Title>
                                            <Card.Text className={`${styles.text}`}>
                                                Get instant guidance from our experts on protection against diseases with no waiting long queues even at the time of crises.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className={`${styles.ourTeam} ${styles.sectionSpace}`}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <h3 className="customHeading">Meet Our Team</h3>
                                <p className="sectionText">
                                    Drray team leads web and mobile development community through conferences, blogs, open source, videos, books and meetups. <br />
                                    We value open source, learning, remote work and open communication.
                                </p>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={4} sm={6} xs={12}>
                                <Card className={`${styles.ourTeamMember}`}>
                                    <Image src="assets/images/team/dummy.png" roundedCircle alt="Founder & CEO" className="mx-auto" />
                                    <Card.Body className={`${styles.body}`}>
                                        <Card.Title className={`${styles.title}`}>
                                            VIKRANT AHUJA
                                        </Card.Title>
                                        <Card.Text className={`${styles.text}`}>
                                            Founder & CEO 
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col md={4} sm={6} xs={12}>
                                <Card className={`${styles.ourTeamMember}`}>
                                    <Image src="assets/images/team/dummy.png" roundedCircle alt="Founder & CEO" className="mx-auto" />
                                    <Card.Body className={`${styles.body}`}>
                                        <Card.Title className={`${styles.title}`}>
                                            VIKAS AHUJA
                                        </Card.Title>
                                        <Card.Text className={`${styles.text}`}>
                                            Founder & CMD 
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col md={4} sm={6} xs={12}>
                                <Card className={`${styles.ourTeamMember}`}>
                                    <Image src="assets/images/team/dummy.png" roundedCircle alt="Founder & CEO" className="mx-auto" />
                                    <Card.Body className={`${styles.body}`}>
                                        <Card.Title className={`${styles.title}`}>
                                            Employee Name
                                        </Card.Title>
                                        <Card.Text className={`${styles.text}`}>
                                            Designation
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col md={4} sm={6} xs={12}>
                                <Card className={`${styles.ourTeamMember}`}>
                                    <Image src="assets/images/team/dummy.png" roundedCircle alt="Founder & CEO" className="mx-auto" />
                                    <Card.Body className={`${styles.body}`}>
                                        <Card.Title className={`${styles.title}`}>
                                            Employee Name
                                        </Card.Title>
                                        <Card.Text className={`${styles.text}`}>
                                            Designation
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
} 

export default About;